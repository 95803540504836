<template>
  <div class="blog-container">
    <BlogCard
      src="blog1.png"
      title="Бывший учитель разводит кроликов гигантов"
    />
    <BlogCard
        src="blog2.png"
        title="Бывший учитель разводит кроликов гигантов"
    />
    <BlogCard
        src="blog3.png"
        title="Бывший учитель разводит кроликов гигантов"
    />
  </div>
</template>
<script>
import BlogCard from "@/components/Blog/BlogCard.vue";

export default {
  name: "BlogContainer",
  components: {BlogCard}
}
</script>
<style lang="scss">
.blog-container{
  display: flex;
  gap: 40px;
}
</style>