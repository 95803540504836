<template>
  <div :class="['order-status', statusList[orderStatus - 1].class ]">
    <div :class="['order-status-icon', { 'order-status-canceled-icon': orderStatus === 5}]">
      <svg v-if="orderStatus !== 3 && orderStatus !== 5" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.19875 0.551758L3.52528 4.88737L1.54882 2.77816L0.241394 4.00331L3.43443 7.41084L9.41381 1.86886L8.19875 0.551758Z" fill="white"/>
      </svg>
      <svg v-if="orderStatus === 3"  width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0833 5.49984C10.0833 8.03114 8.03126 10.0832 5.49996 10.0832C2.96865 10.0832 0.916626 8.03114 0.916626 5.49984C0.916626 2.96853 2.96865 0.916504 5.49996 0.916504C8.03126 0.916504 10.0833 2.96853 10.0833 5.49984Z" fill="#F44236"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49996 3.32275C5.68981 3.32275 5.84371 3.47666 5.84371 3.6665V5.35745L6.88886 6.4026C7.0231 6.53685 7.0231 6.7545 6.88886 6.88874C6.75462 7.02298 6.53697 7.02298 6.40272 6.88874L5.25689 5.74291C5.19243 5.67844 5.15621 5.59101 5.15621 5.49984V3.6665C5.15621 3.47666 5.31011 3.32275 5.49996 3.32275Z" fill="white"/>
      </svg>
      <svg v-if="orderStatus === 5" width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.671243 6.99573C0.497645 7.00582 0.32691 6.94811 0.195042 6.83475C-0.0650139 6.57315 -0.0650139 6.15064 0.195042 5.88904L5.8894 0.194659C6.15988 -0.0584393 6.58431 -0.0443697 6.83741 0.226111C7.06628 0.470705 7.07962 0.846653 6.86864 1.10684L1.14073 6.83475C1.01058 6.94647 0.842565 7.00408 0.671243 6.99573Z" fill="white"/>
        <path d="M6.36033 6.9959C6.18437 6.99515 6.01575 6.9253 5.89082 6.8014L0.196433 1.10699C-0.0444949 0.825644 -0.0117395 0.402231 0.269608 0.161281C0.520718 -0.0537604 0.891056 -0.0537604 1.14214 0.161281L6.87006 5.85567C7.14047 6.10883 7.15445 6.53328 6.90129 6.8037C6.89122 6.81445 6.88081 6.82486 6.87006 6.83493C6.80067 6.89527 6.71961 6.94069 6.63192 6.96838C6.54423 6.99608 6.45179 7.00544 6.36033 6.9959Z" fill="white"/>
      </svg>
    </div>
    <div class="order-status-text">
      {{ statusList[orderStatus - 1].text }}
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderStatus",
  props:{
    orderStatus: {
      type: Number,
      default: () => 0
    }
  },
  data(){
    return{
      statusList: [
        {
          "value": "success",
          "text": "Эта задача выполнена!",
          "class": "order-status-success"
        },
        {
          "value": "progress",
          "text": "Открыта",
          "class": "order-status-progress"
        },
        {
          "value": "failed",
          "text": "Отменена",
          "class": "order-status-failed"
        },
        {
          "value": "progressExecutor",
          "text": "Выполняется",
          "class": "order-status-progress-executor"
        },
        {
          "value": "canceled",
          "text": "Отменено заказчиком",
          "class": "order-status-failed"
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.order-status{
  display: flex;
  align-items: center;
  gap: 4px;
  &-icon{
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    svg{
      width: 13px;
      height: 7px;
    }
  }
  &-success{
    color: #3CB055;
    .order-status-icon{
      background: #3CB055;
    }
  }
  &-progress{
    color: #287EFF;
    .order-status-icon{
      background: #287EFF;
    }
  }
  &-progress-executor{
    color: #FF6B17;
    .order-status-icon{
      background: #FF6B17;
    }
  }
  &-failed{
    color: #F44236;
    .order-status-icon{
      border: 1px solid #F44236;
      background: transparent;
      svg{
        width: 11px;
        height: 11px;
      }
    }
  }
  &-text{
    font-size: 12px;
    height: 12px;
    font-weight: 300;
  }
  &-canceled-icon{
    background: #F44236!important;
    svg{
      width: 7px!important;
      height: 7px!important;
    }
  }
}
</style>
