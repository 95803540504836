<template>
  <div class="default-layout">
    <HeaderComponent/>
    <div class="default-layout-content">
      <slot/>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";

export default {
  name: "DefaultLayout",
  components: {FooterComponent, HeaderComponent},
}
</script>
<style lang="scss">
.default-layout{
  &-content{
    margin-top: 82px;

  }

}
</style>
