<template>
  <div class="task-steps">
    <div
      class="task-steps-step"
      v-for="step in stepCount"
      :key="step"
    >
      <div
        :class="['task-steps-step-container', {'task-steps-step-container-active': step < currentStep || (done && step <= currentStep)}]">
        <div :class="['task-steps-step-container-text', { 'task-steps-step-container-text-active': (step < currentStep || step === currentStep) || (done && step <= currentStep) }]">
          {{ step }}
        </div>
        <div v-if="step < currentStep || (done && step <= currentStep)" class="task-steps-step-container-icon">
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1454 0L5.01225 6.61752L1.99555 3.3982L0 5.26816L4.87359 10.4691L14 2.01031L12.1454 0Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div
        v-if="step < stepCount"
        :class="['task-steps-step-divider', { 'task-steps-step-divider-active': step < currentStep}]"
      >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TaskSteps",
  props: {
    stepCount: {
      type: Number,
      default: () => 1
    },
    currentStep: {
      type: Number,
      default: () => 1
    },
    done: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
.task-steps{
  display: flex;
  align-items: center;
  &-step{
    display: flex;
    align-items: center;
    &-container{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border: 1px solid #D9D9D9;
      border-radius: 17px;
      &-active{
        border-color: #287EFF;
      }
      &-text{
        font-size: 24px;
        font-weight: 500;
        color: #D9D9D9;
        &-active{
          color: #287EFF;
        }
      }
      &-icon{
        position: absolute;
        width: 29px;
        height: 29px;
        background: #287EFF;
        border-radius: 50%;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-divider{
      width: 40px;
      height: 1px;
      background: #D9D9D9;
      &-active{
        background: #287EFF;
      }
    }
  }
}
</style>
