<template>
  <div class="profile-category-card">
    <div class="profile-category-card-title" @click="isOpen = !isOpen">
      <div :class="['profile-category-card-title-text', {'profile-category-card-title-text-active': isOpen}]">
        {{ category.name }}
      </div>
      <div :class="['profile-category-card-title-arrow', {'profile-category-card-title-arrow-active': isOpen}]">
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.62265 4.62354L-0.000789093 9.47453e-05L9.24609 9.39369e-05L4.62265 4.62354Z"/>
        </svg>
      </div>
    </div>
    <div v-if="isOpen" class="profile-category-card-container">
      <div
        class="profile-category-card-subcategory"
        v-for="cat in category.subcategories"
        :key="cat.id"
      >
        <UiCheckbox :title="cat.name" color="orange" :grey-unactive="true"/>
      </div>
    </div>
  </div>
</template>
<script>
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";

export default {
  name: 'ProfileCategoryCard',
  components: {UiCheckbox},
  props: {
    category: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>
<style lang="scss">
.profile-category-card{
  padding: 0 23px;
  border: 1px solid rgba(30, 39, 47, .17);
  border-radius: 11px;
  cursor: pointer;
  &-title{
    display: flex;
    align-items: center;
    height: 61px;
    &-text{
      &-active{
        color: #FF6B17;
      }
    }
    &-arrow{
      margin-left: auto;
      svg{
        fill: #1e272f;
      }
      &-active{
        svg{
          fill: #FF6B17;
          transform: rotate(180deg);
        }
      }
    }
  }
  &-subcategory{
    &:not(:first-child){
      margin-top: 19px;
    }
    .ui-checkbox{
      font-size: 14px;
    }
    .ui-checkbox-checkbox{
      width: 19px;
      height: 19px;
    }
  }
  &-container{
    padding-bottom: 20px;
  }
}

</style>
