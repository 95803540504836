import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue';
import ProfileIndex from "@/components/Profile/ProfileIndex/ProfileIndex.vue";
import ProfileLayout from "@/layouts/ProfileLayout.vue";
import ProfileBilling from "@/components/Profile/ProfileBilling/ProfileBilling.vue";
import ProfileSettings from "@/components/Profile/ProfileSettings/ProfileSettings.vue";
import ProfileHistory from "@/components/Profile/ProfileHistory/ProfileHistory.vue";
import ProfileSecurity from "@/components/Profile/ProfileSecurity/ProfileSecurity.vue";
import ProfileReferral from "@/components/Profile/ProfileReferral/ProfileReferral.vue";
import CreateTask from "@/views/CreateTask.vue";
import TaskView from "@/views/TaskView.vue";
import FindTask from "@/views/FindTask.vue";
import PublicOffer from "@/views/PublicOffer.vue";
import CategoriesView from "@/views/CategoriesView.vue";
import ExecutorsView from "@/views/ExecutorsView.vue";
import SiteMap from "@/views/SiteMap.vue";
import FaqView from "@/views/FaqView.vue";
import PaymentPolicy from "@/views/PaymentPolicy.vue";
import ReferralView from "@/views/ReferralView.vue";
import ProfileView from "@/views/ProfileView.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/profile",
    name: 'ProfileIndex',
    component: ProfileIndex,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/profile/billing",
    name: 'ProfileBilling',
    component: ProfileBilling,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/profile/settings",
    name: 'ProfileSettings',
    component: ProfileSettings,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/profile/history",
    name: 'ProfileHistory',
    component: ProfileHistory,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/profile/security",
    name: 'ProfileSecurity',
    component: ProfileSecurity,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/profile/referral",
    name: 'ProfileReferral',
    component: ProfileReferral,
    meta: {
      layout: ProfileLayout
    }
  },
  {
    path: "/create-task",
    name: 'CreateTask',
    component: CreateTask
  },
  {
    path: "/task/:id",
    name: 'TaskView',
    component: TaskView
  },
  {
    path: "/tasks",
    name: 'FindTask',
    component: FindTask
  },
  {
    path: "/public-offer",
    name: 'PublicOffer',
    component: PublicOffer
  },
  {
    path: "/categories",
    name: 'CategoriesView',
    component: CategoriesView
  },
  {
    path: "/executors",
    name: 'ExecutorsView',
    component: ExecutorsView
  },
  {
    path: "/sitemap",
    name: "SiteMap",
    component: SiteMap
  },
  {
    path: "/faq",
    name: "FaqView",
    component: FaqView
  },
  {
    path: "/payment-policy",
    name: "PaymentPolicy",
    component: PaymentPolicy
  },
  {
    path: "/referral",
    name: "ReferralView",
    component: ReferralView
  },
  {
    path: "/user/:id",
    name: "ProfileView",
    component: ProfileView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
