<template>
  <div class="profile-index">
    <ProfileInfo :is-executor="isExecutor"/>
    <ProfileAbout/>
    <ProfileExamples v-if="isExecutor"/>
    <ProfileReviews :is-executor="isExecutor"/>
    <div v-if="!isExecutor" class="profile-index-footer">
      <h2>
        Хотите начать зарабатывать?
      </h2>
      <div class="btn-orange" @click="setExecutor">
        Стать исполнителем
      </div>
    </div>
  </div>
</template>
<script>
import ProfileInfo from "@/components/Profile/ProfileIndex/ProfileInfo.vue";
import ProfileAbout from "@/components/Profile/ProfileIndex/ProfileAbout.vue";
import ProfileReviews from "@/components/Profile/ProfileIndex/ProfileReviews/ProfileReviews.vue";
import ProfileExamples from "@/components/Profile/ProfileExamples/ProfileExamples.vue";

export default {
  name: "ProfileIndex",
  components: {ProfileExamples, ProfileReviews, ProfileAbout, ProfileInfo},
  data () {
    return {
      isExecutor: false
    }
  },
  beforeMount () {
    const isExecutor = localStorage.getItem('isExecutor')
    if (isExecutor) {
      this.isExecutor = isExecutor
    }
  },
  methods: {
    setExecutor () {
      localStorage.setItem('isExecutor', 'true')
      this.isExecutor = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./ProfileIndex.scss";
.profile-about{
  margin-top: 26px;
}
.profile-reviews{
  margin-top: 24px;
}
.profile-index{
  padding-bottom: 160px;
}
.profile-examples{
  margin-top: 28px;
}
</style>
