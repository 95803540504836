<template>
  <div class="map-component">
<!--    <l-map id="map" ref="map" :center="[pos.lat, pos.lon]" :zoom="10" :min-zoom="7" :max-zoom="18">-->
      <l-map id="map" ref="map" :center="[50.08333, 14.46667]" :zoom="10" :min-zoom="7" :max-zoom="18">
      <l-tile-layer
        url="http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
        layer-type="base"
      >
      </l-tile-layer>
<!--      <LMarker :latLng="[pos.lat, pos.lon]">-->
      <LMarker ref="marker" :latLng="[50.08333, 14.46667]">
        <LIcon class-name="map-component-icon">
          <div
            v-if="noRadius && !noTips && input"
            class="map-component-tooltip"
          >
            <div>{{input}}</div>
            <svg class="map-component-tooltip-icon" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.36603 11.5C7.98113 12.1667 7.01888 12.1667 6.63397 11.5L1.00481 1.75C0.61991 1.08333 1.10103 0.249999 1.87084 0.249999L13.1292 0.249998C13.899 0.249998 14.3801 1.08333 13.9952 1.75L8.36603 11.5Z" fill="white"/>
            </svg>
          </div>
          <div class="map-component-icon-block">
            <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 0C7.1435 0 0.75 6.43783 0.75 14.3529C0.75 25.5993 13.6605 37.2115 14.2099 37.6992C14.4271 37.8936 14.7082 38.0012 14.9997 38.0015C15.2912 38.0018 15.5725 37.8947 15.7901 37.7008C16.3395 37.2115 29.25 25.5993 29.25 14.3529C29.25 6.43783 22.8565 0 15 0ZM15 22.1667C10.6348 22.1667 7.08333 18.6153 7.08333 14.25C7.08333 9.88475 10.6348 6.33333 15 6.33333C19.3652 6.33333 22.9167 9.88475 22.9167 14.25C22.9167 18.6153 19.3652 22.1667 15 22.1667Z" fill="#FF6B17"/>
            </svg>
          </div>
        </LIcon>
      </LMarker>
      <LCircle
        class-name="map-component-radius"
        :lat-lng="[50.08333, 14.46667]"
        :radius="range * 1000"
        :opacity="radiusOn && !noRadius ? 1 : 0"
        :fill-opacity="radiusOn && !noRadius ? .2 : 0"
      />
      <LControl v-if="!noRadius" class="map-component-footer">
<!--        <div :class="['map-component-footer-container', {'map-component-footer-container-disabled': !radiusOn}]">-->
<!--          <div class="map-component-footer-switcher" @click="radiusOn = !radiusOn">-->
<!--            <div :class="['map-component-footer-switcher-toggle', { 'map-component-footer-switcher-toggle-active': radiusOn}]">-->
<!--              <div v-if="radiusOn" class="map-component-footer-switcher-toggle-text">-->
<!--                  <div><strong>{{ (radiusM / 1000).toFixed(1) }}</strong></div>-->
<!--                  <div>km</div>-->
<!--              </div>-->
<!--              <div v-else class="map-component-footer-switcher-toggle-text">-->
<!--                <strong>Off</strong>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <input class="map-component-footer-input" type="number" placeholder="Укажите радиус" @change="setRadius" :disabled="!radiusOn"/>-->
<!--        </div>-->
        <VueSlider
          v-model="range"
          :interval="0.1"
          :min="0"
          :max="100"
          :process-style="{ backgroundColor: '#FF6B17' }"
          :tooltip="'none'"
        >
          <template v-slot:dot>
            <div class="map-slider-dot">{{range}}</div>
          </template>
        </VueSlider>
      </LControl>
    </l-map>
  </div>
</template>
<script>
import {LMarker, LMap, LTileLayer, LIcon, LCircle, LControl} from '@vue-leaflet/vue-leaflet'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import 'leaflet/dist/leaflet.css'
export default {
  name: "MapComponent",
  components: {
    LControl,
    LCircle,
    LIcon,
    LMap,
    LTileLayer,
    LMarker,
    VueSlider
  },
  props: {
    pos: {
      type: Object,
      default: () => {}
    },
    noRadius: {
      type: Boolean,
      default: () => false
    },
    input: {
      type: String,
      default: () => ''
    },
    noTips: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      radiusOn: true,
      radiusM: 1000,
      range: 0
    }
  },
  methods: {
    setRadius (e) {
      const km = 1000
      if(e.target.value > 100){
        this.radiusM = 100 * km
        e.target.value = 100
      }
      this.radiusM = e.target.value * km
    }
  }
}
</script>
<style lang="scss">
.leaflet-control-attribution {
  display: none!important;
}
#map{
  border-radius: 34px;
  border: 1px solid rgba(30, 39, 47, 0.17);
}
.map-component{
  &-icon{
      background: transparent;
      border: none;
      margin-top: -30px!important;
      margin-left: -17px!important;
  }
  &-radius{
      stroke: #fff!important;
      stroke-width: 1px;
      fill: rgba(255, 107, 23)!important;

  }
  &-footer{
    position: absolute;
    top: 380px;
    left: -470px;
    height: 31px;
    width: 278px;
    &-switcher{
      width: 53px;
      height: 31px;
      background: #FF6B17;
      position: relative;
      border-radius: 26px;
      &-toggle{
        position: absolute;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        font-size: 8px;
        background: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        left: 3px;
        top: 2px;
        animation: togglingOff 0.2s ease;
        &-text{
          //height: 6px;
          text-align: center;
        }
        &-active{
          left: 23px;
          animation: togglingOn 0.2s ease;
        }
        @keyframes togglingOn {
          0% {
            left: 3px;
          }
          100% {
            left: 23px;
          }
        }
        @keyframes togglingOff {
          0% {
            left: 23px;
          }
          100% {
            left: 3px;
          }
        }
      }
    }
    &-input{
      margin-left: 3px;
      width: 70%;
      border: none;
      outline: none;
      &:disabled{
        background: rgb(231 231 231);
        cursor: not-allowed;
      }
    }
    &-container{
      display: flex;
      align-items: center;
      background: #fff;
      height: 31px;
      width: 278px;
      border-radius: 26px;
      &-disabled{
        background: rgb(231 231 231);
      }
    }
    .vue-slider-rail{
      height: 30px;
      background: #fff;
    }
    .vue-slider-process{
      padding-left: 30px;
    }
    .map-slider-dot{
      width: 27px;
      height: 27px;
      background: #fff;
      border-radius: 50%;
      left: 8px;
      top: -6px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-tooltip{
    background: #fff;
    margin-left: -40px;
    margin-bottom: 10px;
    border-radius: 21px;
    padding: 8px 48px;
    min-width: 120px;
    position: relative;
    display: inline-block;
    &-icon{
      position: absolute;
      top: 33px;
    }
  }
}

</style>
