<template>
  <div class="chat-message">
    <div class="chat-message-header">
      <div class="chat-message-header-name">
        Emilia Jones
      </div>
      <div class="chat-message-header-date">
        30.11.19, 14:06
      </div>
      <div class="chat-message-header-done">
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.1454 0L5.01225 6.61752L1.99555 3.3982L0 5.26816L4.87359 10.4691L14 2.01031L12.1454 0Z" fill="#999DA1"/>
        </svg>
      </div>
    </div>
    <div class="chat-message-content">
      Во время работы может произойти любая неприятность. Страховой Дом ВСК защитит вас от компенсации материального ущерба.
    </div>
  </div>
</template>
<script>
export default {
  name: "ChatMessage"
}
</script>
<style lang="scss">
.chat-message{
  &-header{
    display: flex;
    align-content: center;
    &-name{
      font-size: 14px;
      font-weight: 500;
    }
    &-date{
      margin-left: 17px;
      color: #999DA1;
      font-size: 14px;
    }
    &-done{
      margin-left: 10px;
    }
  }
  &-content{
    margin-top: 14px;
    font-weight: 300;
  }
  &:not(:first-child){
    margin-top: 22px;
  }
}
</style>
