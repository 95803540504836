<template>
  <div class="balance-component">
    CZK 2,000
    <div v-if="!withOutIcon" class="balance-component-icon">
      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1009 5.00353C19.0442 4.99996 18.9825 4.99998 18.9186 5L18.9026 5.00001H16.3941C14.3264 5.00001 12.5572 6.62757 12.5572 8.75001C12.5572 10.8724 14.3264 12.5 16.3941 12.5H18.9026L18.9186 12.5C18.9825 12.5 19.0442 12.5001 19.1009 12.4965C19.9408 12.4434 20.6835 11.7862 20.746 10.8682C20.7501 10.808 20.75 10.7431 20.75 10.683L20.75 10.6667V6.83334L20.75 6.81702C20.75 6.75688 20.7501 6.69199 20.746 6.6318C20.6835 5.71381 19.9408 5.05657 19.1009 5.00353ZM16.1717 9.75C16.704 9.75 17.1355 9.30229 17.1355 8.75C17.1355 8.19771 16.704 7.75 16.1717 7.75C15.6394 7.75 15.2078 8.19771 15.2078 8.75C15.2078 9.30229 15.6394 9.75 16.1717 9.75Z" fill="#287EFF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9179 14C19.067 13.9961 19.1799 14.1342 19.1394 14.2778C18.9387 14.9902 18.62 15.5975 18.1088 16.1088C17.3604 16.8571 16.4114 17.1892 15.239 17.3469C14.0998 17.5 12.6442 17.5 10.8064 17.5H8.69359C6.85583 17.5 5.40019 17.5 4.26098 17.3469C3.08856 17.1892 2.13961 16.8571 1.39124 16.1088C0.642881 15.3604 0.310764 14.4114 0.153135 13.239C-2.8044e-05 12.0998 -1.54718e-05 10.6442 3.23432e-07 8.80642V8.69358C-1.5591e-05 6.85583 -2.82824e-05 5.40019 0.153135 4.26098C0.310763 3.08856 0.64288 2.13961 1.39124 1.39124C2.13961 0.642881 3.08856 0.310764 4.26098 0.153136C5.40019 -2.78056e-05 6.85582 -1.5293e-05 8.69358 4.4264e-07L10.8064 3.23431e-07C12.6442 -1.5591e-05 14.0998 -2.82824e-05 15.239 0.153135C16.4114 0.310763 17.3604 0.64288 18.1088 1.39124C18.62 1.90252 18.9386 2.50974 19.1394 3.22218C19.1799 3.36575 19.067 3.50387 18.9179 3.5L16.394 3.50001C13.5574 3.50001 11.0571 5.74091 11.0571 8.75001C11.0571 11.7591 13.5574 14 16.394 14L18.9179 14ZM5 12.5C4.58579 12.5 4.25 12.1642 4.25 11.75V5.75C4.25 5.33579 4.58579 5 5 5C5.41421 5 5.75 5.33579 5.75 5.75V11.75C5.75 12.1642 5.41421 12.5 5 12.5Z" fill="#287EFF"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "BalanceComponent",
  props: {
    withOutIcon: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
.balance-component{
  display: flex;
  //height: 24px;
  line-height: 24px;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  color: #287EFF;
  &-icon{
    height: 21px;
  }
}
</style>
