<template>
  <div class="chat-content">
    <div class="chat-content-header">
      <div :class="['chat-item-img', {'chat-item-img-system': system}]">
        <img v-if="system" src="@/assets/img/executorbanner.png"/>
        <img v-else src="@/assets/img/chatimg.png"/>
      </div>
      <div class="chat-content-name">
        Emilia Jones
      </div>
      <div class="chat-content-texting">
        Пишет сообщение
        <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z" fill="#D9D9D9"/>
          <path d="M11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2Z" fill="#D9D9D9"/>
          <path d="M18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.89543 18 2Z" fill="#D9D9D9"/>
        </svg>
      </div>
    </div>
    <div class="chat-content-container">
      <ChatMessage v-for="i in 10" :key="i"/>
    </div>
    <div class="chat-content-footer">
      <div class="chat-content-footer-container">
        <div class="chat-content-footer-file">
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.9175 16.8068L13.8084 9.25354C14.7558 8.34668 14.7558 6.87637 13.8084 5.96951C12.861 5.06265 11.325 5.06265 10.3776 5.96951L2.54387 13.4681C0.743819 15.1911 0.74382 17.9847 2.54387 19.7077C4.34391 21.4308 7.26237 21.4308 9.06242 19.7077L17.0105 12.0997C19.6632 9.5605 19.6632 5.44362 17.0105 2.90441C14.3578 0.365197 10.0569 0.365197 7.40419 2.90441L1 9.03461" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </div>
        <input type="text"/>
        <div class="chat-content-footer-send">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4128 13.6701L19.2008 8.5208C20.7627 4.02242 21.5437 1.77322 20.3069 0.585953C19.0702 -0.601315 16.7273 0.148415 12.0415 1.64788L6.6776 3.36432C2.89569 4.57453 1.00473 5.17964 0.467379 6.06698C-0.0438136 6.91112 -0.0438137 7.95369 0.467378 8.79783C1.00473 9.68518 2.89569 10.2903 6.67759 11.5005C7.14629 11.6505 7.67358 11.5434 8.02454 11.2096L13.7596 5.75495C14.0822 5.44808 14.6029 5.45041 14.9226 5.76015C15.2422 6.06989 15.2398 6.56975 14.9172 6.87662L9.27473 12.2432C8.88789 12.6111 8.76544 13.1742 8.93764 13.6701C10.1983 17.3007 10.8286 19.1161 11.7529 19.6319C12.6322 20.1227 13.7182 20.1227 14.5975 19.6319C15.5219 19.1161 16.1522 17.3008 17.4128 13.6701Z" fill="#287EFF"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChatMessage from "@/components/Chat/ChatMessage.vue";

export default {
  name: 'ChatContent',
  components: {ChatMessage},
  props: {
    system: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.chat-content{
  border-left: 1px solid #ECEDED;
  margin-left: 326px;
  margin-bottom: 33px;
  width: 100%;
  &-container{
    margin-left: 28px;
    margin-top: 5px;
    width: 97%;
    height: 74%;
    padding-top: 10px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #F0F0F0;
      border-radius: $border-radius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1E272F;
      border-radius: $border-radius;
    }
  }
  &-header{
    display: flex;
    align-items: center;
    margin-left: 28px;
    gap: 21px;
  }
  &-footer{
    border-top: 1px solid #f1f2f2;
    padding: 24px 27px;
    &-container{
      display: flex;
      align-items: center;
      border: 1px solid #1e272f;
      border-radius: 12px;
      padding: 9px;
      width: 648px;
      height: 59px;
    }
    &-file{
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg{
        stroke: #1E272F;
      }
      &:hover{
        svg{
          stroke: #FF6B17;
        }
      }
    }
    input{
      width: 100%;
      border: none;
      border-left: 2px solid #D9D9D9;
      padding-left: 20px;
      outline: none;
      font-weight: 300;
      height: 100%;
    }
    &-send{
      height: 20px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  &-name{
    font-size: 18px;
    font-weight: 500;
  }
  &-texting{
    margin-left: 10px;
    color: #D9D9D9;
    font-weight: 300;
  }
}
</style>
