<template>
  <div class="leave-create-task-modal">
    <div class="modal-header">
      Введённые данные будут потеряны
    </div>
    <div class="leave-create-task-modal-content">
      <div class="leave-create-task-modal-content-text">
        Удалить задание?
      </div>
      <div class="btn btn-secondary" @click="confirmDelete">
        Да, удалить
      </div>
    </div>
    <div class="leave-create-task-modal-footer">
      <div class="leave-create-task-modal-footer-text">
        Продолжить создание задания
      </div>
      <div class="btn-primary" @click="closeModal">
        Назад к заданию
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LeaveCreateTaskModal',
  computed: {
    modal(){
      return this.$store.getters.getModal
    }
  },
  methods: {
    confirmDelete(){
      this.modal.callBack()
    },
    closeModal(){
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.leave-create-task-modal{
  .modal-header{
    padding: 56px 57px 54px;
  }
  .btn-secondary{
    margin: 26px auto 0;
    width: 337px;
    height: 98px;
    font-size: 22px;
    color: #1e272f;
    &:hover{
      color: #fff;
    }
  }
  &-content{
    border-top: 1px solid rgba(0,0,0, .12);
    border-radius: 21px;
    background: #fff;
    margin-top: -14px;
    &-text{
      text-align: center;
      margin-top: 43px;
      font-size: 22px;
      font-weight: 300;
    }
  }
  &-footer{
    border-top: 1px solid rgba(0,0,0, .12);
    border-radius: 21px;
    margin-top: 41px;
    padding-bottom: 42px;
    &-text{
      margin-top: 41px;
      text-align: center;
      font-size: 22px;
      font-weight: 300;
    }
    .btn-primary{
      margin: 31px auto 0;
      width: 336px;
      height: 97px;
      font-size: 22px;
    }
  }
}
</style>
