<template>
  <div class="modal-registration">
    <div v-if="steps[step].title" class="modal-header">
      {{ steps[step].title }}
    </div>
    <div>
      <component :is="steps[step].name" :with-link="true" @nextStep="step++" @backStep="step--"/>
    </div>
    <div v-if="step === 0" class="modal-registration-footer">
      <div class="modal-registration-footer-link" @click="authorize">
        Уже зарегистрированы?
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationStep1 from './RegistrationSteps/RegistrationStep1.vue'
import RegistrationStep2 from "@/components/Modals/ModalAuth/RegistrationSteps/RegistrationStep2.vue";
import RegistrationStep3 from "@/components/Modals/ModalAuth/RegistrationSteps/RegistrationStep3.vue";
import RegistrationStep4 from "@/components/Modals/ModalAuth/RegistrationSteps/RegistrationStep4.vue";
export default {
  name: "ModalRegistration",
  components: {
    RegistrationStep1,
    RegistrationStep2,
    RegistrationStep3,
    RegistrationStep4
  },
  data () {
    return {
      steps: [
        {
          name: 'registration-step1',
          title: 'Регистрация'
        },
        {
          name: 'registration-step2',
        },
        {
          name: 'registration-step3',
          title: 'Завершение регистрации'
        },
        {
          name: 'registration-step4'
        }
      ],
      step: 0,
    }
  },
  methods: {
    authorize() {
      this.$store.dispatch('setIsOpen', false)
      this.$store.dispatch('setModal', { modalName: 'ModalLogin'})
    }
  }
}
</script>
<style lang="scss">
.modal-registration{
  width: 592px;
  margin-top: -14px;
  &-container{
    border-top: 1px solid rgba(0,0,0, .12);
    border-radius: 21px;
    background: #fff;
  }
  &-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95px;
    border-top: 1px solid rgba(0,0,0, .12);
    border-radius: 21px;
    &-link{
      color: #287EFF;
      cursor: pointer;
      &:hover{
        color:#FF6B17;
        text-decoration: underline;
      }
    }
  }
}
</style>
