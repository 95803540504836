<template>
  <div class="ui-input">
    <div class="ui-input-label">
      {{ label }}:
    </div>
    <div class="ui-input-input">
      <div v-if="multiSelect" class="ui-input-input-multiselect">
        {{placeholder}}
      </div>
      <div v-else-if="time" class="ui-input-input-time">
        <input ref="hours" placeholder="ЧЧ" @input="setHours" v-model="hours"/> : <input ref="minutes" placeholder="ММ" @input="setMinutes" v-model="minutes"/>
      </div>
      <input v-else :type="type" :placeholder="placeholder" v-model="modelValue"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "UiInput",
  props: {
    label: {
      type: String,
      default: () => ""
    },
    type: {
      type: String,
      default: () => "text"
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    multiSelect: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: String,
      default: () => ''
    },
    time: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data () {
    return {
      hours: null,
      minutes: null
    }
  },
  methods: {
    setHours(e){
      if (!Number(e.data)) {
        this.hours = null
        return
      }
      if (this.hours > 24) {
        this.hours = 24
        this.$refs.minutes.focus()
      }
      if (this.hours < 0 ){
        this.hours = 0
        this.$refs.minutes.focus()
      }
    },
    setMinutes(e){
      if (!Number(e.data)) {
        this.minutes = null
        return
      }
      if (this.minutes > 59) {
        this.minutes = this.hours === 24 || 0 ? "00" : 59
        this.$refs.minutes.blur()
      }
      if (this.minutes < 0 ){
        this.minutes = 0
        this.$refs.minutes.blur()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-input{
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background: #f2f2f2;
  border-radius: 80px;
  height: 66px;
  &-label{
    color: rgba(#1e272f, .51);
    margin-left: 25px;
    font-size: 14px;
    min-width: 65px;
  }
  &-input{
    color: #1e272f;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 80px;
    flex-grow: 1;
    margin-left: 15px;
    &-multiselect{
      margin-left: 20px;
      font-size: 14px;
      color: #999DA1;
    }
    &-time{
      display: flex;
      align-items: center;
      input{
        margin-left: 0!important;
        text-align: center;
      }
    }
    input{
      font-size: 14px;
      border: none;
      outline: none;
      margin-left: 25px;
      width: 74%;
      height: 80%;
    }
  }
}
</style>
