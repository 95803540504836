<template>
  <div class="app-card google-play">
    <div class="app-card-icon google-play-icon">
      <img src="@/assets/img/googleplay.png"/>
      <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect x="0.5" y="0.5" width="85" height="85" rx="22.5" fill="white" stroke="url(#paint0_linear_0_1)"/>
        <defs>
          <linearGradient id="paint0_linear_0_1" x1="43" y1="0" x2="43" y2="86" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2196F3"/>
            <stop offset="0.286458" stop-color="#4CB050"/>
            <stop offset="0.661458" stop-color="#FEC107"/>
            <stop offset="1" stop-color="#F44236"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="app-card-text">
      Google Play
    </div>
  </div>
</template>
<script>
export default {
  name: "GooglePlayCard"
}
</script>
<style scoped lang="scss">
.google-play-icon {
  position: relative;
  img {
    width: 68px;
    height: 68px;
    border-radius: 23px;
    position: absolute;
    z-index: 1;
    left: 52%;
    top:48%;
    transform: translate(-50%, -50%);
  }
  //border: 1px solid;
  //border-image-source: linear-gradient(180deg, #2196F3 0%, #4CB050 28.65%, #FEC107 66.15%, #F44236 100%);
}
</style>