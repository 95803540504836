<template>
  <div class="modal-only-executors">
    <div class="modal-only-executors-header">
      <div class="modal-only-executors-header-icon">
        <img src="@/assets/img/executorbanner.png"/>
      </div>
      <div class="modal-only-executors-header-text">
        <div class="modal-only-executors-header-title">
          {{ props.title ||  "На задания могут откликаться только исполнители"}}
        </div>
        <div class="modal-only-executors-header-description">
          {{ props.description || "Хотите стать исполнителем? Это не сложно. Всего пара шагов. Всё займёт примерно 5 минут."}}
        </div>
      </div>
    </div>
    <div v-if="props.haveTasks" class="modal-only-executors-tasks">
      <div class="modal-only-executors-tasks-container">
        <div v-for="i in 15" :key="i" class="modal-only-executors-tasks-item">
          <UiCheckbox size="medium" color="orange" title="Мне нужно срочно убрать квартиру к приходу гостей такой длины текст"/>
        </div>
      </div>
    </div>
    <div class="modal-only-executors-actions">
      <div class="btn-primary">
        {{ props.confirmBtn || "Стать исполнителем"}}
      </div>
      <div class="btn btn-secondary" @click="closeModal">
        {{ props.cancelBtn || "Закрыть"}}
      </div>
    </div>
  </div>
</template>
<script>
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";

export default {
  name: 'ModalOnlyExecutors',
  components: {UiCheckbox},
  props: {
    props:{
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.modal-only-executors{
  width: 922px;
  padding: 54px 48px 68px 76px;
  &-header{
    display: flex;
    align-items: center;
    gap: 17px;
    &-icon{
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 41px;
        height: 34px;
      }
    }
    &-title{
      font-size: 22px;
      font-weight: 500;
    }
  }
  &-actions{
    margin-top: 39px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 22px;
    .btn-primary{
      width: 297px;
      height: 98px;
    }
    .btn-secondary{
      width: 171px;
      height: 98px;
    }
  }
  &-tasks{
    border: 1px solid #ECEDED;
    padding: 27px;
    border-radius: 21px;
    max-height: 263px;
    &-container{
      overflow-y: scroll;
      max-height: 223px;
      &::-webkit-scrollbar {
        width: 4px;
        margin-left: -10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #F0F0F0;
        border-radius: $border-radius;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #1E272F;
        border-radius: $border-radius;
      }
    }
    &-item:not(:first-child){
      margin-top: 16px;
    }
    label{
      font-size: 16px!important;
    }
  }
}
</style>
