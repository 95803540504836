<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <div :class="['dropdown-icon',{ 'dropdown-icon-active': isOpen, 'dropdown-icon-disabled': disabled }]" @click="openDropdown">
      <slot name="icon"/>
    </div>
    <transition name="fade">
      <div v-show="isOpen" :class="['dropdown-list',`${positions[position]}`]">
        <slot name="list"/>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "DropdownComponent",
  props: {
    position: {
      type: String,
      default: () => "left-bottom"
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return{
      isOpen: false,
      positions: {
        "left-bottom": "dropdown-list-left-bottom",
        "bottom": "dropdown-list-bottom",
        "top": "dropdown-list-top"
      }
    }
  },
  methods: {
    openDropdown(){
      if (this.disabled) return
      this.isOpen = !this.isOpen
    },
    closeDropdown(){
      this.isOpen = false;
    }
  }
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.dropdown{
  position: relative;
  &-icon{
    display: flex;
    align-items: center;
    gap:10px;
    cursor: pointer;
    &-disabled{
      cursor: not-allowed;
    }
    .dropdown-svg {
      fill: #1E272F;
      &-grey{
        fill: #999DA1;
      }
    }
    &-active{
      .dropdown-svg{
        fill: #FF6B17;
        transform: rotate(-180deg);
      }
    }
  }
  &-list{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.25);
    border-radius: 21px;
    padding: 24px 0;
    z-index: 2;
    &-left-bottom{
      left: -210px;
      top: 68px;
    }
    &-bottom{
      left: -50%;
    }
    &-top{
      top: -300%;
    }
  }
}
</style>
