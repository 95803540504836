<template>
  <div class="modal">
    <div class="modal-container">
      <div v-if="!modal.customClose" class="modal-close" @click="closeModal">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_186_312)">
            <path d="M1.51672 15.8995C1.12447 15.9223 0.738678 15.7919 0.440711 15.5358C-0.146904 14.9447 -0.146904 13.99 0.440711 13.3989L13.3075 0.532C13.9187 -0.0398937 14.8777 -0.00810248 15.4496 0.603069C15.9668 1.15575 15.9969 2.00523 15.5202 2.59314L2.57756 15.5358C2.28347 15.7882 1.90384 15.9184 1.51672 15.8995Z" />
            <path d="M14.3685 15.8996C13.9709 15.8979 13.5899 15.74 13.3076 15.4601L0.440711 2.59313C-0.103684 1.9574 -0.0296705 1.00067 0.606055 0.456224C1.17346 -0.0296788 2.01026 -0.0296788 2.57761 0.456224L15.5203 13.3231C16.1313 13.8952 16.1629 14.8542 15.5908 15.4653C15.5681 15.4896 15.5446 15.5131 15.5203 15.5358C15.3635 15.6722 15.1803 15.7748 14.9822 15.8374C14.784 15.9 14.5751 15.9211 14.3685 15.8996Z" />
          </g>
        </svg>
      </div>
      <div class="modal-header" v-if="modal.greyHeader">
        {{ modal.title }}
      </div>
      <div class="modal-content" v-click-outside="closeModal">
        <component :is="modal.modalName" :title="modal.title" :props="modal.props" />
      </div>
    </div>
  </div>
</template>
<script>
import ModalDeposit from "@/components/Modals/ModalDeposit/ModalDeposit.vue";
import AddExample from "@/components/Modals/AddExample.vue";
import ModalWithdraw from "@/components/Modals/ModalWithdraw/ModalWithdraw.vue";
import PublishedTask from "@/components/Modals/PublishedTask.vue";
import ModalRegistration from "@/components/Modals/ModalAuth/Registration.vue";
import ModalLogin from "@/components/Modals/ModalAuth/Login.vue";
import LeaveCreateTaskModal from "@/components/Modals/LeaveCreateTask.vue";
import ModalMap from "@/components/Modals/ModalMap.vue"
import ModalTaskCancel from "@/components/Modals/ModalTaskCancel.vue";
import ModalRespondTask from "@/components/Modals/ModalRespondTask.vue";
import ModalOnlyExecutors from "@/components/Modals/ModalOnlyExecutors.vue";
import ModalTaskDoneFixing from "@/components/Modals/ModalTaskDoneFixing.vue";
export default {
  name: "ModalIndex",
  components:{
    ModalDeposit,
    AddExample,
    ModalWithdraw,
    PublishedTask,
    ModalRegistration,
    ModalLogin,
    LeaveCreateTaskModal,
    ModalMap,
    ModalTaskCancel,
    ModalRespondTask,
    ModalOnlyExecutors,
    ModalTaskDoneFixing
  },
  computed: {
    modal(){
      return this.$store.getters.getModal
    }
  },
  methods: {
    closeModal(e){
      if (e.target.type === 'checkbox') return
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.modal{
  width: 100%;
  min-width: 100vh;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: rgba(#1E272F, .22);
  display: flex;
  align-items: center;
  justify-content: center;
  &-header{
    background: #F8F8F8;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    padding: 56px 124px 44px;
  }
  &-container{
    position: relative;
    background: #fff;
    box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.25);
    border-radius: 21px;
  }
  &-close{
    border-radius: 50px;
    width: 50px;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    cursor: pointer;
    svg{
      fill: #1E272F;
    }
    &:hover{
      svg{
        fill: #FF6B17;
      }
    }
  }
  &-title{
    text-align: center;
  }
  &-white-input{
    border-radius: $border-radius;
    background: #fff;
    padding: 44px 46px;
    box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.17);

  }
  &-primary-btn{
    border-radius: $border-radius;
    background: #287EFF;
    padding: 37px 54px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    &:hover{
      opacity: .75;
    }
  }
}
</style>
