<template>
  <div class="profile-categories">
    <div class="title">
      Виды выполняемых работ
    </div>
    <div class="profile-categories-container">
      <ProfileCategoryCard :category="{'name': 'Уборка и помощь по дому', subcategories: [{
        'id': 1,
        'name': 'Перевозка вещей'
      },{
        'id': 2,
        'name': 'Переезд'
      },{
        'id': 3,
        'name': 'Вывоз мусора'
      }]}"/>
      <ProfileCategoryCard :category="{'name': 'Грузоперевозки', subcategories: [{
        'id': 1,
        'name': 'Перевозка вещей'
      },{
        'id': 2,
        'name': 'Переезд'
      },{
        'id': 3,
        'name': 'Вывоз мусора'
      }]}"/>
    </div>
    <div class="profile-categories-action">
      <div style="height: 17px;">Показать ещё категории</div>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.9743 0.999694L3.9743 6.94952M3.9743 6.94952L1.11839 4.09361M3.9743 6.94952L6.83022 4.09361" stroke="#FF6B17" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>
<script>
import ProfileCategoryCard from "@/components/Profile/ProfileSettings/ProfileCategoryCard.vue";

export default {
  name: 'ProfileCategories',
  components: {ProfileCategoryCard}
}
</script>
<style lang="scss" scoped>
.profile-categories{
  &-action {
    margin-top: 20px;
    color: #FF6B17;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    border-top: 1px solid #ECEDED;
    border-bottom: 1px solid #ECEDED;
    padding: 20px 49px 22px;
    cursor: pointer;
    &:hover{
      opacity: .75;
    }
  }
  &-container{
    margin-top: 24px;
  }
  .profile-category-card:not(:first-child){
    margin-top: 8px;
  }
}
</style>
