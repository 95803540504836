<template>
  <div class="login-step1">
    <div class="login-step1-danger" v-if="invalidLogin || invalidPassword">
      <div class="login-step1-danger-icon">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.85456 5.2492L4.38179 0.703625C3.9845 0.034783 3.01603 0.0338944 2.6182 0.703625L0.145563 5.2492C-0.260574 5.93264 0.231135 6.7981 1.02715 6.7981H5.97276C6.76811 6.7981 7.26069 5.93334 6.85456 5.2492ZM3.5 5.97778C3.27389 5.97778 3.08984 5.79373 3.08984 5.56763C3.08984 5.34152 3.27389 5.15747 3.5 5.15747C3.7261 5.15747 3.91015 5.34152 3.91015 5.56763C3.91015 5.79373 3.7261 5.97778 3.5 5.97778ZM3.91015 4.33716C3.91015 4.56326 3.7261 4.74731 3.5 4.74731C3.27389 4.74731 3.08984 4.56326 3.08984 4.33716V2.28638C3.08984 2.06027 3.27389 1.87622 3.5 1.87622C3.7261 1.87622 3.91015 2.06027 3.91015 2.28638V4.33716Z" fill="white"/>
        </svg>
      </div>
      <div>
        Введен неверный логин или пароль
      </div>
    </div>
    <UiFormInput grey placeholder="Логин:" :invalid="invalidLogin" :model-value="login" @update:modelValue="setLogin"/>
    <UiFormInput grey placeholder="Пароль:" :invalid="invalidPassword" :model-value="password" @update:modelValue="setPassword"/>
    <div class="btn-primary" @click="auth">
      Войти
    </div>
    <div class="login-step1-footer">
      <router-link class="login-step1-footer-link" to="/to">
        Забыли пароль ?
      </router-link>
    </div>
  </div>
</template>
<script>
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";

export default {
  name: 'LoginStep1',
  components: {UiFormInput},
  data () {
    return {
      login: '',
      password: '',
      invalidLogin: false,
      invalidPassword: false
    }
  },
  methods: {
    setLogin(value) {
      this.login = value
    },
    setPassword(value){
      this.password = value
    },
    auth(){
      if (this.login.length === 0){
        this.invalidLogin = true
      }
      if(this.password.length === 0) {
        this.invalidPassword = true
      }
      if(!this.invalidLogin && this.invalidPassword){
        this.$store.dispatch('setIsOpen', false)
      }
    }
  }
}
</script>
<style lang="scss">
.login-step1{
  padding-top: 24px;
  width: 312px;
  margin:0 auto;
  .btn-primary{
    height: 98px;
    font-size: 22px;
    margin-top: 12px;
    font-weight: 400;
  }
  .ui-form-input{
    margin-top:12px;
    padding: 26px 30px;
    input{
      text-align: center;
    }
  }
  &-footer{
    margin-top: 34px;
    padding-bottom: 46px;
    &-link{
      color:#287EFF;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
  &-danger{
    display: flex;
    align-items: center;
    gap: 4px;
    color: #F44236;
    font-size: 12px;
    text-align: center;
    width: 224px;
    margin: 0 auto;
    &-icon{
      background: #F44236;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
