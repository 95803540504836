<template>
  <div class="container-banners apps-banner">
    <div class="container apps-banner-container">
      <div class="apps-banner-title banners-title">
        Скачайте наше приложение
      </div>
      <div class="apps-banner-stores">
        <GooglePlayCard/>
        <AppStoreCard/>
      </div>
      <div class="apps-banner-qr">
        <img src="@/assets/img/qr.png">
      </div>
    </div>
  </div>
</template>
<script>
import GooglePlayCard from "@/components/GooglePlayCard.vue";
import AppStoreCard from "@/components/AppStoreCard.vue";

export default {
  name: "ApplicationsBanner",
  components: {AppStoreCard, GooglePlayCard}
}
</script>
<style lang="scss">
@import "./Applications.scss";
</style>