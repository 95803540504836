<template>
  <div class="modal-withdraw">
    <div v-if="! sended" class="modal-withdraw-container" >
      <div class="modal-withdraw-header">
        Заявка на вывод средств
      </div>
      <div class="modal-withdraw-content">
        <UiFormInput class="modal-withdraw-content-input" title="Номер карты:" type="number" placeholder="0000 0000 0000 0000" :maxLength="16"/>
        <UiFormInput class="modal-withdraw-content-input" title="Сумма:" type="number" placeholder="CZK"/>
        <div class="modal-withdraw-content-actions">
          <div class="modal-withdraw-btn modal-primary-btn" @click="sended = true">
            <div>
              Далее
            </div>
            <svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.5646 8.94928L34.5633 8.94787L26.8071 1.22912C26.2261 0.650882 25.2862 0.653034 24.7079 1.23417C24.1296 1.81523 24.1318 2.75506 24.7128 3.33337L29.9203 8.51562H-1.51562C-2.33545 8.51562 -3 9.18017 -3 10C-3 10.8198 -2.33545 11.4844 -1.51562 11.4844H29.9202L24.7129 16.6666C24.1319 17.2449 24.1296 18.1848 24.7079 18.7658C25.2863 19.347 26.2262 19.349 26.8072 18.7709L34.5634 11.0521L34.5647 11.0507C35.1461 10.4705 35.1442 9.52759 34.5646 8.94928Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="modal-withdraw-sended">
      <div class="modal-withdraw-sended-img">
        <img src="@/assets/img/executorbanner.png">
      </div>
      <div class="modal-withdraw-sended-text">
        Мы получили вашу заявку, ожидайте
        получения средств
      </div>
    </div>
  </div>
</template>
<script>

import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";

export default {
  name: "ModalWithdraw",
  components: {UiFormInput},
  data () {
    return {
      sended: false
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-withdraw{
  &-header{
    text-align: center;
    font-size: 20px;
    padding: 47px 114px;
  }
  &-content{
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    padding: 33px 75px;
    &-actions{
      display: flex;
      align-items: center;
      gap: 50px;
      margin-top: 24px;
      justify-content: center;
    }
    &-input{
      margin-top: 13px;
      width: 353px;
    }
  }
  &-footer{
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    padding: 34px 114px;
    text-align: center;
    font-size: 20px;
    &-content{
      display: flex;
      align-items: center;
      gap: 38px;
      margin-top: 27px;
    }
  }
  &-btn{
    display: flex;
    align-items: center;
    gap: 19px;
  }
  &-sended{
    padding: 56px 114px;
    &-img{
      margin: 0 auto;
      width: 120px;
    }
    img{
      width: 120px;
      height: 100px;
    }
    &-text{
      font-size: 20px;
      width: 364px;
      text-align: center;
      margin-top: 38px;
    }
  }
}
</style>
