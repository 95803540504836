<template>
  <div class="modal-task-done-fixing">
    <div class="modal-task-done-fixing-header">
      {{ props.done ? 'Задание выполнено' : 'Задание не выполнено' }}
    </div>
    <div class="modal-task-done-fixing-content">
      <div v-if="props.done" class="modal-task-done-fixing-content-done">
        <div class="modal-task-done-fixing-content-title">
          Ваша оценка
        </div>
        <StarsComponent/>
        <UiFormTextArea title="Отзыв*:"/>
      </div>
      <div v-else class="modal-task-done-fixing-content-not-done">
        <div class="modal-task-done-fixing-content-title">
          Пожалуйста, укажите причину, по которой задание не было выполнено:
        </div>
        <div v-if="isExecutor" class="modal-task-done-fixing-content-not-done-switchers">
          <UiCheckbox
            v-for="item in reasonListExecutor"
            :key="item.id"
            :title="item.text"
            color="orange"
            :model-value="item.id"
            @update:modelValue="setActive"
          />
          <UiFormTextArea v-if="active === 8"/>
        </div>
        <div v-else class="modal-task-done-fixing-content-not-done-switchers">
          <UiCheckbox
            v-for="item in reasonList"
            :key="item.id"
            :title="item.text"
            color="orange"
            :model-value="item.id"
            @update:modelValue="setActive"
          />
          <UiFormTextArea v-if="active === 8"/>
        </div>
      </div>
    </div>
    <div class="modal-task-done-fixing-footer">
      <div class="btn-orange" @click="closeModal">
        Сохранить
      </div>
      <div class="btn btn-secondary" @click="closeModal">
        Закрыть
      </div>
    </div>
  </div>
</template>
<script>
import StarsComponent from "@/components/Profile/Stars.vue";
import UiFormTextArea from "@/components/Ui/Form/UiFormTextArea.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";

export default {
  name: 'ModalTaskDoneFixing',
  components: {UiCheckbox, UiFormTextArea, StarsComponent},
  props: {
    props: {
      done: {
        type: Boolean,
        default: () => false
      },
      isExecutor: {
        type: Boolean,
        default: () => false
      }
    }
  },
  data () {
    return {
      reasonList: [
        {
          id: 1,
          text: "Случайно выбрал не того исполнителя"
        },
        {
          id: 2,
          text: "Исполнитель изменил условия сотрудничества"
        },
        {
          id: 3,
          text: "Исполнитель отказался выполнять задание"
        },
        {
          id: 4,
          text: "Исполнитель опоздал"
        },
        {
          id: 5,
          text: "Работа выполнена не полностью или некачественно"
        },
        {
          id: 6,
          text: "Я столкнулся с мошенничеством"
        },
        {
          id: 7,
          text: "Задание перестало быть актуальным"
        },
        {
          id: 8,
          text: "Другая причина"
        }
      ],
      reasonListExecutor: [
        {
          id: 1,
          text: "Не смог связаться с заказчиком"
        },
        {
          id: 2,
          text: "Заболел"
        },
        {
          id: 3,
          text: "Заказчик не предоставил необходимых условий"
        },
        {
          id: 4,
          text: "Попал в дтп"
        },
        {
          id: 5,
          text: "Заказчика не было дома"
        },
        {
          id: 6,
          text: "Другая причина"
        }
      ],
      active: 1
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('setIsOpen', false)
    },
    setActive(value) {
      this.active = value.active ? value.value : 0
    }
  }
}
</script>
<style lang="scss">
.modal-task-done-fixing{
  width: 1160px;
  &-header{
    padding: 55px 79px 37px;
    font-size: 28px;
  }
  &-content{
    border-top: 1px solid rgba(0,0,0,.12);
    border-radius: 21px;
    padding: 26px 47px 29px;
    &-title{
      padding-left: 32px;
    }
    &-done{
      .stars-container{
        margin-top: 19px;
        padding-left: 32px;
      }
      .ui-form-text-area{
        margin-top: 48px;
      }
      .ui-checkbox{
        margin-top: 10px;
      }
    }
    &-not-done{
      &-switchers{
        margin-top: 30px;
        padding-left: 32px;
        .ui-checkbox-input + label{
          font-size: 16px!important;
        }
        .ui-checkbox{
          width: 100%;
          margin-top: 10px;
          &:not(:first-child){
            margin-top: 19px;
          }
          &-checkbox{
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
  &-footer{
    padding: 42px 47px 45px;
    border-top: 1px solid rgba(0,0,0,.12);
    border-radius: 21px;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 22px;
    .btn-orange{
      width: 280px;
      height: 98px;
    }
    .btn-secondary{
      width: 171px;
      height: 98px;
    }
  }
}
</style>
