<template>
  <div :class="['ui-form-input', {'ui-form-input-grey': grey, 'ui-form-input-invalid': invalid }]">
    <div :class="['ui-form-input-title', {'ui-form-input-title-black': blackTitle}]">
      {{title}}
    </div>
    <div v-if="type !== 'date'" class="ui-form-input-input" >
      <input :type="type" :placeholder="placeholder" v-model="vModel" @input="changeInput"/>
    </div>
    <div v-else class="ui-form-input-input ui-form-input-input-date">
      <input type="number" v-model="month" @input="changeMonth" placeholder="00"/>
      <span>/</span>
      <input type="number" v-model="year" @input="changeYear" ref="year" placeholder="00"/>
    </div>
    <div v-if="danger" class="ui-form-input-danger" @click="setValid">
      <div class="ui-form-input-danger-icon">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_326_791)">
            <path d="M6.85456 5.2492L4.38179 0.703625C3.9845 0.034783 3.01603 0.0338944 2.6182 0.703625L0.145563 5.2492C-0.260574 5.93264 0.231135 6.7981 1.02715 6.7981H5.97276C6.76811 6.7981 7.26069 5.93334 6.85456 5.2492ZM3.5 5.97778C3.27389 5.97778 3.08984 5.79373 3.08984 5.56763C3.08984 5.34152 3.27389 5.15747 3.5 5.15747C3.7261 5.15747 3.91015 5.34152 3.91015 5.56763C3.91015 5.79373 3.7261 5.97778 3.5 5.97778ZM3.91015 4.33716C3.91015 4.56326 3.7261 4.74731 3.5 4.74731C3.27389 4.74731 3.08984 4.56326 3.08984 4.33716V2.28638C3.08984 2.06027 3.27389 1.87622 3.5 1.87622C3.7261 1.87622 3.91015 2.06027 3.91015 2.28638V4.33716Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_326_791">
              <rect width="7" height="7" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="ui-form-input-danger-text">
        {{ dangerText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UiFormInput",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    type: {
      type: String,
      default: () => "string"
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    maxLength: {
      type: Number,
      default: () => 100
    },
    blackTitle: {
      type: Boolean,
      default: () => false
    },
    modelValue: {
      type: String,
      default: () => ''
    },
    grey: {
      type: Boolean,
      default: () => false
    },
    dangerText: {
      type: String,
      default: () => ''
    },
    invalid: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      vModel: this.modelValue,
      month: null,
      year: null,
      danger: false
    }
  },
  methods: {
    changeInput(){
      if(this.maxLength > 0 && String(this.vModel).split('').length > this.maxLength){
        this.vModel = String(this.vModel).split('', this.maxLength).join('')
      }
      this.$emit('update:modelValue', this.vModel)
    },
    changeMonth(){
      if(this.month > 12 || this.month < 0 || !Number(this.month)){
        this.month = 12
        this.$refs.year.focus()
      } else if(String(this.month).split('').length === 2) {
        this.$refs.year.focus()
      }
    },
    changeYear(){
      if(this.year > 99 || this.year < 0 || !Number(this.year)){
        this.year = 23
      }
    },
    setDanger(value) {
      this.danger = value
    },
    setValid() {
      this.$emit('validate', false)
      this.danger = false
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-form-input{
  border: 3px solid #f2f2f2;
  padding: 15px 30px;
  border-radius: 104px;
  position: relative;
  &-invalid{
    border: 1px solid #F44236;
  }
  &-title{
    color: rgba(30, 39, 47, .51);
    &-black{
      margin-top: 3px;
      color: #1e272f;
    }
  }
  &-grey{
    background: #f2f2f2;
  }
  &-input{
    margin-top: 4px;
    &-date{
      display: flex;
      align-items: center;
      width: 50px;
      gap: 4px;
    }
    input{
      width: 100%;
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
    }
  }
  &-danger{
    border: 1px solid #F44236;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 104px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding-left: 26px;
    &-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      background: #F44236;
      border-radius: 50%;
    }
    &-text{
      color: #F44236;
      margin-left: 4px;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
</style>
