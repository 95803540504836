<template>
  <div>
    <VueDatePicker
      v-model="date"
      auto-apply
      :range="isRange"
      no-today
      :alt-position="datePickerPos"
      @range-start="onRangeStart"
      @range-end="onRangeEnd"
      @closed="onClosed"
    >
      <template #trigger v-if="!icon">
        <div v-if="date.length === 2 && date[1]">
          <div class="date-picker-input">
            <div class="date-picker-input-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2C13.3333 3.10457 12.4379 4 11.3333 4C10.2288 4 9.33333 3.10457 9.33333 2C9.33333 0.89543 10.2288 0 11.3333 0C12.4379 0 13.3333 0.89543 13.3333 2Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 8C2.83333 7.72386 3.05719 7.5 3.33333 7.5H9.33333C9.60948 7.5 9.83333 7.72386 9.83333 8C9.83333 8.27614 9.60948 8.5 9.33333 8.5H3.33333C3.05719 8.5 2.83333 8.27614 2.83333 8Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 12.357C1.95262 13.3333 3.52397 13.3333 6.66667 13.3333C9.80936 13.3333 11.3807 13.3333 12.357 12.357C13.3333 11.3807 13.3333 9.80936 13.3333 6.66667C13.3333 5.73416 13.3333 4.94 13.3078 4.25868C12.7802 4.72028 12.0894 5 11.3333 5C9.67648 5 8.33333 3.65685 8.33333 2C8.33333 1.24389 8.61305 0.553114 9.07466 0.0255059C8.39334 3.72529e-08 7.59918 0 6.66667 0C3.52397 0 1.95262 0 0.976311 0.976311C0 1.95262 0 3.52397 0 6.66667C0 9.80936 0 11.3807 0.976311 12.357ZM2.83333 10.3333C2.83333 10.0572 3.05719 9.83333 3.33333 9.83333H7.33333C7.60948 9.83333 7.83333 10.0572 7.83333 10.3333C7.83333 10.6095 7.60948 10.8333 7.33333 10.8333H3.33333C3.05719 10.8333 2.83333 10.6095 2.83333 10.3333Z" fill="#1E272F"/>
              </svg>
            </div>
            <div :class="['date-picker-input-text', {'date-picker-input-text-active': date[0]}]">
              С {{ date[0].toLocaleDateString('ru-RU') }}
            </div>
          </div>
          <div class="date-picker-input date-picker-input-second">
            <div class="date-picker-input-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2C13.3333 3.10457 12.4379 4 11.3333 4C10.2288 4 9.33333 3.10457 9.33333 2C9.33333 0.89543 10.2288 0 11.3333 0C12.4379 0 13.3333 0.89543 13.3333 2Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 8C2.83333 7.72386 3.05719 7.5 3.33333 7.5H9.33333C9.60948 7.5 9.83333 7.72386 9.83333 8C9.83333 8.27614 9.60948 8.5 9.33333 8.5H3.33333C3.05719 8.5 2.83333 8.27614 2.83333 8Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 12.357C1.95262 13.3333 3.52397 13.3333 6.66667 13.3333C9.80936 13.3333 11.3807 13.3333 12.357 12.357C13.3333 11.3807 13.3333 9.80936 13.3333 6.66667C13.3333 5.73416 13.3333 4.94 13.3078 4.25868C12.7802 4.72028 12.0894 5 11.3333 5C9.67648 5 8.33333 3.65685 8.33333 2C8.33333 1.24389 8.61305 0.553114 9.07466 0.0255059C8.39334 3.72529e-08 7.59918 0 6.66667 0C3.52397 0 1.95262 0 0.976311 0.976311C0 1.95262 0 3.52397 0 6.66667C0 9.80936 0 11.3807 0.976311 12.357ZM2.83333 10.3333C2.83333 10.0572 3.05719 9.83333 3.33333 9.83333H7.33333C7.60948 9.83333 7.83333 10.0572 7.83333 10.3333C7.83333 10.6095 7.60948 10.8333 7.33333 10.8333H3.33333C3.05719 10.8333 2.83333 10.6095 2.83333 10.3333Z" fill="#1E272F"/>
              </svg>
            </div>
            <div :class="['date-picker-input-text', {'date-picker-input-text-active': date[1]}]">
              По {{ date[1].toLocaleDateString('ru-RU')}}
            </div>
          </div>
        </div>
        <div v-else class="date-picker-date-time">
          <div class="date-picker-input">
            <div v-if="invalid" class="date-picker-input-danger">
              <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.20833C0 2.60954 0 1.81014 0.188758 1.5412C0.377516 1.27227 1.12916 1.01498 2.63246 0.500396L2.91886 0.402358C3.70249 0.134119 4.09431 0 4.5 0C4.90569 0 5.29751 0.13412 6.08114 0.402359L6.36755 0.500396C7.87084 1.01498 8.62248 1.27227 8.81124 1.5412C9 1.81014 9 2.60954 9 4.20833V4.99568C9 7.81472 6.88052 9.18274 5.55072 9.76364C5.18999 9.92121 5.00963 10 4.5 10C3.99037 10 3.81001 9.92121 3.44928 9.76364C2.11948 9.18274 0 7.81472 0 4.99568V4.20833ZM4.5 2.625C4.70711 2.625 4.875 2.79289 4.875 3V5C4.875 5.20711 4.70711 5.375 4.5 5.375C4.29289 5.375 4.125 5.20711 4.125 5V3C4.125 2.79289 4.29289 2.625 4.5 2.625ZM4.5 7C4.77614 7 5 6.77614 5 6.5C5 6.22386 4.77614 6 4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7Z" fill="#F44236"/>
              </svg>
              Укажите дату
            </div>
            <div class="date-picker-input-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2C13.3333 3.10457 12.4379 4 11.3333 4C10.2288 4 9.33333 3.10457 9.33333 2C9.33333 0.89543 10.2288 0 11.3333 0C12.4379 0 13.3333 0.89543 13.3333 2Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 8C2.83333 7.72386 3.05719 7.5 3.33333 7.5H9.33333C9.60948 7.5 9.83333 7.72386 9.83333 8C9.83333 8.27614 9.60948 8.5 9.33333 8.5H3.33333C3.05719 8.5 2.83333 8.27614 2.83333 8Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 12.357C1.95262 13.3333 3.52397 13.3333 6.66667 13.3333C9.80936 13.3333 11.3807 13.3333 12.357 12.357C13.3333 11.3807 13.3333 9.80936 13.3333 6.66667C13.3333 5.73416 13.3333 4.94 13.3078 4.25868C12.7802 4.72028 12.0894 5 11.3333 5C9.67648 5 8.33333 3.65685 8.33333 2C8.33333 1.24389 8.61305 0.553114 9.07466 0.0255059C8.39334 3.72529e-08 7.59918 0 6.66667 0C3.52397 0 1.95262 0 0.976311 0.976311C0 1.95262 0 3.52397 0 6.66667C0 9.80936 0 11.3807 0.976311 12.357ZM2.83333 10.3333C2.83333 10.0572 3.05719 9.83333 3.33333 9.83333H7.33333C7.60948 9.83333 7.83333 10.0572 7.83333 10.3333C7.83333 10.6095 7.60948 10.8333 7.33333 10.8333H3.33333C3.05719 10.8333 2.83333 10.6095 2.83333 10.3333Z" fill="#1E272F"/>
              </svg>
            </div>
            <div :class="['date-picker-input-text', {'date-picker-input-text-active': date[0]}]">
              {{ date[0] ? date[0].toLocaleDateString('ru-RU') : 'Дата*' }}
            </div>
          </div>
        </div>
      </template>
      <template #trigger v-else>
        <div class="date-picker-icon-input">
          <div class="date-picker-icon-input-text">
            {{ date[0] && date[1] ? date[0].toLocaleDateString('ru-RU') + '-' + date[1].toLocaleDateString('ru-RU') : date[0] ? date[0].toLocaleDateString('ru-RU') : 'Выбор по дате'}}
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 3.33325C14.6667 4.43782 13.7713 5.33325 12.6667 5.33325C11.5621 5.33325 10.6667 4.43782 10.6667 3.33325C10.6667 2.22868 11.5621 1.33325 12.6667 1.33325C13.7713 1.33325 14.6667 2.22868 14.6667 3.33325Z" fill="#1E272F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16671 9.33325C4.16671 9.05711 4.39056 8.83325 4.66671 8.83325H10.6667C10.9428 8.83325 11.1667 9.05711 11.1667 9.33325C11.1667 9.60939 10.9428 9.83325 10.6667 9.83325H4.66671C4.39056 9.83325 4.16671 9.60939 4.16671 9.33325Z" fill="#1E272F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.30968 13.6903C3.286 14.6666 4.85734 14.6666 8.00004 14.6666C11.1427 14.6666 12.7141 14.6666 13.6904 13.6903C14.6667 12.714 14.6667 11.1426 14.6667 7.99992C14.6667 7.06741 14.6667 6.27325 14.6412 5.59193C14.1136 6.05353 13.4228 6.33325 12.6667 6.33325C11.0099 6.33325 9.66671 4.99011 9.66671 3.33325C9.66671 2.57714 9.94643 1.88637 10.408 1.35876C9.72671 1.33325 8.93255 1.33325 8.00004 1.33325C4.85734 1.33325 3.286 1.33325 2.30968 2.30956C1.33337 3.28587 1.33337 4.85722 1.33337 7.99992C1.33337 11.1426 1.33337 12.714 2.30968 13.6903ZM4.16671 11.6666C4.16671 11.3904 4.39056 11.1666 4.66671 11.1666H8.66671C8.94285 11.1666 9.16671 11.3904 9.16671 11.6666C9.16671 11.9427 8.94285 12.1666 8.66671 12.1666H4.66671C4.39056 12.1666 4.16671 11.9427 4.16671 11.6666Z" fill="#1E272F"/>
          </svg>
          <div v-if="date[0]" class="date-picker-icon-input-clear" @click="$emit('update:modelValue', [])">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.13754 11.9246C0.843349 11.9417 0.554009 11.8439 0.330533 11.6518C-0.110178 11.2085 -0.110178 10.4925 0.330533 10.0492L9.98066 0.399C10.439 -0.0299203 11.1583 -0.00607686 11.5872 0.452302C11.9751 0.866811 11.9977 1.50392 11.6402 1.94486L1.93317 11.6518C1.7126 11.8412 1.42788 11.9388 1.13754 11.9246Z" fill="#1E272F"/>
              <path d="M10.7763 11.9247C10.4781 11.9234 10.1923 11.805 9.98061 11.595L0.330442 1.94485C-0.0778544 1.46805 -0.0223444 0.750501 0.45445 0.342168C0.880002 -0.0222591 1.5076 -0.0222591 1.93312 0.342168L11.6401 9.99233C12.0984 10.4214 12.1221 11.1407 11.693 11.5989C11.676 11.6172 11.6583 11.6348 11.6401 11.6519C11.5225 11.7541 11.3851 11.8311 11.2365 11.878C11.0879 11.925 10.9313 11.9408 10.7763 11.9247Z" fill="#1E272F"/>
            </svg>
          </div>
        </div>
      </template>
      <template
        #month-year="{
          month,
          year,
          handleMonthYearChange
      }">
        <div class="date-picker-header">
          <div class="date-picker-header-back" @click="handleMonthYearChange(false)">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.72602 8.5L9.66602 7.56L6.61268 4.5L9.66602 1.44L8.72601 0.5L4.72602 4.5L8.72602 8.5Z" fill="#1E272F"/>
              <path d="M4.33344 8.5L5.27344 7.56L2.2201 4.5L5.27344 1.44L4.33344 0.5L0.333437 4.5L4.33344 8.5Z" fill="#1E272F"/>
            </svg>
          </div>
          <div class="date-picker-header-month">
            {{months[month].text + ' ' + year}}
          </div>
          <div class="date-picker-header-next" @click="handleMonthYearChange(true)">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.66656 0.5L4.72656 1.44L7.7799 4.5L4.72656 7.56L5.66656 8.5L9.66656 4.5L5.66656 0.5Z" fill="#1E272F"/>
              <path d="M1.27398 0.5L0.333984 1.44L3.38732 4.5L0.333984 7.56L1.27398 8.5L5.27398 4.5L1.27398 0.5Z" fill="#1E272F"/>
            </svg>
          </div>
        </div>
      </template>
      <template #time-picker>
        <div v-if="selectPeriod" class="date-picker-period date-picker-period-select">
          <div class="date-picker-period-select-text">
            История операций:
          </div>
          <div class="date-picker-period-select-multiselect">
            За месяц
          </div>
        </div>
        <div v-else class="date-picker-period">
          <div class="date-picker-period-text">
            Период
          </div>
          <div class="date-picker-period-container">
            <div class="date-picker-period-item">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2C13.3333 3.10457 12.4379 4 11.3333 4C10.2288 4 9.33333 3.10457 9.33333 2C9.33333 0.89543 10.2288 0 11.3333 0C12.4379 0 13.3333 0.89543 13.3333 2Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 8C2.83333 7.72386 3.05719 7.5 3.33333 7.5H9.33333C9.60948 7.5 9.83333 7.72386 9.83333 8C9.83333 8.27614 9.60948 8.5 9.33333 8.5H3.33333C3.05719 8.5 2.83333 8.27614 2.83333 8Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 12.357C1.95262 13.3333 3.52397 13.3333 6.66667 13.3333C9.80936 13.3333 11.3807 13.3333 12.357 12.357C13.3333 11.3807 13.3333 9.80936 13.3333 6.66667C13.3333 5.73416 13.3333 4.94 13.3078 4.25868C12.7802 4.72028 12.0894 5 11.3333 5C9.67648 5 8.33333 3.65685 8.33333 2C8.33333 1.24389 8.61305 0.553114 9.07466 0.0255059C8.39334 3.72529e-08 7.59918 0 6.66667 0C3.52397 0 1.95262 0 0.976311 0.976311C0 1.95262 0 3.52397 0 6.66667C0 9.80936 0 11.3807 0.976311 12.357ZM2.83333 10.3333C2.83333 10.0572 3.05719 9.83333 3.33333 9.83333H7.33333C7.60948 9.83333 7.83333 10.0572 7.83333 10.3333C7.83333 10.6095 7.60948 10.8333 7.33333 10.8333H3.33333C3.05719 10.8333 2.83333 10.6095 2.83333 10.3333Z" fill="#1E272F"/>
              </svg>
              <div class="date-picker-period-item-date">
                {{ date[0] && date[1] ? date[0].toLocaleDateString('ru-RU') : ''}}
              </div>
            </div>
            <div class="date-picker-period-item">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2C13.3333 3.10457 12.4379 4 11.3333 4C10.2288 4 9.33333 3.10457 9.33333 2C9.33333 0.89543 10.2288 0 11.3333 0C12.4379 0 13.3333 0.89543 13.3333 2Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 8C2.83333 7.72386 3.05719 7.5 3.33333 7.5H9.33333C9.60948 7.5 9.83333 7.72386 9.83333 8C9.83333 8.27614 9.60948 8.5 9.33333 8.5H3.33333C3.05719 8.5 2.83333 8.27614 2.83333 8Z" fill="#1E272F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 12.357C1.95262 13.3333 3.52397 13.3333 6.66667 13.3333C9.80936 13.3333 11.3807 13.3333 12.357 12.357C13.3333 11.3807 13.3333 9.80936 13.3333 6.66667C13.3333 5.73416 13.3333 4.94 13.3078 4.25868C12.7802 4.72028 12.0894 5 11.3333 5C9.67648 5 8.33333 3.65685 8.33333 2C8.33333 1.24389 8.61305 0.553114 9.07466 0.0255059C8.39334 3.72529e-08 7.59918 0 6.66667 0C3.52397 0 1.95262 0 0.976311 0.976311C0 1.95262 0 3.52397 0 6.66667C0 9.80936 0 11.3807 0.976311 12.357ZM2.83333 10.3333C2.83333 10.0572 3.05719 9.83333 3.33333 9.83333H7.33333C7.60948 9.83333 7.83333 10.0572 7.83333 10.3333C7.83333 10.6095 7.60948 10.8333 7.33333 10.8333H3.33333C3.05719 10.8333 2.83333 10.6095 2.83333 10.3333Z" fill="#1E272F"/>
              </svg>
              <div class="date-picker-period-item-date">
                {{ date[0] && date[1] ? date[1].toLocaleDateString('ru-RU') : ''}}
              </div>
            </div>
          </div>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import {ref} from "vue";
export default {
  name: "DatePicker",
  components: {
    VueDatePicker
  },
  props: {
    invalid: {
      type: Boolean,
      default: () => false
    },
    modelValue: {
      type: undefined,
      default: () => ''
    },
    icon: {
      type: Boolean,
      default: () => false
    },
    position: {
      type: Object,
      default: () => {
        return {
          top: 0,
          right: 0,
          transform: "translate(75%,-10%)"
        }
      }
    },
    selectPeriod: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props) {
    const datePickerPos = () => {
      return {
        top: props.position.top || 0,
        right: props.position.right || 0,
        transform: props.position.transform || "translate(75%,-10%)"
      }
    }
    return {
      datePickerPos
    }
  },
  data () {
    return {
      months: [
        {
          text: 'January',
          value: 0
        },
        {
          text: 'February',
          value: 1
        },
        {
          text: 'March',
          value: 2
        },
        {
          text: 'April',
          value: 3
        },
        {
          text: 'May',
          value: 4
        },
        {
          text: 'June',
          value: 5
        },
        {
          text: 'July',
          value: 6
        },
        {
          text: 'August',
          value: 7
        },
        {
          text: 'September',
          value: 8
        },
        {
          text: 'October',
          value: 9
        },
        {
          text: 'November',
          value: 10
        },
        {
          text: 'December',
          value: 11
        }
      ],
      isRange: true,
      rangeStart: "",
      rangeEnd: ""
    }
  },
  computed: {
    date: {
      get(){
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onRangeStart(value){
      if(this.rangeEnd){
        this.rangeEnd = ''
      }
      if(new Date(this.rangeStart) === new Date(this.rangeEnd)){
        this.rangeStart = [this.rangeStart, null]
        return
      }
      this.rangeStart = new Date(value)
    },
    onRangeEnd(value){
      if(new Date(this.rangeStart).getTime() === new Date(value).getTime()){
        this.date = [this.rangeStart, null]
        return
      }
      this.rangeEnd = new Date(value)
    },
    onClosed(){
      if (this.rangeStart && this.rangeEnd.length === 0){
        this.date = [this.rangeStart, null]
      }
    }
  }
}
</script>

<style lang="scss">
.date-picker{
  &-input{
    display: flex;
    align-items: center;
    gap: 9px;
    border: 3px solid #D9D9D9;
    border-radius: 80px;
    width: 195px;
    padding: 23px 20px 21px;
    cursor: pointer;
    position: relative;
    &-second{
      margin-top: 11px;
    }
    &-icon {
      height: 14px;
    }
    &-text{
      font-size: 14px;
      color: #999DA1;
      &-active{
        color: #1e272f;
      }
    }
    &-danger{
      width: 86.4%;
      background: #fff;
      height: 100%;
      border: 1px solid #F44236;
      border-radius: 81px;
      left: -1px;
      top: -1px;
      padding-left: 25px;
      display: flex;
      align-items: center;
      position: absolute;
      color: #F44236;
      font-size: 14px;
      font-weight: 300;
      gap:3px;
    }
  }
  &-header{
    display: flex;
    align-items: center;
    gap: 44px;
    margin: 0 auto;
    &-month{
      font-size: 14px;
      min-width: 100px;
      font-weight: 500;
      text-align: center;
    }
    &-back,&-next{
      cursor: pointer;
    }
  }
  &-period {
    width: 261px;
    height: 124px;
    position: absolute;
    bottom: -130px;
    background: #fff;
    border-radius: 21px;
    padding: 30px 28px;
    &-text{
      font-size: 12px;
    }
    &-container{
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 15px;
    }
    &-item{
      background: #F2F2F2;
      color: #1e272f;
      width: 101px;
      height: 36px;
      border-radius: 9px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      gap: 5px;
      font-size: 12px;
      line-height: 14px;
    }
    &-select{
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 28px;
      height: 68px;
      bottom: -75px;
      &-text{
        color: #999DA1;
      }
    }
  }
  &-date-time{
    display: flex;;
    align-items: center;
  }
  &-icon-input{
    display: flex;
    gap: 10px;
    cursor: pointer;
    &-clear{
      cursor: pointer;
    }
  }
}
.dp{
  &__arrow_top{
    display: none!important;
  }
  &__menu{
    border: none!important;
    border-radius: 21px;
    box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.25);
  }
  &__active_date{
    border-radius: 8px;
  }
  &__calendar_header_separator{
    display: none;
  }
}
</style>
