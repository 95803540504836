<template>
  <div class="create-task-step5">
    <div class="create-task-step5-container">
      <div class="create-task-container-title">
        Что ещё важно знать исполнителю?
      </div>
      <div class="create-task-container-description">
        Добавьте подробности к вашему заданию
      </div>
      <UiFormTextArea placeholder="Например: нужна покраска стен в офисе
после предыдущего арендатора"/>
      <UiFormUpload/>
    </div>
    <TipsComponent/>
  </div>
</template>
<script>
import TipsComponent from "@/components/Tasks/TipsComponent.vue";
import UiFormTextArea from "@/components/Ui/Form/UiFormTextArea.vue";
import UiFormUpload from "@/components/Ui/Form/UiFormUpload.vue";

export default {
  name: 'CreateTaskStep5',
  components: {UiFormUpload, UiFormTextArea, TipsComponent}
}
</script>
<style lang="scss">
.create-task-step5{
  display: flex;
  padding-bottom: 230px;
  &-container{
    width: 750px;
  }
  .tips-component{
    width: 360px;
  }
}
.ui-form-text-area{
  margin-top: 31px;
}
.ui-form-upload{
  margin-top: 9px;
}
</style>
