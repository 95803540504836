<template>
  <div class="profile-billing">
    <div class="profile-billing-balance">
      <div>
        Текущий счет:
      </div>
      <BalanceComponent class="profile-billing-balance-text"/>
       <div class="profile-billing-balance-actions">
         <div class="profile-billing-balance-actions-text">
           <div>
             Пополнить счет:
           </div>
           <div class="profile-billing-balance-subtext">
             от CZK 500,00
           </div>
         </div>
         <div class="btn-orange" @click="openModal">
           Пополнить
         </div>
       </div>
      <div class="profile-billing-withdraw" @click="openWithdraw">
        Оставить заявку на вывод
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9L9 1M9 1V8.68M9 1H1.32" stroke="#287EFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <TabsComponent class="profile-billing-transactions" :tabs="tabs" @setTab="setTab" :date-filter="true" title="История операций:">
      <TransactionComponent v-for="t in transactions" :key="t.id" :date="t.date" :type="t.type" :user="t.user" :amount="t.amount" />
    </TabsComponent>
    <FAQComponent class="profile-billing-faq"/>
  </div>
</template>
<script>
import BalanceComponent from "@/components/Balance.vue";
import TabsComponent from "@/components/Profile/ProfileBilling/TabsComponent.vue";
import FAQComponent from "@/components/Profile/ProfileBilling/FAQ.vue";
import TransactionComponent from "@/components/Profile/ProfileBilling/Transaction.vue";
import Transactions from "./Transactions.json";
export default {
  name: "ProfileBilling",
  components: {
    TransactionComponent,
    BalanceComponent, TabsComponent,
    FAQComponent
  },
  data(){
    return{
      isModal: false,
      transactions: Transactions,
      tabs: [{id: 1, name: 'Все операции'}, {id: 2, name: 'Пополнение'}, {id: 3, name: 'Списание'}]
    }
  },
  methods: {
    openModal(){
      this.$store.dispatch("setModal",{title: 'На какую сумму хотите пополнить <br> ваш счет', modalName: 'ModalDeposit'})
    },
    openWithdraw(){
      this.$store.dispatch("setModal",{title: 'Заявка на вывод средств', modalName: 'ModalWithdraw'})
    },
    setTab(value){
      switch (value){
        case 1:
          this.transactions = Transactions;
          break;
        case 2:
          this.transactions = Transactions.filter(el => el.type === 'Plus' || el.type === 'Refund');
          break;
        case 3:
          this.transactions = Transactions.filter(el => el.type === 'Minus')
          break;
      }
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileBilling.scss";
</style>
