<template>
  <div class="task-content">
    <div class="task-content-title">
      <div class="task-content-title-text">Мне нужно срочно убрать квартиру к приходу гостей</div>
      <div class="task-content-title-cost">
        CZK200
      </div>
    </div>
    <div class="task-content-description">
      Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.
      При создании генератора мы использовали небезизвестный универсальный код речей.
      Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия.
    </div>
    <div class="task-content-info">
      <div class="task-content-info-item">
        <div class="task-content-info-item-text">
          Категория задачи:
        </div>
        <div class="task-content-info-item-value">
          Уборка помещений
        </div>
      </div>
      <div class="task-content-info-item">
        <div class="task-content-info-item-text">
          Подкатегория:
        </div>
        <div class="task-content-info-item-value">
          Уборка ванных комнат
        </div>
      </div>
      <div class="task-content-info-item">
        <div class="task-content-info-item-text">
          Приступить:
        </div>
        <div class="task-content-info-item-value">
          16.10.2023  в 13:38
        </div>
      </div>
      <div class="task-content-info-item">
        <div class="task-content-info-item-text">
          Адрес:
        </div>
        <div class="task-content-info-item-value">
          Ул. Город. Дом. Дробь/2
          <div class="task-content-info-item-link" @click="openMap">
            Показать на карте
          </div>
        </div>
      </div>
      <div class="task-content-info-item">
        <div class="task-content-info-item-text">
          Желаемый бюджет:
        </div>
        <div class="task-content-info-item-value task-content-info-item-value-cost">
          CZK200
        </div>
      </div>
    </div>
    <UiGallery
      :items="[
        'gallery-img.jpeg',
        'gallery-img.jpeg',
        'gallery-img.jpeg'
      ]"
    />
    <div v-if="!isExecutor && isOwner">
      <div
        v-if="status === 5"
        class="task-content-actions" @click="$emit('update:modelValue', 2)">
        <div class="btn-orange">
          Опубликовать снова
        </div>
      </div>
      <div v-else-if="status === 4" class="task-content-actions">
        <div class="btn-orange" @click="openDoneFixing(true)">
          Задание выполнено
        </div>
        <div class="btn btn-secondary" @click="openDoneFixing(false)">
          Задание не выполнено
        </div>
      </div>
      <div v-else class="task-content-actions">
        <div class="btn-orange">
          Изменить детали задания
        </div>
        <div class="btn btn-secondary" @click="cancelTask">
          Отменить задание
        </div>
      </div>
    </div>
    <div v-else>
      <div class="task-content-actions">
        <div class="btn-orange" @click="openModal">
          Откликнуться на задание
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UiGallery from "@/components/Ui/UiGallery.vue";

export default {
  name: 'TaskContent',
  components: {UiGallery},
  props: {
    status: {
      type: Number,
      default: () => 0
    },
    isExecutor: {
      type: Boolean,
      default: () => false
    },
    isOwner: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    openMap(){
      this.$store.dispatch('setModal', { modalName: 'ModalMap'})
    },
    openDoneFixing(value){
      if (value) {
        this.$store.dispatch('setModal', {
          modalName: "ModalTaskDoneFixing",
          props: {
            done: true,
            isExecutor: this.isExecutor
          },
          customClose: true
        })
        return
      }
      this.$store.dispatch('setModal', {
        modalName: "ModalTaskDoneFixing",
        props: {
          done: false,
          isExecutor: this.isExecutor
        },
        customClose: true
      })
    },
    cancelTask(){
      this.$store.dispatch('setModal',
        {
          modalName: 'ModalTaskCancel',
          greyHeader: true,
          title: 'Вы действительно хотите отменить задание ?',
          callBack: () => {
            this.$emit('update:modelValue', 5)
            this.$store.dispatch('setIsOpen', false)
          }}
      )
    },
    openModal(){
      if (!this.isOwner && !this.isExecutor) {
        this.$store.dispatch('setModal', {
          modalName: "ModalOnlyExecutors",
          customClose: true
        })
        return
      }
      this.$store.dispatch('setModal', {
        modalName: "ModalRespondTask",
        customClose: true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.task-content{
  &-title{
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    &-cost{
      color: #287EFF;
      margin-left: 24px;
    }
  }
  &-description{
    margin-top: 14px;
    font-size: 18px;
    font-weight: 300;
  }
  &-info{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-left: -113px;
    width: 900px;
    &-item{
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 30px;
      margin-left: 113px;
      min-width: 286px;
      &-value{
        display: flex;
        align-items: center;
        gap: 10px;
        &-cost{
          color: #287EFF;
        }
      }
      &-link{
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #FF6B17;
        font-weight: 300;
        text-decoration: underline;
        &:hover{
          opacity: .75;
        }

      }
      &-text{
        color: #999DA1;
        font-weight: 300;
      }
    }
  }
  .ui-gallery{
    margin-top: 35px;
  }
  &-actions{
    margin-top: 75px;
    display: flex;
    align-items: center;
    gap: 19px;
    .btn-orange{
      width: 380px;
      height: 98px;
      font-size: 22px;
    }
    .btn-secondary{
      width: 337px;
      height: 98px;
      font-size: 22px;
      color: #1e272f;
      &:hover{
        color: #fff;
      }
    }
  }
}
</style>
