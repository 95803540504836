<template>
  <div class="profile-transactions">
    <h2 v-if="title">{{ title }}</h2>
    <div class="profile-transactions-container">
       <div class="profile-transactions-header">
         <div
           v-for="tab in tabs"
           :key="tab.id"
           :class="['profile-transactions-header-link', {
             'profile-transactions-header-link-active': current === tab.id
           }]"
           @click="setTab(tab.id)">
           {{ tab.name }}
         </div>
         <div class="profile-transactions-sort" v-if="dateFilter">
           <DatePicker
             :model-value="date"
             @update:modelValue="setDate"
             icon
             select-period
             :position="{
               top: 0,
               right: 0,
               transform: 'translate(0,0)'
             }"
           />
         </div>
       </div>
      <div class="profile-transactions-content">
        <slot/>
      </div>
      <PaginationComponent v-if="!noPagination"/>
    </div>
  </div>
</template>
<style lang="scss">
@import "./ProfileTransactions.scss";
</style>
<script>
import '@vuepic/vue-datepicker/dist/main.css'
import PaginationComponent from "@/components/PaginationComponent.vue";
import DatePicker from "@/components/DatePicker.vue";
export default {
  name: "TabsComponent",
  components: {DatePicker, PaginationComponent},
  props: {
    title: {
      type: String,
      default: () => ""
    },
    dateFilter: {
      type: Boolean,
      default: () => false
    },
    tabs: {
      type: Array,
      default: () => []
    },
    noPagination: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return{
      date: [],
      current: 1,
    }
  },
  methods: {
    setTab(value){
      this.current = value
      this.$emit('setTab', value)
    },
    setDate(value) {
      this.date = value
    }
  }
}
</script>
