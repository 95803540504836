<template>
  <div id="app">
    <component :is="layout" :class="{'is-modal': modal.isOpen}">
      <router-view/>
    </component>
    <ModalIndex v-if="modal.isOpen && modal.data.modalName !== 'Chat'"/>
    <ChatComponent v-if="modal.isOpen && modal.data.modalName === 'Chat'"/>
    <transition>
      <NotificationComponent v-if="notificationState"/>
    </transition>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DeafultLayout.vue';
import ModalIndex from "@/components/Modals/Modal.vue";
import NotificationComponent from "@/components/Notifications/Notification.vue";
import ChatComponent from "@/components/Chat/ChatComponent.vue";
export default {
  name: 'App',
  computed: {
    layout(){
      return this.$route.meta.layout || 'default-layout'
    },
    modal() {
      return { isOpen: this.$store.getters.getModalState, data: this.$store.getters.getModal}
    },
    notificationState() {
      return this.$store.getters.getNotificationState
    }
  },
  created() {
    console.log(this.modal);
    setTimeout(() => {
      this.$store.dispatch('setNotification', { name: 'cookies-notification' })
    }, 1500)
  },
  components: {
    ChatComponent,
    NotificationComponent,
    ModalIndex,
    DefaultLayout
  },

}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
.is-modal{
  max-height: 100vh;
  overflow: hidden;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s linear;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
