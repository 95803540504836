<template>
  <div class="task-footer">
      <div class="task-footer-title">
        Отклики на ваше задание:
      </div>
      <div class="task-footer-container">
        <ResponseCard/>
        <ResponseCard/>
        <ResponseCard/>
        <ResponseCard/>
        <ResponseCard/>
        <ResponseCard/>
      </div>
  </div>
</template>
<script>
import ResponseCard from "@/components/Tasks/ResponseCard.vue";

export default {
  name: 'TaskFooter',
  components: {ResponseCard}
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.task-footer{
  &-title{
    font-size: 18px;
  }
  &-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-left: -40px;
    .response-card{
      margin-left: 40px;
      margin-top: 14px;
      box-sizing: border-box;
      max-width: 559px;
      border-radius: $border-radius-medium;
    }
  }
}
</style>
