<template>
  <div class="ui-google-pay">
    <img src="@/assets/img/googlepay.png"/>
  </div>
</template>
<style lang="scss" scoped>
.ui-google-pay{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 44px;
  border: 1px solid rgba(0, 0, 0, .12);
  border-radius: 21px;
  cursor: pointer;
}
</style>
