<template>
  <div class="response-card">
     <div class="response-card-header">
       <UiUserWithStars/>
       <div class="response-card-header-time">
         Время отклика: 15:27
       </div>
     </div>
    <div class="response-card-content">
      Добрый день я помогу вам убраться к приходу гостей!
      Обсудим детали работы? У меня есть парава вопросов по уборке.
    </div>
    <div class="response-card-footer">
      <div class="response-card-footer-actions">
        <div class="btn-orange">
          Выбрать
        </div>
        <div class="btn-primary" @click="openChat">
          Написать
        </div>
      </div>
      <div class="response-card-footer-cost">
        <div class="response-card-footer-cost-title">
          Предложенная цена:
        </div>
        <div class="response-card-footer-cost-value">
          CZK2000
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UiUserWithStars from "@/components/Ui/UiUserWithStars.vue";

export default {
  name: 'ResponseCard',
  components: {UiUserWithStars},
  methods: {
    openChat () {
      this.$store.dispatch('setModal', { modalName: 'Chat'})
    }
  }
}
</script>
<style lang="scss" scoped>
.response-card{
  border: 1px solid rgba(30, 39, 47, 0.17);
  width: 100%;
  padding: 22px 37px 22px 22px;
  &-header{
    display: flex;
    align-items: center;
    &-time{
      margin-left: auto;
      font-size: 12px;
      color: #999DA1;
    }
  }
  &-content{
    margin-top: 16px;
  }
  &-footer{
    margin-top: 25px;
    display: flex;
    align-items: center;
    &-actions{
      display: flex;
      align-items: center;
      gap: 10px;
      .btn-orange{
        box-sizing: border-box;
        width: 97px;
        height: 34px;
        font-size: 12px;
      }
      .btn-primary{
        box-sizing: border-box;
        width: 91px;
        height: 34px;
        font-size: 12px;
      }
    }
    &-cost{
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      &-title{
        color: #999DA1;
      }
      &-value{
        color: #287EFF;
      }
    }
  }
}
</style>
