<template>
  <div class="referral-card">
    <AvatarComponent :is-star="true" :star-color="lvlColors[lvl - 1]" star-position="top"/>
    <div class="referral-card-info">
      <div class="referral-card-name">
        {{ name }}
      </div>
      <div :class="['referral-card-lvl', `referral-card-lvl-${lvl}`]">
        Уровень: {{ lvl }}
      </div>
      <div class="referral-card-tasks">
        Завершенные задачи: {{ tasks }}
      </div>
      <div class="referral-card-points">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0.677002C0.792893 0.677002 0.625 0.858886 0.625 1.08325C0.625 1.30762 0.792893 1.4895 1 1.4895H2V6.65017C2 7.37605 2 7.73899 2.13405 8.05902C2.2681 8.37906 2.52023 8.61806 3.02449 9.09605L4.02449 10.044C4.96576 10.9362 5.4364 11.3823 6 11.3823C6.5636 11.3823 7.03424 10.9362 7.97551 10.044L8.97551 9.09605C9.47977 8.61805 9.7319 8.37906 9.86595 8.05902C10 7.73899 10 7.37605 10 6.65017V1.4895H11C11.2071 1.4895 11.375 1.30762 11.375 1.08325C11.375 0.858886 11.2071 0.677002 11 0.677002H1ZM4.25 6.63534C4.04289 6.63534 3.875 6.81722 3.875 7.04159C3.875 7.26595 4.04289 7.44784 4.25 7.44784H7.75C7.95711 7.44784 8.125 7.26595 8.125 7.04159C8.125 6.81722 7.95711 6.63534 7.75 6.63534H4.25ZM3.875 4.33325C3.875 4.10889 4.04289 3.927 4.25 3.927H7.75C7.95711 3.927 8.125 4.10889 8.125 4.33325C8.125 4.55762 7.95711 4.7395 7.75 4.7395H4.25C4.04289 4.7395 3.875 4.55762 3.875 4.33325Z" fill="#1E272F"/>
        </svg>
        Баллы: {{ points }}
      </div>
    </div>
  </div>
</template>
<script>
import AvatarComponent from "@/components/Avatar.vue";
export default {
  name: 'ReferralCard',
  props: {
    name: {
      type: String,
      default: () => ''
    },
    lvl: {
      type: Number,
      default: () => 1
    },
    tasks: {
      type: Number,
      default: () => 0
    },
    points: {
      type: Number,
      default: () => 0
    }
  },
  components: {
    AvatarComponent
  },
  data () {
    return {
      lvlColors: [
        'orange',
        'blue'
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.referral-card{
  @import "@/assets/styles/variables";
  border: 1px solid #ECEDED;
  border-radius: $border-radius-medium;
  padding: 8px 8px 18px;
  display: flex;
  &-info{
    margin-left: 10px;
  }
  &-name{
    font-size: 18px;
  }
  &-lvl{
    margin-top: 3px;
    &-1{
      color: #FF6B17;
    }
    &-2{
      color: #287EFF;
    }
  }
  &-tasks{
    margin-top: 12px;
    color: #999DA1;
    width: 104px;
  }
  &-points{
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
</style>
