<template>
  <div class="profile-history">
    <div class="profile-history-switcher" v-if="!isExecutor">
      <UiSwitcherType @update:modelValue="setType"/>
    </div>
<!--    <div v-if="type === 0">-->
<!--      <div class="profile-history-title">-->
<!--        Ваши заказы-->
<!--      </div>-->
<!--      <div class="profile-history-container">-->
<!--        <div class="profile-history-container-cards">-->
<!--          <OrderCard class="profile-history-container-cards-card" v-for="order in orders" :key="order.id" :name="order.name" :category="order.category" :order-status="order.status"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="type === 0">
      <div class="profile-history-title" style="margin-top: 0;">
        {{ isExecutor ? 'Ваши заказы' : 'Задачи которые вы ставили' }}
      </div>
      <TabsComponent :tabs="tabs" @setTab="setTab">
        <div class="profile-history-container-cards" :key="orders">
          <OrderCard class="profile-history-container-cards-card" v-for="order in orders" :key="order.id" :customer="order.name" :category="order.category" :order-status="order.status"/>
        </div>
      </TabsComponent>
    </div>
    <div v-else class="profile-history-executor-banner">
      <ExecutorBanner/>
    </div>
  </div>
</template>
<script>
import UiSwitcherType from "@/components/Ui/UiSwitcherType.vue";
import OrderCard from "@/components/Profile/ProfileHistory/OrderCard.vue";
import TabsComponent from "@/components/Profile/ProfileBilling/TabsComponent.vue";
import Orders from "./Orders.json"
import ExecutorBanner from "@/components/ExecutorBanner.vue";
export default {
  name: "ProfileHistory",
  components: {
    ExecutorBanner,
    OrderCard,
    UiSwitcherType,
    TabsComponent
  },
  data () {
    return {
      orders: Orders,
      tabs: [
        {
          "id": 1,
          "name": "Выполненные"
        },
        {
          "id": 2,
          "name": "Созданные"
        },
        {
          "id": 3,
          "name": "Отмененные"
        },
        {
          "id": 4,
          "name": "Оспоренные"
        },
        {
          "id": 5,
          "name": "Все"
        },
      ],
      isExecutor: false,
      type: 0
    }
  },
  methods: {
    setType(value){
      this.type = value
    },
    setTab(value){
      if (value === 5){
        this.orders = Orders
        return
      }
      this.orders = Orders.filter(el => el.status === value)
    }
  },
  beforeMount () {
    const isExecutor = localStorage.getItem('isExecutor')
    if (isExecutor) {
      this.isExecutor = isExecutor
    }
  },
  mounted () {
    this.setTab(1)
  },
}
</script>
<style lang="scss">
@import "./ProfileHistory.scss";
</style>
