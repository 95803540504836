<template>
  <div class="public-offer">
    <PublicOfferContent/>
  </div>
</template>
<script>
import PublicOfferContent from "@/components/PublicOfferContent.vue";

export default {
  name: "PublicOffer",
  components: {PublicOfferContent}
}
</script>
<style lang="scss">
.public-offer{
  .container-banners{
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
