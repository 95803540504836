<template>
  <div class="faq-container container">
    <TabsComponent :tabs="tabs" :no-pagination="true">
      <div class="faq-container-tab">
        <div class="profile-referral-lvl profile-referral-lvl-first">
          <div class="profile-referral-lvl-info">
            <div class="profile-referral-lvl-info-avatars">
              <MiniLogo/>
            </div>
            <div class="profile-referral-lvl-info-text">
              <div style="margin-top: 10px;">
                <strong>GetJob</strong> – это онлайн сервис, позволяющий быстро находить
                и нанимать специалистов для решения большинства бытовых проблем.
              </div>
              <div style="margin-bottom: 10px;">
                <br/><strong >Для клиентов услуги сервиса GetJob абсолютно бесплатны.</strong>
              </div>
            </div>

          </div>
          <div class="profile-referral-lvl-bubble profile-referral-lvl-bubble-orange"></div>
        </div>
      </div>
      <FaqItem
        v-for="(item, idx) in faqItems"
        :key="idx"
        :title="item.title"
        :description="item.description"
        :items="item.items"
      />
    </TabsComponent>
  </div>
</template>
<script>
import TabsComponent from "@/components/Profile/ProfileBilling/TabsComponent.vue";
import MiniLogo from "@/components/icons/MiniLogo.vue";
import FaqItem from "@/components/Faq/FaqItem.vue";
export default {
  name: "FaqContainer",
  components: {
    FaqItem,
    MiniLogo,
    TabsComponent
  },
  data () {
    return {
      tabs: [
        {
          id: 1,
          name: "О сервисе"
        },
        {
          id: 2,
          name: "Как пользоваться сервисом"
        },
        {
          id: 3,
          name: "Оплата"
        },
        {
          id: 4,
          name: "Безопасность"
        }
      ],
      faqItems: [
        {
          title: "Что такое GetJob и как им пользоваться?",
          description: "GetJob – веб сервис, который позволяет нанимать специалистов для решения любых бытовых задач. <br> Услуги сервиса для клиентов являются бесплатными.",
          items: [
            {
              title: "Как пользоваться сервисом GetJob\n",
              description: "GetJob могут использовать и заказчики, и исполнители. Регистрация на ресурсе бесплатна. Если вы хотите стать исполнителем, получать заказы и зарабатывать, вам потребуется пройти дополнительную процедуру для получения статуса «Мастер»."
            },
            {
              title: "Как работает getjob.cz для клиентов?",
              description: "Потребность в онлайн сервисах возрастает с каждым днем. Все большее количество клиентов и заказчиков предпочитают услуги частных исполнителей предложениям специализированных компаний. Поиск и вызов специалиста с помощью нашего сервиса занимает не более 10 минут."
            },
            {
              title: "Удобно и быстро",
              description: "Вы можете выбрать специалиста по каталогу и связаться с ним в удобное для вас время или создать заявку, чтобы быстро получить отклики от специализирующихся на выполнении заданий указанного профиля исполнителей. С сервисом GetJob вы сможете найти специалиста за несколько минут."
            },
            {
              title: "Безопасно и качественно",
              description: "Каждый исполнитель проходит предварительную проверку, поэтому клиенты защищены от общения с мошенниками и иными сомнительными личностями. \n" +
                "Отзывы других клиентов сервиса, размещенные в анкетах специалистов, помогут вам определиться с правильным выбором."
            },
            {
              title: "Выгодно и экономно",
              description: "Вы можете установить свою цену при создании заявки, в этом случае вы получите отклики исполнителей, которых устраивает указанная стоимость, или  попросить специалистов сориентировать вас по стоимости их услуг, в этом случае вы сможете проанализировать разные предложения и выбрать наиболее выгодный для вас вариант."
            }
          ]
        },
        {
          title: "Как зарегистрироваться на GetJob?",
          description: "Процедура регистрации на портале максимальна проста и удобна. Следуя подсказкам системы, вы сможете стать зарегистрированным пользователем за 5 минут.",
          items: [
            {description: "Чтобы зарегистрировать свой аккаунт на GetJob.cz, нажмите «Регистрация» в правом верхнем углу главной страницы сайта."},
            {description: "Для регистрации вам потребуется указать действующий адрес электронной почты. Введите его в соответствующее поле и нажмите «Зарегистрироваться»."},
            {description: "Проверьте свой почтовый ящик. Система отправит вам письмо для подтверждения электронного адреса. Перейдите по ссылке в письме и завершите процедуру регистрации."},
            {description: "Войдите в свой аккаунт, выберите подходящую вашему заданию категорию, опишите его в специальной графе и разместите на сайте. Буквально через несколько минут вам начнут поступать предложения от специалистов, заинтересованных в получении вашего заказа."}
          ]
        },
        {
          title: "Как разместить задание?",
          description: "Чем быстрее вы разместите свой заказ, тем скорее он будет выполнен. Мы постараемся помочь вам со всеми возникающими вопросами",
          items: [
            { description: "Для создания задания просто зайдите на GetJob, используя свои логин и пароль, или зарегистрируйтесь на сайте."},
            { description: "Если вы увидели сообщение : «пользователь с таким почтовым адресом/телефоном уже есть», значит, вы уже проходили регистрацию и ваш профиль создан."},
            { description: "Вспомните данные, которые вы использовали для входа, или свяжитесь с сотрудниками службы поддержки  — модераторы портала помогут вам восстановить доступ к своему кабинету, чтобы продолжить работу."},
            { description: "Чтобы найти специалиста, просто создайте задание. Напишите в строке главной страницы сайта getjob.cz, что вы хотите заказать. "},
            { description: "Например:  «Собрать мебель»."},
            { description: "Опишите предстоящее задание своими словами. Обратите внимание на детали и пожелания, чтобы исполнитель мог точнее оценить объем работ, сориентируйте по стоимости, в этом случае вам не придется торговаться."},
            { description: "Нажмите «Опубликовать»."},
            { description: "После этого вы начнете получать предложения от исполнителей, в которых будет содержаться информации по срокам, расценкам и контактной информации специалистов. Внимательно изучите поступившие заявки, просмотрите профили исполнителей, почитайте отзывы других клиентов, созвонитесь с ними для уточнения всех деталей и нюансов, а также точной стоимости услуг."},
            { description: "Договорившись о сотрудничестве, укажите на сервисе выбранного исполнителя. После выполнения задания закройте его и по возможности оставьте отзыв, у вас это займет несколько минут, но очень поможет другим клиентам сервиса в будущем."},
            { description: "Если ни одно предложение исполнителей вам не подойдет, вы можете закрыть задание. Перейдите в раздел «Мои заказы», выберите нужное задание и нажмите «Редактировать»."},
            { description: "Чем точнее и подробнее будут описаны детали предстоящей работы, тем меньше времени вам понадобится на выбор исполнителя. Если вы не готовы сразу определиться по точным цифрам оплаты работы, укажите ориентировочную стоимость. Окончательная цена в любом случае обсуждается непосредственно с выбранными кандидатами по телефону или в чате до начала выполнения задания."},
          ]
        },
        {
          title: "Как стать исполнителем?",
          description: "Вы готовы предложить свою помощь по хозяйству, реализовать профессиональные навыки и неплохо заработать? Зарегистрируйтесь как «Исполнитель» на портале и начните получать доход за выполнение заданий уже сегодня <br>GetJob приглашает к сотрудничеству в качестве специалиста всех желающих, достигших возраста 18 лет.",
          items: [
            {
              title: "1. Получение статуса исполнителя.",
              description: "Пройдите в раздел Профиль и выберите кнопку «Стать исполнителем»."
            },
            {
              title: "2. Внимательно изучите правила.",
              description: "Ознакомьтесь с правилами работы на GetJob и основными правилами безопасности во время работы с заказчиками в онлайн пространстве."
            },
            {
              title: "3. Впишите данные в предложенную анкету.",
              description: "Сообщайте только реальные данные о себе. Они необходимы, чтобы сотрудники GetJob и ваши заказчики могли связаться с вами.<br>" +
                "·  Фамилию и имя, <br>" +
                "·  дату рождения,<br>" +
                "·  пол (муж/жен),<br>" +
                "·  номер телефона для связи,<br>" +
                "·  e-mail.<br>"
            },
            {
              title: "4. Заполните свой профиль.",
              description: "Помните, он станет вашей визитной карточкой. Опишите свой профессиональный опыт, познакомьте заказчиков со своими достижениями, укажите преимущества сотрудничества с вами. По необходимости выставьте фотографии выполненных работ (портфолио). При отсутствии отзывов правильно оформленный профиль поможет получить первые поручения."
            },
            {
              title: "5. Ознакомьтесь с перечнем заданий и оставляйте предложения к заданиям.",
              description: "Хорошо обдумайте, как именно предложить свои услуги, чтобы иметь конкурентное преимущество, напишите в предложении про свой опыт подобной работы, укажите свои пожелания. Откликаясь на свои первые заказы, стоит подумать над предложением бонусов и скидок своим заказчикам, в этом случае вы привлечете к себе внимание, быстрее получите свое первое задание и отзыв."
            },
            {
              description: "Постарайтесь ответственно подойти к составлению резюме, результат не заставит себя ждать."
            },
            {
              description: "<strong>ВНИМАНИЕ!</strong> В случае нарушения правил ваш аккаунт исполнителя может быть заблокирован, вам будет отказано в доступе к выполнению заданий сервиса GetJob.Но использовать поиск услуг других специалистов как заказчик вы по-прежнему сможете."
            },
            {
              description: "Мы очень заботимся о безопасности каждого человека, заказывающего и выполняющего работу на <strong>GetJob</strong>. По этой причине нашими правилами запрещено использование нескольких аккаунтов. Не пытайтесь создать зеркальный профиль после блокировки или использовать фейковые учетные записи для рекламных целей, администрация сервиса оставляет за собой право заблокировать такого пользователя навсегда."
            },
            {
              description: "В случае утери логина или пароля, не надо создавать профиль еще раз. Просто обратитесь в службу поддержки GetJob, наши специалисты помогут вам его восстановить."
            },
            {
              title: "Если вы решили стать исполнителем, внимательно прочтите следующие правила:",
              description: "1.  Создайте свой профиль. <br>" +
                "2.  Отправляйте интересные и привлекательные предложения к интересующим вас заданиям. Не стесняйтесь указывать свою стоимость, если вам не подходит цена заказчика.<br>" +
                "3.  От высоты вашего рейтинга напрямую зависит частота выбора вас в качестве исполнителя.<br>" +
                "4. Соблюдайте правила вежливого и тактичного поведения при общении с заказчиками. Не забывайте улыбаться. Доброжелательное отношение всегда оставляет приятное впечатление у заказчика.<br>"
            },
            {
              title: "За что можно получить бан или лишиться статуса исполнителя?",
              description: "Мы заботимся о своей репутации и безопасности наших пользователей, поэтому вынуждены незамедлительно реагировать на нарушение правил и принимать соответствующие меры к нарушителям<br>" +
                "Перед опубликованием задания или началом выполнения работ, обязательно прочитайте правила сервиса. За систематические нарушения правил каждый пользователь может быть заблокирован или лишиться статуса исполнителя, независимо от количества заказов и хороших отзывов\n"
            },
            {
              title: "Причины отказа в доступе:",
              description: "Предупрежден - значит вооружен. Познакомьтесь с наиболее частыми и грубыми случаями нарушений, из-за которых пользователю может быть навсегда отказано в доступе к GetJob\n"
            },
            {
              title: "Перечень действий, которые могут повлечь за собой блокировку навсегда:",
              description: "Любое действие, противоречащее законодательству ЧР. Как законопослушные граждане, мы строго следим за соблюдением на сервисе всех требований законодательства Чехии."
            },
            {
              title: "Стоимость заданий",
              description: "В заданиях заказчик сам указывает сумму, которую готов заплатить за работу, с учетом минимальных цен в каждой категории.\n"
            },
            {description: "В заданиях заказчик сам указывает сумму, которую готов заплатить за работу, с учетом минимальных цен в каждой категории."},
            {
              description: "GetJob не берет комиссию с заказчиков за размещение заданий. Оплата производится по договоренности между заказчиком и исполнителем, напрямую на расчетный счет исполнителя, либо наличным расчётом."
            },
            {
              description: "Изменить стоимость задания можно только у уже созданного задания."
            },
            {
              description: "Если договоритесь о дополнительной работе и оплате, заказчику нужно будет создать новое задание на сумму, которую нужно доплатить. Или создать новый договор на услуги."
            },
            {
              title: "В какой момент необходимо производить оплату за задание?",
              description: "В зависимости от договоренностей с исполнителем <br>" +
                "Обычно стороны договариваются об оплате по факту оказанию услуг. В зависимости от вида или объемов работ исполнитель может попросить предоплату – это нормальная практика. Все договоренности об оплате рекомендуется фиксировать письменно.\n"
            },
            {
              title: "Специалисты пользуются сервисом на бесплатной основе первые 3 месяца!",
              description: "По истечению 6 месяцев специалисты оплачивают услуги сервиса через покупку тарифов на те категории услуг, в которых хотят откликаться на заявки."
            },
            {
              description: "<strong>Тариф</strong> - это оплата, которая позволяет специалисту откликаться на заявки."
            },
            {
              description: "<strong>Название тарифов</strong> - это название категорий услуг. Какой тариф активирует специалист, по тем услугам и сможете откликаться."
            },
            {
              description: "<strong>Доступные</strong> сроки активации тарифов: 10 и 30 дней."
            },
            {
              title: "Цены тарифов формируются системой по десяткам различных показателей:",
              description: "активности специалистов, количеству заявок, затрат и доходов спецов и т.д. Когда происходит рост или снижение одного из показателей, цена поднимается или опускается."
            },
            {
              title: "Как можно подстраховаться?",
              description: "Пожалуйста, помните: мы не являемся работодателем наших пользователей и не влияем на ваше решение о сотрудничестве. Заказчика и исполнителя вы выбираете сами <br>" +
                "·  Обращайте внимание на отзывы пользователей"
            },
            {
              description: "<strong>Отзывы</strong> — основной инструмент для принятия решения о сотрудничестве. Пользователь, которого часто рекомендуют, дорожит своей репутацией. Всегда читайте отзывы о человеке, особенно если вы намерены доверить ему деньги или ценные предметы.<br>" +
                "·  Оформляйте договор, берите расписку\n"
            },
            {
              description: "Когда вы впускаете исполнителя к себе в дом, запросите у него копию паспорта, доверяете ему финансовые средства или материальные ценности — потребуйте расписку. Если есть возможность, рекомендуем подписать договор. Позаботьтесь о своей безопасности заранее, и мошенники обойдут вас стороной. <br>" +
                "Не обязательно оформлять стопки бумаг и обращаться к юристам. Для большинства заданий на GetJob подойдет документ в простой письменной форме, где вы можете своими словами сформулировать условия работы (задачи, оплату, сроки и т.д.) и зафиксировать паспортные данные. На составление такой бумаги уйдет несколько минут, и она очень поможет при решении споров через суд или арбитраж.<br>" +
                "При передаче любых предметов, имеющих материальную ценность, а также денежных средств, оформляйте расписку. Если вы заказываете курьерские услуги по доставке ценного груза или оплачиваете покупку стройматериалов, всегда фиксируйте факт передачи или получения средств. Ситуации бывают разные, иногда совершенно без злого умысла одна из сторон может забыть размер оплаты, что может вызвать споры и недоверие в работе<br>" +
                "<strong>Помните:</strong> без расписки, договора, других документов вам будет сложно доказать, что вы передали пользователю деньги и другие материальные ценности.<br>" +
                "·  Проверяйте паспортные данные<br>" +
                "Даже если вы считаете, что составлять договор или оформлять расписку для задания нецелесообразно, когда задача связана с возможной материальной ответственностью, как минимум проверьте паспортные данные пользователя.<br>" +
                "Несмотря на то что мы не участвуем в договоренностях и взаиморасчетах между пользователями, мы всегда готовы подключиться к диалогу в конфликтной ситуации и приложить максимум усилий для ее разрешения.<br>"
            },
            {
              title: "Как можно подстраховаться?",
              description: "По условия работы нашего сервиса, пользователи сами выбирают, с кем и на каких условиях сотрудничать, мы не влияем на это решение, поэтому настоятельно рекомендуем дополнительно заботиться о собственной безопасности."
            }
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.faq-container{
  .profile-transactions{
    width: 100%;
    border-bottom: none;
  }
  .profile-referral-lvl{
    border-top: none;
    width: 100%;
    padding-top: 0;
    &-info{
      //align-items: center;
    }
    &-bubble-orange{
      top: 147%;
      width: 600px;
    }
  }
}
</style>
