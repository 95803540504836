<template>
  <div class="pagination">
    <div class="pagination-item pagination-item-active">
      1
    </div>
    <div class="pagination-item">
      2
    </div>
    <div class="pagination-item">
      3
    </div>
    <div>
      ...
    </div>
    <div class="pagination-item">
      33
    </div>
    <div>
      >
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.pagination{
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: #999DA1;
  div{
    &:hover{
      cursor: pointer;
      color: #1E272F;
    }
  }
  &-item{
    padding: 2px 6px;
    border:1px solid transparent;
    &:hover{
      cursor: pointer;
      color: #1E272F;
      border:1px solid #FF6B17;
      border-radius: 3px;
    }
    &-active{
      color: #1E272F;
      border:1px solid #FF6B17;
      border-radius: 3px;
    }
  }
}
</style>
