<template>
  <div class="model-deposit-second-step">
    <div class="modal-deposit-second-step-header">
      Выберите способ пополнения
    </div>
    <div class="modal-deposit-second-step-content">
      <div class="modal-deposit-second-step-content-checkbox">
        <UiCheckbox title="Привязать эту карту"/>
      </div>
      <UiFormInput class="modal-deposit-second-step-content-input" title="Номер карты:" type="number" placeholder="0000 0000 0000 0000" :maxLength="16"/>
      <UiFormInput class="modal-deposit-second-step-content-input" title="Срок действия:" type="date" placeholder="00 / 00" :maxLength="5"/>
      <UiFormInput class="modal-deposit-second-step-content-input" title="CVC/CVV:" type="number" placeholder="000" :maxLength="3"/>
      <div class="modal-deposit-second-step-content-actions">
        <div class="modal-deposit-second-step-back" @click="$emit('back')">
          <svg width="38" height="19" viewBox="0 0 38 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.435367 10.2538L0.436702 10.2553L8.19286 17.974C8.77392 18.5522 9.71375 18.5501 10.2921 17.969C10.8704 17.3879 10.8682 16.4481 10.2872 15.8698L5.07968 10.6875L36.5156 10.6875C37.3354 10.6875 38 10.023 38 9.20313C38 8.38331 37.3354 7.71876 36.5156 7.71876L5.07975 7.71876L10.2871 2.53651C10.8681 1.9582 10.8704 1.01836 10.2921 0.437304C9.71367 -0.143901 8.77377 -0.145906 8.19278 0.432259L0.436629 8.15101L0.43529 8.15242C-0.146066 8.73266 -0.144208 9.67554 0.435367 10.2538Z"/>
          </svg>
        </div>
        <div class="modal-deposit-second-step-btn modal-primary-btn">
          Пополнить CZK {{amount}}
        </div>
      </div>
    </div>
    <div class="modal-deposit-second-step-footer">
      <div class="modal-deposit-second-step-footer-title">
        Другие способы пополнения
      </div>
      <div class="modal-deposit-second-step-footer-content">
        <UiApplePay/>
        <UiGooglePay/>
      </div>
    </div>
  </div>
</template>
<script>
import UiApplePay from "@/components/Ui/UiApplePay.vue";
import UiGooglePay from "@/components/Ui/UiGooglePay.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";

export default {
  name: "DepositSecond",
  components: {UiFormInput, UiCheckbox, UiGooglePay, UiApplePay},
  props: {
    amount: {
      type: Number,
      default: () => 0
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-deposit-second-step{
  .ui-apple-pay{
    width: 70px;
  }
  &-header{
    text-align: center;
    font-size: 20px;
    padding: 47px 114px;
  }
  &-content{
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    padding: 33px 75px;
    &-actions{
      display: flex;
      align-items: center;
      gap: 50px;
      margin-top: 24px;
    }
    &-checkbox{
      margin-left: 36px;
    }
    &-input{
      margin-top: 13px;
      width: 353px;
    }
  }
  &-back{
    cursor: pointer;
    svg{
      fill: #D9DBDC;
    }
    &:hover{
      svg{
        fill: #FF6B17;
      }
    }
  }
  &-footer{
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    padding: 34px 114px;
    text-align: center;
    font-size: 20px;
    &-content{
      display: flex;
      align-items: center;
      gap: 38px;
      margin-top: 27px;
    }
  }
}
</style>
