<template>
  <div class="faq">
    <h2>
      FAQ
    </h2>
    <div class="faq-container">
      <div class="faq-item" v-for="i in 6" :key="i">
        <router-link to="/">
          Вопрос номер 1
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FAQComponent"
}
</script>
<style lang="scss" scoped>
.faq{
  &-container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &-item{
    min-width: 190px;
  }
}
</style>
