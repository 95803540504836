<template>
  <div class="task-view container-banners">
    <div class="container">
      <TaskHeader
        :status="status"
        :is-executor="isExecutor"
        @update:modelValue="changeStatus"
      />
      <TaskContent
        :status="status"
        :is-executor="isExecutor"
        :is-owner="isOwner"
        @update:modelValue="changeStatus"
      />
    </div>
    <div v-if="!isExecutor && isOwner" class="task-view-footer">
      <div class="container">
        <TaskFooter/>
      </div>
    </div>
  </div>
</template>
<script>
import TaskHeader from "@/components/Tasks/TaskHeader.vue";
import TaskContent from "@/components/Tasks/TaskContent.vue";
import TaskFooter from "@/components/Tasks/TaskFooter.vue";

export default {
  name: 'TaskView',
  components: {TaskFooter, TaskContent, TaskHeader},
  data () {
    return {
      status: 2,
      isExecutor: false,
      isOwner: false
    }
  },
  mounted() {
    // const isExecutor = localStorage.getItem('isExecutor')
    // if (isExecutor) {
    //   this.isExecutor = isExecutor
    // }
    switch (Number(this.$route.params.id)) {
      case 1:
        this.isExecutor = false
        this.isOwner = true
        break
      case 2:
        this.isExecutor = true
        break
      case 3:
        this.isExecutor = false
        this.isOwner = false
        break
      case 4:
        this.isExecutor = false
        this.isOwner = true
        this.status = 4
        break
    }
  },
  methods: {
    changeStatus (value) {
      this.status = value
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.task-view{
  .task-content{
    margin-top: 39px;
  }
  &-footer{
    margin-top: 76px;
    border-top: 1px solid rgba(30, 39, 47, 0.17);
    border-radius: $border-radius;
    padding-top: 40px;
  }
}
.container-banners{
  padding: 50px 0;
}
</style>
