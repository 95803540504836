<template>
  <div class="modal-respond-task">
    <div class="modal-respond-task-header">
        Оставить отклик
    </div>
    <div class="modal-respond-task-content">
      <div class="modal-respond-task-content-title">
        Опишите что вы можете предложить заказчику по его задаче
      </div>
      <UiFormTextArea :max-length="1000"/>
      <div class="modal-respond-task-price">
        <UiInput label="CZK" placeholder="CZK 200"/>
        <div class="modal-respond-task-price-description">
          Ваша цена за данный заказ
        </div>
      </div>
    </div>
    <div class="modal-respond-task-footer" @click="closeModal">
      <div class="btn-orange">
        Оставить отклик
      </div>
      <div class="btn btn-secondary" @click="closeModal">
        Отмена
      </div>
    </div>
  </div>
</template>
<script>
import UiFormTextArea from "@/components/Ui/Form/UiFormTextArea.vue";
import UiInput from "@/components/Ui/UiInput.vue";

export default {
  name: 'ModalRespondTask',
  components: {UiInput, UiFormTextArea},
  methods: {
    closeModal() {
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss">
.modal-respond-task{
  width: 1160px;
  &-header{
    padding: 52px 79px 40px;
    font-size: 28px;
  }
  &-content, &-footer{
    border-top: 1px solid rgba(0,0,0,.12);
    border-radius: 21px;
  }
  &-content{
    padding: 47px 23px 42px;
    &-title{
      font-size: 18px;
      margin-left: 32px;
    }
    .ui-form-text-area{
      //width: 1064px;
      //height: 208px;
    }
  }
  &-footer{
    padding:42px 47px 45px;
    display: flex;
    align-items: center;
    gap: 14px;
    .btn-orange{
      width: 280px;
      height: 98px;
      font-size: 22px;
    }
    .btn-secondary{
      width: 171px;
      height: 98px;
      font-size: 22px;
    }
  }
  &-price{
    display: flex;
    align-items: center;
    margin-top: 21px;
    gap: 13px;
    .ui-input{
      width: 396px;
      .ui-input-label{
        min-width: 18px;
      }
      input {
        text-align: center;
        color: #287EFF;
      }
    }
    &-description{
      font-size: 18px;
      color:rgba(30, 39, 47, 0.51);
    }
  }
}
</style>
