<template>
  <div class="faq-item">
    <div v-if="isOpen" class="profile-referral-lvl-bubble profile-referral-lvl-bubble-blue"></div>
    <div class="faq-item-header" @click="isOpen = !isOpen">
      <svg :class="['faq-item-header-arrow', {'faq-item-header-arrow-rotated': isOpen}]" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00057 15.0001H19.0006C19.1828 14.9995 19.3615 14.9493 19.5173 14.8547C19.673 14.7602 19.8001 14.6249 19.8848 14.4636C19.9694 14.3022 20.0085 14.1207 19.9977 13.9388C19.9869 13.7569 19.9267 13.5814 19.8236 13.4311L10.8236 0.431105C10.4506 -0.107895 9.55257 -0.107895 9.17857 0.431105L0.178574 13.4311C0.0743986 13.581 0.0133079 13.7567 0.00193892 13.9389C-0.00943004 14.1211 0.0293576 14.303 0.114088 14.4647C0.198818 14.6264 0.32625 14.7619 0.482538 14.8562C0.638826 14.9506 0.817994 15.0004 1.00057 15.0001Z"/>
      </svg>
      <div :class="['faq-item-header-text', { 'faq-item-header-text-active': isOpen}]">
        {{ title }}
      </div>
    </div>
    <div v-html="description" class="faq-item-description">
    </div>
<!--    <div class="faq-item-description">-->
<!--      {{ description }}-->
<!--    </div>-->
    <div v-if="isOpen" class="faq-item-content">
      <div
        v-for="item in items"
        :key="item.id"
        class="faq-item-content-block"
      >
        <div class="faq-item-content-block-header">
          {{ item.title }}
        </div>
<!--        <div class="faq-item-content-block-description">-->
<!--          {{ item.description }}-->
<!--        </div>-->
        <div class="faq-item-content-block-description" v-html="item.description">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FaqItem",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>
<style lang="scss">
.faq-item{
  border-bottom: 1px solid #ECEDED;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  .profile-referral-lvl-bubble{
    top: -160px;
    width: 600px;
  }
  &-header{
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    &-text{
      font-size: 26px;
      height: 26px;
      &-active{
        color: #287EFF;
      }
    }
    &-arrow{
      fill: #1E272F;
      &-rotated{
        fill: #287EFF;
        transform: rotate(180deg);
      }
    }
  }
  &-description{
    margin-top: 23px;
    font-weight: 300;
  }
  &-content{
    margin-top: 8px;
    &-block{
      margin-top: 23px;
      &-header{
        font-weight: 500;
      }
      &-description{
        margin-top: 16px;
        font-weight: 300;
      }
    }
  }
}
</style>
