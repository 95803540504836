<template>
  <div class="mini-logo">
    <img src="@/assets/img/executorbanner.png" alt=""/>
  </div>
</template>
<style lang="scss">
.mini-logo{
  width: 70px;
  height: 70px;
  border: 1px solid #D9D9D9;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 41px;
    height: 34px;
  }
}
</style>
