<template>
  <div class="referral-desc">
    <div class="referral-desc-icon" v-if="isIcon">
      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1454 0L5.01225 6.61752L1.99555 3.3982L0 5.26816L4.87359 10.4691L14 2.01031L12.1454 0Z" fill="white"/>
      </svg>
    </div>
    <div class="referral-desc-icon" v-else>
      {{ number }}
    </div>
    <div class="referral-desc-container">
      <div class="referral-desc-container-title title">
        {{ title }}
      </div>
      <div class="referral-desc-container-desc">
        {{ desc }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReferralDesc',
  props: {
    number: {
      type: Number,
      default: () => 1
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => ''
    },
    desc: {
      type: String,
      default: () => ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.referral-desc{
  border: 1px solid rgba(30, 39, 47, .17);
  border-radius: $border-radius-medium;
  padding: 28px;
  display: flex;
  width: 545px;
  height: 143px;
  &-container{
    margin-left: 16px;
    &-desc{
      margin-top: 11px;
    }
  }
  &-icon{
    width: 33px;
    min-width: 33px;
    height: 33px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    background: #287EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
}
</style>
