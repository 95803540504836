<link rel="stylesheet" href="ProfileInfo.scss">
<template>
  <div class="profile-info">
    <div class="profile-info-photo" v-if="!isView">
      <div class="profile-info-photo-img">
        <img src="@/assets/img/profileimg.png"/>
        <div class="profile-info-photo-img-btn">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4998 8.52339C15.4987 9.62557 15.4903 10.5624 15.4277 11.3311C15.355 12.2231 15.2061 12.9684 14.8727 13.5875C14.7257 13.8605 14.545 14.105 14.325 14.325C13.7007 14.9493 12.9054 15.2321 11.8974 15.3676C10.9127 15.5 9.65054 15.5 8.04003 15.5H7.95997C6.34946 15.5 5.08734 15.5 4.10259 15.3676C3.09463 15.2321 2.29926 14.9493 1.67498 14.325C1.12153 13.7716 0.83572 13.0827 0.683733 12.2282C0.53443 11.3888 0.507118 10.3444 0.501444 9.04754C0.5 8.71767 0.5 8.36878 0.5 8.0007V7.95997C0.499991 6.34947 0.499984 5.08734 0.632382 4.10259C0.767898 3.09463 1.05069 2.29927 1.67498 1.67498C2.29927 1.05069 3.09463 0.767898 4.10259 0.632382C4.97834 0.51464 6.1051 0.501612 7.47652 0.500177C7.76551 0.499875 8 0.734269 8 1.02325C8 1.31224 7.7654 1.5464 7.47642 1.5467C6.08562 1.54817 5.05063 1.56085 4.24203 1.66956C3.35012 1.78948 2.81241 2.01754 2.41497 2.41497C2.01754 2.81241 1.78948 3.35012 1.66956 4.24203C1.54762 5.14899 1.54651 6.34077 1.54651 8C1.54651 8.20262 1.54651 8.39858 1.54675 8.58832L2.24522 7.97716C2.88099 7.42087 3.83919 7.45278 4.43654 8.05013L7.42937 11.043C7.90884 11.5224 8.66358 11.5878 9.21835 11.1979L9.42639 11.0517C10.2247 10.4907 11.3048 10.5557 12.03 11.2084L14.0048 12.9857C14.2036 12.5683 14.3216 12.0198 14.3846 11.2461C14.444 10.5171 14.4522 9.63161 14.4533 8.52341C14.4536 8.23443 14.6878 8 14.9767 8C15.2657 8 15.5001 8.2344 15.4998 8.52339Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.125 7.25C10.534 7.25 9.73851 7.25 9.24426 6.75574C8.75 6.26149 8.75 5.46599 8.75 3.875C8.75 2.28401 8.75 1.48851 9.24426 0.994257C9.73851 0.5 10.534 0.5 12.125 0.5C13.716 0.5 14.5115 0.5 15.0057 0.994257C15.5 1.48851 15.5 2.28401 15.5 3.875C15.5 5.46599 15.5 6.26149 15.0057 6.75574C14.5115 7.25 13.716 7.25 12.125 7.25ZM13.784 2.21596C14.072 2.5039 14.072 2.97075 13.784 3.2587L13.7407 3.30207C13.6988 3.34393 13.6404 3.36289 13.5821 3.35243C13.5454 3.34585 13.4919 3.33366 13.4279 3.31148C13.3001 3.26712 13.1322 3.18335 12.9744 3.02556C12.8167 2.86778 12.7329 2.69993 12.6885 2.57207C12.6663 2.50814 12.6541 2.45455 12.6476 2.41791C12.6371 2.35965 12.6561 2.30118 12.6979 2.25933L12.7413 2.21596C13.0292 1.92801 13.4961 1.92801 13.784 2.21596ZM12.0125 5.03022C11.9043 5.13844 11.8502 5.19255 11.7905 5.23908C11.7202 5.29397 11.644 5.34104 11.5634 5.37944C11.4951 5.41199 11.4225 5.43619 11.2773 5.48458L10.5117 5.73979C10.4403 5.76361 10.3615 5.74501 10.3082 5.69176C10.255 5.6385 10.2364 5.55973 10.2602 5.48828L10.5154 4.72265C10.5638 4.57746 10.588 4.50487 10.6206 4.43657C10.659 4.356 10.706 4.27985 10.7609 4.20947C10.8075 4.14981 10.8616 4.09571 10.9698 3.9875L12.3189 2.63837C12.3515 2.60575 12.4077 2.62068 12.4228 2.66426C12.4781 2.8235 12.5818 3.03066 12.7756 3.22444C12.9693 3.41822 13.1765 3.52194 13.3357 3.57719C13.3793 3.59231 13.3942 3.64849 13.3616 3.68111L12.0125 5.03022Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="profile-info-quotes">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06982 3.41853C4.24607 5.23203 2.67744 7.34253 2.36357 9.75115C1.87494 13.5 5.22744 15.3349 6.92619 13.6864C8.62494 12.0375 7.60682 9.94503 6.37682 9.37278C5.14682 8.8009 4.39457 9.00003 4.52582 8.2354C4.65707 7.47115 6.40682 5.35165 7.94432 4.36465C7.9988 4.31832 8.03356 4.25295 8.04151 4.18187C8.04945 4.1108 8.02998 4.03937 7.98707 3.98215L7.60682 3.48753C7.44182 3.27303 7.28357 3.28128 7.06982 3.41815V3.41853ZM14.5046 3.41853C11.6808 5.23203 10.1118 7.3429 9.79832 9.75115C9.30969 13.5 12.6622 15.3349 14.3609 13.6864C16.0597 12.0375 15.0416 9.94503 13.8112 9.37278C12.5812 8.8009 11.8289 9.00003 11.9602 8.2354C12.0914 7.47115 13.8416 5.35165 15.3791 4.36465C15.4335 4.31827 15.4682 4.25287 15.4761 4.1818C15.4839 4.11072 15.4644 4.03932 15.4214 3.98215L15.0412 3.48753C14.8762 3.27303 14.7179 3.28128 14.5046 3.41815V3.41853Z" fill="#287EFF"/>
        </svg>
      </div>
      <p>
        Не забывайте установить
        реальное фото профиля
      </p>
      <div class="profile-info-upload-info">
        Минимальный размер – 180×180px, Максимальный
        размер файла 7Mb
      </div>
    </div>
    <div class="profile-info-text">
      <div class="profile-info-name">
        <div class="profile-info-name-block">
          <div class="profile-info-name-surname">
              Максим Фамилия
          </div>
          <div class="profile-info-name-icons">
            <AcceptedIcon/>
          </div>
          <div v-if="isView" class="profile-info-date">
            На getjob с 11.10.2023
          </div>
        </div>
        <div class="profile-info-personal-info">
          <div class="profile-info-city">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.375 0.875C1.95973 0.875 0 2.63895 0 4.8125C0 8.3125 4.375 13.125 4.375 13.125C4.375 13.125 8.75 8.3125 8.75 4.8125C8.75 2.63895 6.79027 0.875 4.375 0.875ZM4.375 7C4.02888 7 3.69054 6.89736 3.40275 6.70507C3.11497 6.51278 2.89066 6.23947 2.75821 5.9197C2.62576 5.59993 2.5911 5.24806 2.65863 4.90859C2.72615 4.56913 2.89282 4.2573 3.13756 4.01256C3.3823 3.76782 3.69413 3.60115 4.03359 3.53363C4.37306 3.4661 4.72493 3.50076 5.0447 3.63321C5.36447 3.76566 5.63778 3.98997 5.83007 4.27775C6.02236 4.56554 6.125 4.90388 6.125 5.25C6.12449 5.71397 5.93996 6.1588 5.61188 6.48688C5.2838 6.81496 4.83897 6.99949 4.375 7Z" fill="#1E272F"/>
            </svg>
            <span>Прага</span>
          </div>
          <div class="profile-info-age">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004ZM10 6.00004C10 7.10461 9.10461 8.00004 8.00004 8.00004C6.89547 8.00004 6.00004 7.10461 6.00004 6.00004C6.00004 4.89547 6.89547 4.00004 8.00004 4.00004C9.10461 4.00004 10 4.89547 10 6.00004ZM8.00003 13.6667C9.1894 13.6667 10.2932 13.3003 11.2047 12.6742C11.6072 12.3976 11.7793 11.8709 11.5452 11.4422C11.06 10.5535 10.0602 10 8 10C5.93983 10 4.94002 10.5535 4.4548 11.4422C4.22074 11.8708 4.39277 12.3976 4.79534 12.6741C5.70683 13.3003 6.81064 13.6667 8.00003 13.6667Z" fill="#1E272F"/>
            </svg>
            <span>Возраст: 22</span>
          </div>
          <div v-if="!isView" class="profile-info-date">
            На getjob с 11.10.2023
          </div>
        </div>
        <div class="profile-info-rating" v-if="!isView">
          <div class="profile-info-rating-title">Общая оценка</div>
          <div class="profile-info-rating-stars">
            <StarsComponent/>
            <div class="profile-info-rating-number active">
              4.8
            </div>
          </div>
        </div>
      </div>
      <div v-if="isView" class="profile-info-online">
        <div class="profile-info-online-text">
          Сейчас на сайте
        </div>
        <ProfileAchievements/>
      </div>
      <div v-if="isView" class="profile-info-about-block">
        Учитывая ключевые сценарии поведения, внедрение современных методик требует определения и уточнения анализа существующих паттернов поведения.

        Повседневная практика показывает, что разбавленное изрядной долей эмпатии, рациональное мышление позволяет выполнить важные задания по разработке укрепления моральных ценностей. Как принято считать, интерактивные прототипы, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут превращены в посмешище, хотя само их существование приносит несомненную пользу
      </div>
      <div v-if="isExecutor || isView" :class="['profile-info-executor', {'profile-info-executor-is-view': isView}]">
        <div v-if="isView && isExecutor" class="profile-info-executor-view">
          <div class="profile-info-executor-view-title">
            Виды выполняемых работ
          </div>
          <div class="profile-info-executor-row">
            <div class="profile-info-executor-left">
              <div class="profile-info-executor-value">
                Курьерские услуги
              </div>
            </div>
            <div class="profile-info-executor-right">
              <div class="profile-info-executor-label">
                Выполненно:
              </div>
              <div class="profile-info-executor-value">
                3117 заданий
              </div>
            </div>
          </div>
          <div class="profile-info-executor-row">
            <div class="profile-info-executor-left">
              <div class="profile-info-executor-value">
                Грузоперевозки
              </div>
            </div>
            <div class="profile-info-executor-right">
              <div class="profile-info-executor-label">
                Выполненно:
              </div>
              <div class="profile-info-executor-value">
                1 задание
              </div>
            </div>
          </div>
          <div class="profile-info-executor-row">
            <div class="profile-info-executor-left">
              <div class="profile-info-executor-value">
                Виртуальный помощник
              </div>
            </div>
            <div class="profile-info-executor-right">
              <div class="profile-info-executor-label">
                нет заданий
              </div>
            </div>
          </div>
        </div>
        <div class="profile-info-executor-row">
          <div class="profile-info-executor-left">
            <div class="profile-info-executor-label">
              Заданий выполнено:
            </div>
            <div class="profile-info-executor-value">
              253
            </div>
          </div>
          <div class="profile-info-executor-right">
            <div class="profile-info-executor-label">
              Заданий создано:
            </div>
            <div class="profile-info-executor-value">
              53
            </div>
          </div>
        </div>
        <div  class="profile-info-executor-row">
          <div class="profile-info-executor-left">
            <div class="profile-info-executor-label">
              Отменено заданий:
            </div>
            <div class="profile-info-executor-value">
              3
            </div>
          </div>
          <div class="profile-info-executor-right">
            <div class="profile-info-executor-label">
              Оспорено заданий:
            </div>
            <div class="profile-info-executor-value">
              12
            </div>
          </div>
        </div>
        <div class="profile-info-executor-row" v-if="!isView">
          <div class="profile-info-executor-left">
            <a href="tel:+472242564324" class="profile-info-executor-value">
              +472 242 564 324
            </a>
          </div>
          <div class="profile-info-executor-right">
            <a href="mailto:maxim@gmail.com" class="profile-info-executor-value">
              maxim@gmail.com
            </a>
          </div>
        </div>
        <ProfileAchievements v-if="isExecutor"/>
      </div>
    </div>
  </div>
</template>
<script>
import StarsComponent from "@/components/Profile/Stars.vue";
import ProfileAchievements from "@/components/Profile/ProfileIndex/ProfileAchievements/ProfileAchievements.vue";
import AcceptedIcon from "@/components/Profile/View/AcceptedIcon.vue";

export default {
  name: "ProfileInfo",
  props: {
    isExecutor: {
      type: Boolean,
      default: () => false
    },
    isView: {
      type: Boolean,
      default: () => false
    }
  },
  components: {AcceptedIcon, ProfileAchievements, StarsComponent}
}
</script>
<style lang="scss">
@import "./ProfileInfo.scss";
</style>
