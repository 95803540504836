<template>
  <div class="reviews">
    <h1 class="reviews-title container">Отзывы о наших профессионалах</h1>
    <div class="reviews-container">
      <div class="container">
        <Carousel :itemsToShow="2.7" :transition="200" :wrap-around="true" snapAlign="center">
          <Slide v-for="slide in 10" :key="slide">
            <div class="carousel__item">
              <ReviewCard/>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import ReviewCard from "@/components/Home/Reviews/ReviewsCard.vue";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: "ReviewsComponent",
  components: {
    ReviewCard,
    Carousel,
    Slide,
    Pagination,
    Navigation
  }
}
</script>
<style>
@import "./Reviews.scss";
</style>
