<template>
  <div class="profile-settings">
    <div class="profile-settings-personal-data">
      <div class="profile-settings-inputs">
        <UiInput class="profile-settings-inputs-input" label="Имя" type="text" placeholder="Имя"/>
        <UiInput class="profile-settings-inputs-input" label="Фамилия" type="text" placeholder="Фамилия"/>
<!--        <UiInput class="profile-settings-inputs-input" label="Город" type="select" placeholder="Город"/>-->
        <DropdownComponent class="profile-settings-inputs-dropdown" position="bottom" ref="dropdownCategories">
          <template #icon>
            <UiInput label="Город*" multi-select :placeholder="activeCity.city ? activeCity.city : 'Выберите город'"/>
            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="dropicon">
              <path d="M4.62265 4.62341L-0.000789093 -2.7325e-05L9.24609 -2.81334e-05L4.62265 4.62341Z" fill="#1E272F"/>
            </svg>
          </template>
          <template #list>
            <div class="header-city-list-container">
              <div class="header-city-search">
                <div class="header-city-search-container">
                  <input placeholder="Поиск по категориям" @input="search" v-model="searchInput"/>
                  <div class="header-city-search-icon">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="header-city-list">
                <div
                  :class="['header-city-list-item', {'header-city-list-item-active': activeCity.city === city.city}]"
                  v-for="city in cities"
                  :key="city.city"
                  @click="setCity(city)"
                >
                  {{ city.city }}
                </div>
              </div>
            </div>
          </template>
        </DropdownComponent>
        <UiInput class="profile-settings-inputs-input" label="Дата рождения" type="date" placeholder="01.01.1970"/>
        <UiInput class="profile-settings-inputs-input" label="Email" type="email" placeholder="email@mail.com"/>
        <UiInput class="profile-settings-inputs-input" label="Пол" type="select" placeholder="Мужчина"/>
        <UiInput class="profile-settings-inputs-input" label="Номер телефона" type="number" placeholder="+00000000000"/>
      </div>
      <div class="btn btn-dark profile-settings-save" @click="saveSettings">
        Сохранить личные данные
      </div>
    </div>
    <ProfileCategories v-if="isExecutor"/>
    <ProfileSettingsGeo v-if="isExecutor"/>
    <div class="profile-settings-delete-account">
      <div :class="['profile-settings-delete-account-container', { 'profile-settings-delete-account-container-active' : isDelete}]" @click="isDelete = true">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.3517 5.61665L13.3929 2.05375C12.2651 1.03868 11.7012 0.531143 11.0092 0.265625L11 3.00011C11 5.35713 11 6.53564 11.7322 7.26787C12.4645 8.00011 13.643 8.00011 16 8.00011H19.5801C19.2175 7.29588 18.5684 6.71164 17.3517 5.61665Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 20H12C15.7712 20 17.6569 20 18.8284 18.8284C20 17.6569 20 15.7712 20 12V11.5629C20 10.6901 20 10.0344 19.9574 9.50011H16L15.9051 9.50011C14.808 9.5002 13.8385 9.50027 13.0569 9.3952C12.2098 9.28131 11.3628 9.0198 10.6716 8.32853C9.9803 7.63726 9.7188 6.79028 9.60491 5.94316C9.49983 5.16164 9.49991 4.19207 9.5 3.09497L9.50923 0.260566C9.50951 0.178128 9.51656 0.0965928 9.53001 0.0166612C9.1214 -4.26546e-07 8.6358 0 8.02979 0C4.23869 0 2.34315 0 1.17157 1.17157C0 2.34315 0 4.22876 0 8V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20ZM3.46967 12.4697C3.76256 12.1768 4.23744 12.1768 4.53033 12.4697L5.5 13.4393L6.46967 12.4697C6.76256 12.1768 7.23744 12.1768 7.53033 12.4697C7.82322 12.7626 7.82322 13.2374 7.53033 13.5303L6.56066 14.5L7.53033 15.4697C7.82322 15.7626 7.82322 16.2374 7.53033 16.5303C7.23744 16.8232 6.76256 16.8232 6.46967 16.5303L5.5 15.5607L4.53033 16.5303C4.23744 16.8232 3.76256 16.8232 3.46967 16.5303C3.17678 16.2374 3.17678 15.7626 3.46967 15.4697L4.43934 14.5L3.46967 13.5303C3.17678 13.2374 3.17678 12.7626 3.46967 12.4697Z"/>
        </svg>
        <div class="profile-settings-delete-account-text">
          Удалить профиль
        </div>
      </div>
      <div v-if="isDelete" class="profile-settings-delete-account-confirmation">
        <div class="profile-settings-delete-account-confirmation-text">
          Вы действительно хотите удалить аккаунт GetJob?
        </div>
        <div class="btn-primary" @click="isDelete = false">
          Отмена
        </div>
        <div class="btn btn-secondary">
          Удалить
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UiInput from "@/components/Ui/UiInput.vue";
import ProfileCategories from "@/components/Profile/ProfileSettings/ProfileCategories.vue";
import ProfileSettingsGeo from "@/components/Profile/ProfileSettings/ProfileSettingsGeo/ProfileSettingsGeo.vue";
import DropdownComponent from "@/components/Dropdown.vue";
import czcities from "@/components/Header/cz.json";

export default {
  name: "ProfileSettings",
  components: {DropdownComponent, ProfileSettingsGeo, ProfileCategories, UiInput},
  data(){
    return{
      isDelete: false,
      isExecutor: false,
      cities: czcities,
      activeCity: {},
      searchInput: ''
    }
  },
  beforeMount () {
    const isExecutor = localStorage.getItem('isExecutor')
    if (isExecutor) {
      this.isExecutor = isExecutor
    }
  },
  methods: {
    saveSettings() {
      this.$store.dispatch('setNotification', { name: 'save-notification', autoClose: true})
    },
    search(){
      this.cities = czcities.filter(el => {
        if(el['city'].toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1){
          return el
        }
      })
    },
    setCity(value){
      this.activeCity = value
      this.$refs.dropdownCategories.closeDropdown()
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileSettings.scss";
</style>
