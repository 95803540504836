<template>
  <div class="profile-settings-geo">
    <div class="profile-settings-geo-title">
      Включите геопозицию
    </div>
    <div class="profile-settings-geo-desc">
      Выберите эту настройку, если хотите получать заказы поблизости.
      Вы можете указать радиус, задания в пределах которого были созданы заказчиками
    </div>
    <div class="profile-settings-geo-switcher">
      <UiTurnSwitcher @update:modelValue="setGeo"/>
      <div v-if="mapEnabled" class="profile-settings-geo-switcher-text">
        Вы можете самоятоятельно выбрать локацию и радиус
      </div>
    </div>
<!--    <MapComponent v-if="mapEnabled && fetchedPos" :pos="pos"/>-->
    <MapComponent v-if="mapEnabled" :pos="pos"/>
  </div>
</template>
<script>
import UiTurnSwitcher from "@/components/Ui/UiTurnSwitcher.vue";
import MapComponent from "@/components/Map/Map.vue";
export default {
  name: 'ProfileSettingsGeo',
  components: {
    MapComponent,
    UiTurnSwitcher
  },
  data () {
    return {
      mapEnabled: false,
      pos: {
        lat: 0.0,
        lon: 0.0
      },
      fetchedPos: false
    }
  },
  methods: {
    setGeo(value){
      this.mapEnabled = value
      navigator.geolocation.watchPosition((pos) => {
        this.pos.lat = pos.coords.latitude
        this.pos.lon = pos.coords.longitude
        this.fetchedPos = true
        console.log('watch position is worked: ', pos)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-settings-geo{
  &-title{
    font-size: 18px;
  }
  &-desc{
    margin-top: 18px;
    font-size: 14px;
    font-weight: 300;
  }
  &-switcher{
    display: flex;
    align-items: center;
    height: 49px;
    margin-top: 20px;

    &-text{
      color: #FF6B17;
      font-size: 14px;
      margin-left: 19px;
    }
  }
  .map-component{
    width: 662px;
    height: 435px;
    border-radius: 34px;
    margin-top: 26px;
  }
}
</style>
