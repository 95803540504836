<template>
  <div class="categories">
    <h1 class="categories-title">Наши мастера умеют всё</h1>
    <div class="categories-container">
      <CategoryCard
          v-for="category in categories"
          :key="category.id"
          :src="category.img"
          :title="category.title"
          :executors-count="category.executorsCount"
      />
      <CategoryAllBtn/>
    </div>
  </div>
</template>
<script>
import CategoryCard from "@/components/Home/Categories/CategoryCard.vue";
import CategoriesJson from "./categories.json"
import CategoryAllBtn from "@/components/Home/Categories/CategoryAllBtn.vue";
export default {
  name: "CategoriesComponent",
  data(){
    return{
      categories: CategoriesJson
    }
  },
  components: {CategoryAllBtn, CategoryCard}
}
</script>
<style lang="scss">
@import "./Categories.scss";
</style>