<template>
  <div :class="['avatar-component-container', `avatar-component-${size}`]">
    <div class="avatar-component">
      <div class="avatar-component-bell" v-if="newMessage">
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.78393 6.74729C2.06429 7.10368 2.50473 7.33334 3 7.33334C3.49528 7.33334 3.93571 7.10368 4.21607 6.74729C3.40881 6.85667 2.59119 6.85667 1.78393 6.74729Z" fill="white"/>
          <path d="M5.24971 3V3.23471C5.24971 3.51637 5.33009 3.79173 5.48073 4.02609L5.84988 4.60039C6.18706 5.12496 5.92965 5.83797 5.34321 6.00386C3.80909 6.43781 2.19091 6.43781 0.656787 6.00386C0.0703488 5.83797 -0.187059 5.12496 0.15012 4.60039L0.519266 4.02609C0.669905 3.79173 0.75029 3.51637 0.75029 3.23471V3C0.75029 1.71134 1.75752 0.666672 3 0.666672C4.24248 0.666672 5.24971 1.71134 5.24971 3Z" fill="white"/>
        </svg>
      </div>
      <div v-if="isStar" :class="['avatar-component-star', `avatar-component-star-${starColor}`, `avatar-component-star-${starPosition}`]">
        <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.90675 1.12677C2.17063 0.65338 2.30257 0.416687 2.49984 0.416687C2.6971 0.416687 2.82904 0.65338 3.09293 1.12676L3.1612 1.24924C3.23619 1.38376 3.27368 1.45102 3.33214 1.4954C3.3906 1.53978 3.46341 1.55625 3.60903 1.5892L3.7416 1.61919C4.25403 1.73514 4.51025 1.79311 4.57121 1.98913C4.63217 2.18515 4.45749 2.38941 4.10815 2.79792L4.01777 2.90361C3.9185 3.01969 3.86886 3.07774 3.84653 3.14954C3.8242 3.22135 3.83171 3.29879 3.84672 3.45368L3.86038 3.59469C3.9132 4.13973 3.9396 4.41225 3.78001 4.5334C3.62043 4.65455 3.38053 4.54409 2.90074 4.32318L2.77661 4.26603C2.64027 4.20325 2.5721 4.17186 2.49984 4.17186C2.42758 4.17186 2.35941 4.20325 2.22306 4.26603L2.09894 4.32318C1.61914 4.54409 1.37925 4.65455 1.21966 4.5334C1.06007 4.41225 1.08648 4.13973 1.13929 3.59469L1.15296 3.45368C1.16797 3.29879 1.17547 3.22135 1.15314 3.14954C1.13081 3.07774 1.08118 3.01969 0.981904 2.90361L0.891524 2.79792C0.542181 2.38941 0.367509 2.18515 0.428467 1.98913C0.489424 1.79311 0.745641 1.73514 1.25807 1.61919L1.39065 1.5892C1.53626 1.55625 1.60907 1.53978 1.66753 1.4954C1.72599 1.45102 1.76349 1.38376 1.83848 1.24924L1.90675 1.12677Z" fill="white"/>
        </svg>
      </div>
      <router-link v-if="isLink" :to="to">
        <div class="avatar-component-img">
          <img src="@/assets/img/review.png"/>
        </div>
      </router-link>
      <div class="avatar-component-img" v-else>
        <img src="@/assets/img/review.png"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AvatarComponent",
  props: {
    isDropdown: {
      type: Boolean,
      default: ()=> false,
    },
    isLink: {
      type: Boolean,
      default: ()=> false,
    },
    to: {
      type: String,
      default: () => "/"
    },
    newMessage: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => 'default'
    },
    isStar: {
      type: Boolean,
      default: () => false
    },
    starColor: {
      type: String,
      default: () => 'orange'
    },
    starPosition: {
      type: String,
      default: () => 'bottom'
    }
  },
}
</script>
<style lang="scss" scoped>
.avatar-component{
  position: relative;
  &-img{
    box-shadow: 0px 4px 79px rgba(0, 0, 0, 0.25);
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    img{
      //width: 60px;
      //height: 60px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-default{
    width: 60px;
    height: 60px;
    .avatar-component-img{
      width: 57px;
      height: 57px;
    }
  }
  &-big{
    width: 79px;
    height: 79px;
    img{
      width: 77px;
      height: 77px;
    }
    .avatar-component-img{
      width: 75px;
      height: 75px;
    }
  }
  &-medium{
    width: 37px;
    height: 37px;
    min-width: 37px;
    img{
      width: 35px;
      height: 35px;
    }
    .avatar-component-img{
      width: 37px;
      height: 37px;
    }
  }
  &-mini{
    width: 24px;
    height: 24px;
    min-width: 24px;
    img{
      width: 22px;
      height: 22px;
    }
    .avatar-component-img{
      width: 24px;
      height: 24px;
    }
  }
  &-bell{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F44236;
    border: 1px solid #fff;
    border-radius: 50px;
    top: -1px;
    right: -1px;
    z-index: 1;
    width: 18px;
    height: 18px;
    svg{
      width: 10px;
      height: 10px;
    }
  }
  //&-container{
  //  display: flex;
  //  align-items: center;
  //  width: 60px;
  //  min-width: 60px;
  //  height: 60px;
  //}
  &-dropdown{
    margin-left: 10px;
    cursor: pointer;
  }
  &-star{
    position: absolute;
    right: 5px;
    width: 11px;
    height: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
    &-bottom{
      bottom: -2px;
    }
    &-top{
      top: 2px;
    }
    &-orange{
      background: #FF6B17;
    }
    &-blue{
      background: #287EFF;
    }
  }
}
</style>
