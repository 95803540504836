<template>
  <div class="banner-input-container">
    <div class="banner-input-icon">
      <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.5536 38.4326L29.2256 27.1046C31.9478 23.8366 33.3053 19.6449 33.0156 15.4015C32.726 11.1581 30.8115 7.18973 27.6705 4.32191C24.5295 1.4541 20.4038 -0.0923482 16.1516 0.00426926C11.8994 0.100887 7.84817 1.83313 4.84065 4.84065C1.83313 7.84817 0.100887 11.8994 0.00426926 16.1516C-0.0923482 20.4038 1.4541 24.5295 4.32191 27.6705C7.18973 30.8115 11.1581 32.726 15.4015 33.0156C19.6449 33.3053 23.8366 31.9478 27.1046 29.2256L38.4326 40.5536L40.5536 38.4326ZM3.05364 16.5536C3.05364 13.8836 3.8454 11.2735 5.3288 9.05344C6.8122 6.83338 8.92061 5.10305 11.3874 4.08127C13.8542 3.05948 16.5686 2.79214 19.1874 3.31304C21.8061 3.83394 24.2116 5.11969 26.0996 7.0077C27.9876 8.89571 29.2733 11.3012 29.7942 13.9199C30.3151 16.5387 30.0478 19.2531 29.026 21.7199C28.0042 24.1867 26.2739 26.2951 24.0538 27.7785C21.8338 29.2619 19.2237 30.0536 16.5536 30.0536C12.9744 30.0497 9.54296 28.6261 7.01208 26.0952C4.4812 23.5643 3.05761 20.1328 3.05364 16.5536Z" fill="#1E272F" fill-opacity="0.21"/>
      </svg>
    </div>
    <input class="banner-input" :placeholder="placeholder" type="text">
    <div v-if="filter" :class="['banner-input-filter', { 'banner-input-filter-active': activeFilter}]">
      <DropdownComponent v-click-outside="()=> {activeFilter = false}">
        <template #icon>
          <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg" @click="activeFilter = !activeFilter">
            <path d="M20.8505 14.4376C20.1946 14.4376 19.663 13.9059 19.663 13.2501L19.663 2.16675C19.663 1.51091 20.1946 0.979248 20.8505 0.979248C21.5063 0.979248 22.038 1.51091 22.038 2.16675L22.038 13.2501C22.038 13.9059 21.5063 14.4376 20.8505 14.4376Z" />
            <path d="M5.08203 18.7917C2.45868 18.7917 0.332031 16.6651 0.332031 14.0417C0.332031 11.4184 2.45868 9.29175 5.08203 9.29175C7.70538 9.29175 9.83203 11.4184 9.83203 14.0417C9.83203 16.6651 7.70538 18.7917 5.08203 18.7917Z" />
            <path d="M20.9154 17.2084C18.292 17.2084 16.1654 19.3351 16.1654 21.9584C16.1654 24.5818 18.292 26.7084 20.9154 26.7084C23.5387 26.7084 25.6654 24.5818 25.6654 21.9584C25.6654 19.3351 23.5387 17.2084 20.9154 17.2084Z" />
            <path d="M3.82963 22.7501C3.82963 22.0942 4.36129 21.5626 5.01713 21.5626C5.67297 21.5626 6.20463 22.0942 6.20463 22.7501V33.8334C6.20463 34.4893 5.67297 35.0209 5.01713 35.0209C4.36129 35.0209 3.82963 34.4893 3.82963 33.8334V22.7501Z" />
            <path d="M20.8505 35.0209C20.1946 35.0209 19.663 34.4893 19.663 33.8334V30.6667C19.663 30.0109 20.1946 29.4792 20.8505 29.4792C21.5063 29.4792 22.038 30.0109 22.038 30.6667V33.8334C22.038 34.4893 21.5063 35.0209 20.8505 35.0209Z" />
            <path d="M3.82963 2.16675C3.82963 1.51091 4.36129 0.979248 5.01713 0.979248C5.67297 0.979248 6.20463 1.51091 6.20463 2.16675V5.33342C6.20463 5.98925 5.67297 6.52092 5.01713 6.52092C4.36129 6.52092 3.82963 5.98925 3.82963 5.33342V2.16675Z" />
          </svg>
        </template>
        <template #list>
          <div class="dropdown-filter">
            <div class="banner-input-filter-title">
              Фильтр
            </div>
            <div class="banner-input-filter-checkbox">
              <div class="banner-input-filter-checkbox-item">
                <UiCheckbox title="Все языки" color="orange" size="medium"/>
              </div>
              <div class="banner-input-filter-checkbox-item">
                <UiCheckbox title="Только на чешском" color="orange" size="medium"/>
              </div>
              <div class="banner-input-filter-checkbox-item">
                <UiCheckbox title="Только на английском" color="orange" size="medium"/>
              </div>
              <div class="banner-input-filter-checkbox-item">
                <UiCheckbox title="Только на русском" color="orange" size="medium"/>
              </div>
              <div class="banner-input-filter-checkbox-item">
                <UiCheckbox title="Только на вьетнамском" color="orange" size="medium"/>
              </div>
            </div>
            <div class="banner-input-filter-city">
              <DropdownComponent position="bottom" ref="dropdown" v-click-outside="clear">
                <template #icon>
                  <div class="header-city-container">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.375 0.875C1.95973 0.875 0 2.63895 0 4.8125C0 8.3125 4.375 13.125 4.375 13.125C4.375 13.125 8.75 8.3125 8.75 4.8125C8.75 2.63895 6.79027 0.875 4.375 0.875ZM4.375 7C4.02888 7 3.69054 6.89736 3.40275 6.70507C3.11497 6.51278 2.89066 6.23947 2.75821 5.9197C2.62576 5.59993 2.5911 5.24806 2.65863 4.90859C2.72615 4.56913 2.89282 4.2573 3.13756 4.01256C3.3823 3.76782 3.69413 3.60115 4.03359 3.53363C4.37306 3.4661 4.72493 3.50076 5.0447 3.63321C5.36447 3.76566 5.63778 3.98997 5.83007 4.27775C6.02236 4.56554 6.125 4.90388 6.125 5.25C6.12449 5.71397 5.93996 6.1588 5.61188 6.48688C5.2838 6.81496 4.83897 6.99949 4.375 7Z" fill="#999DA1"/>
                    </svg>
                    <div class="header-city-container-text">
                      {{cities['active']}}
                    </div>
                    <svg class="dropdown-svg dropdown-svg-grey" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 6L-5.24537e-07 1.04907e-06L12 0L6 6Z"/>
                    </svg>
                  </div>
                </template>
                <template #list>
                  <div class="header-city-list-container">
                    <div class="header-city-search">
                      <div class="header-city-search-container">
                        <input placeholder="Поиск по городу" @input="search" v-model="searchInput"/>
                        <div class="header-city-search-icon">
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="header-city-list">
                      <div :class="['header-city-list-item', {'header-city-list-item-active': cities['active'] === city['city']}]" v-for="(city) in cities['data']" :key="city['city']" @click="setCity(city['city'])">
                        {{city['city']}}
                      </div>
                    </div>
                  </div>
                </template>
              </DropdownComponent>
            </div>
            <div class="banner-input-filter-footer">
              <div class="banner-input-filter-footer-radius">
                Выберите радиус
              </div>
              <div :class="['banner-input-filter-footer-radius-input', { 'banner-input-filter-footer-radius-input-inactive': !radius}]">
                {{ radius ? radius : "До, км"}}
              </div>
              <div class="banner-input-filter-footer-slider">
                <VueSlider
                  v-model="radius"
                  :interval="0.1"
                  :min="0"
                  :max="100"
                  :process-style="{ backgroundColor: '#FF6B17' }"
                  :tooltip="'none'"
                />
              </div>
              <div class="banner-input-filter-footer-actions">
                <div class="btn btn-dark" @click="()=> {activeFilter = false}">
                  Применить фильтры
                </div>
                <div class="btn btn-secondary">
                  Очистить
                </div>
              </div>
            </div>
          </div>
        </template>
      </DropdownComponent>
    </div>
    <div class="banner-input-btn btn-orange">{{btnText}}</div>
  </div>
</template>
<script>
import DropdownComponent from "@/components/Dropdown.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";
import czcities from "@/components/Header/cz.json";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  name: "BannerInput",
  components: {UiCheckbox, DropdownComponent, VueSlider},
  props: {
    btnText: {
      type: String,
      default: () => ""
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    filter: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      activeFilter: false,
      cities: {
        'active': 'Olomouc',
        'data': czcities
      },
      radius: 0,
      searchInput: ""
    }
  },
  methods: {
    search(){
      this.cities['data'] = czcities.filter(el => {
        if(el['city'].toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1){
          return el
        }
      })
    },
    setCity(value){
      this.cities['active'] = value;
      this.$refs.dropdown.closeDropdown();
      this.clear();
    },
    clear(){
      this.cities['data'] = czcities;
      this.searchInput = "";
    },
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.banner-input{
  border: none;
  outline: none;
  height: 3rem;
  box-shadow: none;
  font-size: 22px;
  width: 100%;
  margin-left: 32px;
  background: transparent;
  &-container{
    display: flex;
    box-shadow: 0px 38px 202px 0px #1E272F2B;
    align-items: center;
    padding-left: 2rem;
    border-radius: $border-radius;
    width: 830px;
    height: 98px;
  }
  &-btn{
    font-size: 22px;
    padding: 37px 54px;
    position: relative;
    white-space: nowrap;
    max-height: 98px;
    z-index: 1;
    border-radius: $border-radius;
    &:hover{
      opacity: .75;
      cursor: pointer;
    }
  }
  &-filter{
    margin-right: 24px;
    padding-left: 10px;
    cursor: pointer;
    &-title{
      font-size: 18px;
      font-weight: 500;
    }
    &-checkbox{
      margin-top: 34px;
      label{
        font-size: 16px!important;
        font-weight: 300;
      }
      &-item:not(:first-child){
        margin-top: 16px;
      }
      padding-bottom: 20px;
      border-bottom: 1px solid #ECEDED;
    }
    .dropdown-list{
      width: 318px;
      height: 522px;
      left: -90px;
      top: 80px;
      padding-left: 27px;
      padding-right: 29px;
      z-index: 6;
    }
    svg {
      fill: #1e272f;
    }
    &-active{
      svg {
        fill: #287EFF;
      }
    }
    &-city{
      .dropdown-icon{
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid #ECEDED;
      }
      .dropdown-list{
        top: 30px!important;
        left: -10px!important;
        width: 245px!important;
        height: 251px!important;
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
    &-footer{
      margin-top: 24px;
      &-radius{
        font-size: 14px;
        font-weight: 300;
        &-input{
          margin-top: 12px;
          border-radius: 9px;
          background: #F2F2F2;
          width: 101px;
          height: 36px;
          padding: 11px;
          font-size: 12px;
          &-inactive{
            color: rgba(30, 39, 47, 0.29);
          }
        }
      }
      &-slider{
        margin-top: 12px;
      }
      .vue-slider{
        height: 6px!important;
      }
      .vue-slider-dot{
        width: 20px!important;
        height: 20px!important;
        &-handle{
          background: #FF6B17!important;
          border: 2px solid #FFF!important;
        }
      }
      &-actions{
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 19px;
        .btn-dark{
          width: 157px;
          height: 34px;
          font-size: 12px;
          padding: 0;
        }
        .btn-secondary{
          width: 92px;
          height: 34px;
          font-size: 12px;
          padding: 0;
        }
      }
    }
  }
}
</style>
