export default{
  state: {
    isAuth: false
  },
  getters: {
    getAuth: state => state.isAuth
  },
  mutations: {
    SET_IS_AUTH(state, value){
      state.isAuth = value;
    }
  },
  actions: {
      setAuth: ({ commit }, value) => {
        commit("SET_IS_AUTH", value);
      }
  }
}
