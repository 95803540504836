<template>
  <div class="notification save-notification">
    <div class="save-notification-text">Сохранено успешно</div>
    <div class="save-notification-icon">
      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1454 0L5.01225 6.61752L1.99555 3.3982L0 5.26816L4.87359 10.4691L14 2.01031L12.1454 0Z" fill="white"/>
      </svg>
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="scss">
.save-notification{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 200px;
  height: 38px;
  background: rgba(#FF6B17, .75);
  border-radius: 21px;
  &-text{
    font-size: 14px;
    //color: #3CB055;
    color: #fff;
  }
  &-icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #3CB055;
  }
}
</style>
