<template>
  <div class="modal-deposit">
    <DepositFirst :title="title" @deposit="deposit" v-if="step === 1"/>
    <DepositSecond :amount="amount" v-if="step === 2" @back="step = 1"/>
  </div>
</template>
<script>
import DepositFirst from "@/components/Modals/ModalDeposit/Step1.vue";
import DepositSecond from "@/components/Modals/ModalDeposit/Step2.vue";

export default {
  name: "ModalDeposit",
  components: {DepositSecond, DepositFirst},
  props: {
    title: {
      type: String,
      default: ()=> ''
    }
  },
  data(){
    return{
      step: 1,
      amount: 0
    }
  },
  methods: {
    deposit(value){
      this.amount = value;
      this.step = 2
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-deposit{

}
</style>
