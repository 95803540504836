<template>
  <div class="how-it-works-container">
    <div class="how-it-works-header">
      <h1>
        Как это работает
      </h1>
      <div class="how-it-works-actions">
        <UiSwitcherType @update:modelValue="setExecutor"/>
     </div>
      <div class="how-it-works-link">
        <router-link to="/">
          Узнать подробнее как это работает
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L9 1M9 1V8.68M9 1H1.32" stroke="#FF6B17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="how-it-works-content" v-if="isExecutor === 0">
      <HowItWorksCard
          title="Создайте задание"
          description="Укажите детали, время
           и желаемую сумму."
          src="hiw1.png"
      />
      <HowItWorksCard
          title="Получите отклики от профессионалов"
          description="Договоритесь о сотрудничестве."
          src="hiw2.png"
      />
      <HowItWorksCard
          title="Оплачивайте безопасно онлайн"
          description="После того как задание выполнено."
          src="hiw3.png"
      />
    </div>
    <div class="how-it-works-content" v-else>
      <HowItWorksCard
        title="Находите новых клиентов"
        description="Откликайтесь на созданные задания предлагайте свои услуги"
        src="hiw4.png"
      />
      <HowItWorksCard
        title="Получите отклики от профессионалов"
        description="Договоритесь о сотрудничестве."
        src="hiw5.png"
      />
      <HowItWorksCard
        title="Получайте
гарантированную оплату"
        description="После того как задание выполнено."
        src="hiw6.png"
      />
    </div>
  </div>
</template>
<script>
import HowItWorksCard from "@/components/Home/HowItWorks/HowItWorksCard.vue";
import UiSwitcherType from "@/components/Ui/UiSwitcherType.vue";

export default {
  name: "HowItWorksComponent",
  components: {UiSwitcherType, HowItWorksCard},
  data(){
    return{
      isExecutor: 0
    }
  },
  methods: {
    setExecutor(value){
      this.isExecutor = value
    }
  }
}
</script>
<style lang="scss">
@import "./HowItWorks.scss";
</style>
