<template>
  <div class="find-task-item">
    <div class="find-task-item-header">
      <div class="find-task-item-header-avatar">
        <AvatarComponent :size="!isExecutors ? 'medium' : 'big'"/>
        <div v-if="!isExecutors" class="find-task-item-header-name">
          By George
        </div>
        <div v-else class="find-task-item-header-name">
          <div>Имя Фамилия</div>
          <div class="find-task-item-stars">
            <StarsComponent/>
            <div class="find-task-item-stars-value">
              4.8
            </div>
            <div class="find-task-item-stars-reviews">
              428 отзывов
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isExecutors" class="find-task-item-header-date">
        <div class="find-task-item-header-date-text">
          Приступить:
        </div>
        <div class="find-task-item-header-date-value">
          16.10.2023  в 13:38
        </div>
      </div>
      <div v-else class="find-task-item-header-date">
        <div class="find-task-item-header-date-text">
          Был на сайте 3 дня назад
        </div>
      </div>
    </div>
    <div :class="['find-task-item-content', {'find-task-item-content-light': isExecutors}]">
      Мне нужно срочно убрать квартиру к приходу гостей такой длины текст
      в 2 или даже 3 строчки можно уместить
    </div>
    <div class="find-task-item-footer">
      <div v-if="!isExecutors" class="btn-orange">
        Детали
      </div>
      <div v-else class="btn-orange find-task-item-footer-btn" @click="offerTask">
        Предложить задание
      </div>
      <div v-if="!isExecutors" class="find-task-item-footer-price">
        CZK 2,000
      </div>
      <div v-else class="find-task-item-footer-achievements">
        <AchievementClassic :count="500"/>
        <AchievementSpecial type="top" color="gold" value="50"/>
        <AchievementSpecial color="silver" value="98%"/>
      </div>
      <div v-if="!isExecutors" class="find-task-item-footer-category">
        Категория: Клининг
      </div>
    </div>
  </div>
</template>
<script>
import AvatarComponent from "@/components/Avatar.vue";
import StarsComponent from "@/components/Profile/Stars.vue";
import AchievementClassic from "@/components/Profile/ProfileIndex/ProfileAchievements/AchievementClassic.vue";
import AchievementSpecial from "@/components/Profile/ProfileIndex/ProfileAchievements/AchievementSpecial.vue";

export default {
  name: "FindTaskItem",
  components: {AchievementSpecial, AchievementClassic, StarsComponent, AvatarComponent},
  props: {
    isExecutors: {
      type: Boolean,
      default: () => false
    },
    haveTasks: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    offerTask(){
      if (!this.haveTasks){
        this.$store.dispatch('setModal', {
          modalName: "ModalOnlyExecutors",
          customClose: true,
          props: {
            title: "У вас пока нет опубликованных заданий",
            description: "Создайте задание, после чего вы сможете предложить выполнить его исполнителям.",
            confirmBtn: "Создать задание"
          }
        })
      } else {
        this.$store.dispatch('setModal', {
          modalName: "ModalOnlyExecutors",
          customClose: true,
          props: {
            title: "Предложить задание",
            description: "Выберите задание, которое хотите предложить исполнителю",
            confirmBtn: "Предложить",
            haveTasks: true
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.find-task-item{
  padding: 18px;
  border: 1px solid rgba(30, 39, 47, 0.17);
  border-radius: $border-radius-medium;
  &-header{
    display: flex;
    align-items: center;
    &-avatar{
      display: flex;
      align-items: center;
    }
    &-name{
      font-size: 18px;
      margin-left: 12px;
    }
    &-date{
      margin-left: auto;
      display: flex;
      align-items: center;
      &-text{
        color: #999DA1;
      }
      &-value{
        margin-left: 5px;
      }
    }
  }
  &-content{
    font-size: 18px;
    font-weight: 500;
    margin-top: 21px;
    &-light{
      font-weight: 400;
    }
  }
  &-footer{
    margin-top: 41px;
    display: flex;
    align-items: center;
    .btn-orange{
      width: 130px;
      height: 46px;
      font-size: 12px;
    }
    &-price{
      color: #287EFF;
      margin-left: 21px;
      font-weight: 500;
    }
    &-category{
      font-size: 14px;
      margin-left: auto;
    }
    &-achievements{
      margin-left: 13px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-btn{
      width: 165px!important;
      font-size: 12px!important;
    }
  }
  &-stars{
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 10px;
    &-value{
      color: #FF6B17;
      font-weight: 500;
      font-size: 12px;
      //height: 3px;
      margin-left: 10px;
    }
    &-reviews{
      font-size: 12px;
      color: #999DA1;
      //height: 3px;
      margin-left: 23px;
    }
  }
  .stars-container{
    svg{
      width: 16px;
      height: 16px;
    }
  }
}
</style>
