<template>
  <div class="create-task-step4">
    <div class="create-task-step4-container">
      <div class="create-task-container-title">
        На какой бюджет вы рассчитываете?
      </div>
      <div class="create-task-container-description">
        Укажите примерный бюджет. <br>
        Финальную стоимость можно будет обсудить с исполнителем
      </div>
      <UiInput label="CZK" placeholder="от 500,00"/>
      <div class="create-task-step4-payment">
        <div class="create-task-container-description">
          Выберите способ оплаты:
        </div>
        <div class="create-task-step4-payment-item">
          <UiCheckbox color="orange"/>
          <div class="create-task-step4-payment-item-text">
            <div class="create-task-step4-payment-item-text-header">
              Безопасная сделка
            </div>
            <div class="create-task-step4-payment-item-text-desc">
              Оплата банковской картой с гарантией возврата. <router-link to="/">Подробнее</router-link>
            </div>
          </div>
        </div>
        <div class="create-task-step4-payment-item create-task-step4-payment-item-divider">
          <UiCheckbox color="orange"/>
          <div class="create-task-step4-payment-item-text">
            <div class="create-task-step4-payment-item-text-header">
              Оплата напрямую исполнителю
            </div>
            <div class="create-task-step4-payment-item-text-desc">
              Без гарантий и компенсаций: вы напрямую договариваетесь с исполнителем
              об условиях и способе оплаты.
            </div>
          </div>
        </div>
        <div class="create-task-step4-payment-item">
          <UiCheckbox color="orange"/>
          <div class="create-task-step4-payment-item-text">
            <div class="create-task-step4-payment-item-text-header">
              Оплатить потом через Twisto
            </div>
            <div class="create-task-step4-payment-item-text-desc">
              Закажите услугу сейчас, оплатите потом. <router-link to="/">Подробнее</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TipsComponent/>
  </div>
</template>
<script>
import TipsComponent from "@/components/Tasks/TipsComponent.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";

export default {
  name: 'CreateTaskStep4',
  components: {UiCheckbox, UiInput, TipsComponent},
  methods: {
    nextStep(){
      return true
    }
  }
}
</script>
<style lang="scss">
.create-task-step4{
  display: flex;
  padding-bottom: 171px;
  &-payment{
    margin-top: 27px;
    &-item{
      display: flex;
      margin-top: 21px;
      &-divider{
        padding-bottom: 25px;
        border-bottom: 1px solid #D9D9D9;
      }
      &-text{
        &-desc{
          font-size: 14px;
          font-weight: 300;
        }
        a{
          color: #287EFF;
          &:hover{
            color: #FF6B17;
          }
        }
      }
    }
  }
  .ui-input{
    width: 406px;
    margin-top: 32px;
    height: 72px;
    &-input{
      input{
        text-align: center;
      }
    }
    &-label{
      min-width: 30px!important;
    }
  }
  .tips-component{
    min-width: 360px;
    width: 360px;
  }
}

</style>
