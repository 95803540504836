<template>
  <div v-if="!items" class="categories-content container">
    <CategoryBlock v-for="category in categories" :key="category.id" :title="category.name" :icon="category.icon" :subcategories="category.subcategories"/>
  </div>
  <div v-else class="categories-content categories-content-items container">
    <CategoryBlock v-for="category in items" :key="category.id" :title="category.name" :icon="category.icon" :subcategories="category.subcategories"/>
  </div>
</template>
<script>
import CategoryBlock from "@/components/Categories/CategoryBlock.vue";
import Categories from "./categories.json"
export default {
  name: "CategoriesContent",
  props: {
    items: {
      type: Array
    }
  },
  components: {
    CategoryBlock
  },
  data () {
    return {
      categories: Categories
    }
  }
}
</script>
<style lang="scss">
.categories-content{
  display: flex;
  align-items: center;
  margin-top: -29px;
  flex-wrap: wrap;
  gap:50px;
  &-items{
    .category-block{
      min-height: 726px!important;
    }
  }
  .category-block{
    margin-top: 29px;
    min-width: 350px;
    min-height: 426px;
  }
}
</style>
