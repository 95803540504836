<template>
  <div :class="['achievement-special', `achievement-special-${color}`]">
    <div>
      <div v-if="type === 'top'" class="achievement-special-top">
        TOP
      </div>
      <div>
        {{ value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AchievementSpecial",
  props: {
    type: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => 'gold'
    },
    value: {
      type: String,
      default: () => ''
    }
  },
}
</script>
<style lang="scss" scoped>
.achievement-special{
  width: 50px;
  height: 50px;
  border-radius: 50% ;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  &-top{
    font-size: 8px;
  }
  &-gold{
    color: #584324;
    background: conic-gradient(from 180deg at 48.5% 50%, #F6DBA6 -28.12deg, rgba(227, 164, 85, 0.75) 26.25deg, #F6DBA6 88.12deg, #FFEBC4 156.58deg, #F0BE79 191.74deg, #F6DBA6 213.59deg, #F6DBA6 225.95deg, #F6DBA6 255.19deg, #EEBC70 278.6deg, #F6DBA6 331.88deg, rgba(227, 164, 85, 0.75) 386.25deg);
    border: 1px solid #D6A65B;
  }
  &-silver{
    color: #50697E;
    background: conic-gradient(from 180deg at 48.5% 50%, #E3E9EE -28.12deg, #FBFBFD 26.25deg, #E3E9EE 88.12deg, #FFFFFF 156.58deg, #E3E9EE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #E3E9EE 310.11deg, #E3E9EE 331.88deg, #FBFBFD 386.25deg);
    border: 1px solid #89A4BA;
  }
}
</style>
