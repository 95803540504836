<template>
  <div class="profile-review-card">
    <AvatarComponent/>
    <div class="profile-review-card-info">
      <div class="profile-review-card-name">
        <div>{{ name }}</div>
        <div class="profile-review-card-date">
          15:27    03.10.2023
        </div>
      </div>
      <div class="profile-review-card-text">
        {{ text }}
      </div>
      <StarsComponent class="profile-review-card-stars"/>
    </div>
  </div>
</template>
<script>
import StarsComponent from "@/components/Profile/Stars.vue";
import AvatarComponent from "@/components/Avatar.vue";

export default {
  name: "ProfileReviewCard",
  components: {AvatarComponent, StarsComponent},
  props: {
    name: {
      type: String,
      default: () => ""
    },
    text: {
      type: String,
      default: () => ""
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileReviewCard.scss";
</style>
