<template>
  <div class="transaction-component">
    <div class="transaction-component-date">
      {{ date }}
    </div>
    <div :class="['transaction-component-type', {
      'red': amount.type === 'Refund'
    }]">
      {{ typeDict[type] }}
    </div>
    <div class="transaction-component-from-to">
      От кого/кому <router-link :to="'/' + user.id">{{ user.name }}</router-link>
    </div>
    <div class="transaction-component-amount">
      Сумма:
      <div :class="['transaction-component-amount-value', {
      'red': amount.type === 'Minus',
      'green': amount.type === 'Plus'
    }]">
        CZK {{ amount.type === 'Plus' || amount.type === 'Refund' ? '+' : (amount.type === 'Minus' ? '-' : '') }} {{ amount.value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransactionComponent",
  props: {
    date: {
      type: String,
      default: () => "01.01.2001"
    },
    type: {
      type: String,
      default: ()=> "Списание"
    },
    user: {
      type: String,
      default: ()=> "Name Name"
    },
    amount: {
      type: Object,
      default: ()=> {
        return {
          'type': 'Plus',
          'value': '2,000'
        }
      }
    }
  },
  data(){
    return{
      typeDict: {
        "Plus": "Пополнение",
        "Minus": "Списание",
        "Refund": "Возврат"
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.transaction-component{
  font-size: 12px;
  display: flex;
  align-items: center;
  &-amount{
    display: flex;
    align-items: center;
    &-value{
      margin-left: 3px;
    }
  }
  &-type{
    margin-left: 46px;
    width: 58px;
  }
  &-from-to{
    margin-left: 62px;
    color: #999DA1;
    a{
      color: #287EFF;
    }
  }
  &-amount{
    margin-left: auto;
  }
}
</style>
