<template>
  <div class="ui-form-text-area">
    <div :class="['ui-form-text-area-title', {'ui-form-text-area-title-black': blackTitle}]">
      {{title}}
    </div>
    <div class="ui-form-text-area-input" >
      <textarea :placeholder="placeholder" v-model="vModel" @input="changeInput"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiFormTextArea',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    maxLength: {
      type: Number,
      default: () => 300
    },
    blackTitle: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return{
      vModel: null,
    }
  },
  methods: {
    changeInput(){
      if(this.maxLength > 0 && String(this.vModel).split('').length > this.maxLength){
        this.vModel = String(this.vModel).split('', this.maxLength).join('')
      }
      this.$emit('update:modelValue', this.vModel)
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-form-text-area{
  border: 6px solid #f2f2f2;
  padding: 15px 30px;
  border-radius: 32px;
  &-title{
    color: rgba(30, 39, 47, .51);
    &-black{
      margin-top: 3px;
      color: #1e272f;
    }
  }
  &-input{
    margin-top: 4px;
    textarea{
      resize: none;
      height: 200px;
      width: 100%;
      padding: 0;
      border: none;
      outline: none;
    }
  }
}
</style>
