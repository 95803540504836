<template>
  <div class="ui-input-code">
    <input
      class="ui-input-code-slot"
      type="number"
      v-for="i in codeLength"
      @input="changeInput"
      :key="i"
    />
  </div>
</template>
<script>
export default {
  name: "UiInputCode",
  props: {
    codeLength: {
      type: Number,
      default: () => 4
    }
  },
  methods: {
    changeInput(e){
      const input = e.target.value
      if(String(input).split('').length > 1){
        e.target.value = String(input).split('', 1).join('')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.ui-input-code{
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: center;
  background: #fff;
  border-radius: $border-radius;/* Rectangle 21 */
  width: 456px;
  height: 121px;
  box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.17);
  &-slot{
    border-radius: 8px;
    background: #ededed;
    width: 69px;
    height: 79px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border:none;
    text-align: center;
    font-size: 32px;
  }
}
</style>
