export default{
  state: {
    notificationIsOpen: false,
    notification: {
      name: '',
      autoClose: false
    }
  },
  getters: {
    getNotification: state => state.notification,
    getNotificationState: state => state.notificationIsOpen
  },
  mutations: {
    SET_NOTIFICATION_IS_OPEN(state, value){
      state.notificationIsOpen = value;
    },
    SET_NOTIFICATION(state, value) {
      state.notification = value
    }
  },
  actions: {
    setNotificationIsOpen: ({ commit }, value) => {
      commit("SET_NOTIFICATION_IS_OPEN", value);
    },
    setNotification({commit, dispatch}, value){
      if (value.autoClose) {
        setTimeout(() => {
          dispatch('setNotificationIsOpen', false)
        }, 2000)
      }
      commit("SET_NOTIFICATION", value)
      dispatch("setNotificationIsOpen", true)
    }
  }
}
