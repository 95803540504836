<template>
  <div class="modal-deposit-first-step">
    <div class="modal-title" v-html="title"/>
    <div class="modal-white-input modal-deposit-input">
      <div class="modal-deposit-input-text">
        CZK
      </div>
      <input type="number" v-model="amount"/>
    </div>
    <div class="modal-primary-btn modal-deposit-btn" @click="$emit('deposit', amount)">
      Пополнить
    </div>
  </div>
</template>
<script>
export default {
  name: "DepositFirst",
  props: {
    title: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return{
      amount: 500
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-deposit{
  &-first-step{
    padding: 68px;
  }
  &-input{
    margin-top: 41px;
    display: flex;
    font-size: 28px;
    align-items: center;
    justify-content: center;
    width: 456px;
    &-text{
      margin-left: 50px;
    }
    input{
      width: 100px;
      margin-left: 10px;
      outline: none;
      border: none;
    }
  }
  &-btn{
    width: 221px;
    margin: 39px auto 0;
  }
}
</style>
