<template>
  <div class="create-task-step2">
    <div class="create-task-container-title">
      Когда вам нужна помощь?
    </div>
    <div class="create-task-container-description">
      Укажите точную дату или период, когда нужно приступить к работе
    </div>
    <div class="create-task-step2-container">
      <DatePicker class="create-task-step2-container-datepicker" :model-value="date" @update:modelValue="setDate" :invalid="invalid"/>
      <div v-if="date[0] && !date[1]">
        <UiInput class="create-task-step2-container-time" label="Время" placeholder="ЧЧ:ММ" time/>
      </div>
    </div>
  </div>
</template>
<script>

import DatePicker from "@/components/DatePicker.vue";
import UiInput from "@/components/Ui/UiInput.vue";

export default {
  name: "CreateTaskStep2",
  components: {UiInput, DatePicker},
  data () {
    return {
      date: [],
      invalid: false
    }
  },
  methods: {
    nextStep(){
      if (!this.date) {
        this.invalid = true
        return false
      }
      this.invalid = false
      return true
    },
    setDate(value) {
      this.date = value
      if (this.date) {
        this.$emit('validate', false)
      }
    }
  }
}
</script>
<style lang="scss">
.create-task-step2{
  &-container {
    margin-top: 41px;
    padding-bottom: 178px;
    display: flex;
    align-items: center;
    &-datepicker{
      width: 139px;
    }
    &-time{
      width: 199px;
      height: 66px;
      margin-left: 15px;
      .ui-input-label{
        margin-left: 19px;
        min-width: 56px;
      }
      .ui-input-input{
        margin-left: 0;
      }
    }
  }
}
</style>
