<template>
  <div class="sitemap">
    <div class="container-banners">
      <div class="sitemap-header container">
        <div class="title">
          Карта сайта
        </div>
        <div class="description">
          Наши специалисты готовы помочь вам в решении самых разнообразных задач
        </div>
      </div>
      <div class="container-banners container-banners-no-margin">
        <CategoriesContent :items="items"/>
      </div>
    </div>
  </div>
</template>
<script>
import CategoriesContent from "@/components/Categories/CategoriesContent.vue";
export default {
  name: "SiteMap",
  data () {
   return {
      items: [
        {
          "id": 1,
          "name": "Страницы",
          "icon": "Notes",
          "subcategories": [
            {
              "id":  1,
              "name": "Главная страница",
              "link": "/"
            },
            {
              "id": 2,
              "name": "Категории услуг",
              "link": "/"
            },
            {
              "id": 3,
              "name": "Категории услуг",
              "link": "/"
            },
            {
              "id": 4,
              "name": "Блог",
              "link": "/"
            },
            {
              "id": 5,
              "name": "Политика конфиденциальности",
              "link": "/"
            },
            {
              "id": 6,
              "name": "Публичная оферта",
              "link": "/"
            },
            {
              "id": 7,
              "name": "Контакты",
              "link": "/"
            }
          ]
        },
        {
          "id": 2,
          "name": "Категории заданий",
          "icon": "RotatedNotes",
          "subcategories": [
            {
              "id":  1,
              "name": "Все категории",
              "link": "/"
            },
            {
              "id": 2,
              "name": "Клининговые услуги",
              "link": "/"
            },
            {
              "id": 3,
              "name": "Няни и сиделки",
              "link": "/"
            },
            {
              "id": 4,
              "name": "Уход за животными",
              "link": "/"
            },
            {
              "id": 5,
              "name": "Домашний ремонт",
              "link": "/"
            },
            {
              "id": 6,
              "name": "Курьерские услуги",
              "link": "/"
            },
            {
              "id": 7,
              "name": "Грузоперевозки",
              "link": "/"
            },
            {
              "id": 8,
              "name": "Пассажирские перевозки",
              "link": "/"
            },
            {
              "id": 9,
              "name": "Обучение и Репетиторы",
              "link": "/"
            },
            {
              "id": 10,
              "name": "Помощь с документами",
              "link": "/"
            },
            {
              "id": 11,
              "name": "Риэлторские услуги",
              "link": "/"
            },
            {
              "id": 12,
              "name": "Другое",
              "link": "/"
            }
          ]
        },
        {
          "id": 3,
          "name": "Категории исполнителей",
          "icon": "RotatedCards",
          "subcategories": [
            {
              "id":  1,
              "name": "Все категории",
              "link": "/"
            },
            {
              "id": 2,
              "name": "Клининговые услуги",
              "link": "/"
            },
            {
              "id": 3,
              "name": "Няни и сиделки",
              "link": "/"
            },
            {
              "id": 4,
              "name": "Уход за животными",
              "link": "/"
            },
            {
              "id": 5,
              "name": "Домашний ремонт",
              "link": "/"
            },
            {
              "id": 6,
              "name": "Курьерские услуги",
              "link": "/"
            },
            {
              "id": 7,
              "name": "Грузоперевозки",
              "link": "/"
            },
            {
              "id": 8,
              "name": "Пассажирские перевозки",
              "link": "/"
            },
            {
              "id": 9,
              "name": "Обучение и Репетиторы",
              "link": "/"
            },
            {
              "id": 10,
              "name": "Помощь с документами",
              "link": "/"
            },
            {
              "id": 11,
              "name": "Риэлторские услуги",
              "link": "/"
            },
            {
              "id": 12,
              "name": "Другое",
              "link": "/"
            }
          ]
        },
      ]
   }
  },
  components: {
    CategoriesContent
  }
}
</script>
<style lang="scss">
.sitemap{
  .container-banners{
    padding-left: 0;
    padding-right: 0;
    &-no-margin{
      margin-left: 0;
      margin-right: 0;
      border-bottom: none;
    }
  }
  &-header{
    .title{
      font-size: 42px;
    }
    .description{
      margin-top: 10px;
      font-weight: 300;
    }
  }
}
</style>
