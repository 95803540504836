<template>
  <div class="tips-component">
    <div class="tips-component-item">
      <MiniLogo/>
      <div class="tips-component-item-right tips-component-item-right-logo">
        Полезные советы <br>
        от GetJob
      </div>
    </div>
    <div v-if="!mapStep" class="tips-component-default" >
      <div class="tips-component-item">
        <div class="tips-component-item-left tips-component-item-left-blue">
          <div>
            <span class="tips-component-item-left-head">5</span> <br>
            hours
          </div>
        </div>
        <div class="tips-component-item-right">
          Cреднее время, которое уходит на выполнение заявки по данной категории.
        </div>
      </div>
      <div class="tips-component-item">
        <div class="tips-component-item-left tips-component-item-left-orange">
          <svg width="33" height="39" viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 0.461426L0 7.3845V17.7691C0 27.3749 7.04 36.3576 16.5 38.5383C25.96 36.3576 33 27.3749 33 17.7691V7.3845L16.5 0.461426Z" fill="#FF6B17"/>
            <path d="M16.7594 21.9219C16.133 21.9207 15.513 21.804 14.9349 21.5787C14.3567 21.3533 13.8317 21.0237 13.3897 20.6085C12.4972 19.7701 11.9973 18.6344 12 17.4513C12.0028 16.2682 12.5079 15.1345 13.4043 14.2998C14.3008 13.465 15.515 12.9974 16.78 13C19.436 13.0045 21.541 15.003 21.5378 17.5175C21.529 19.939 19.3827 21.9234 16.7594 21.9219ZM19.6689 17.4573C19.6674 16.9197 19.4955 16.3946 19.1748 15.9486C18.8541 15.5025 18.3991 15.1554 17.8674 14.9513C17.3357 14.7473 16.7511 14.6954 16.1879 14.8022C15.6246 14.909 15.1078 15.1698 14.7031 15.5514C14.2984 15.9331 14.0238 16.4185 13.9143 16.9462C13.8047 17.4739 13.865 18.0201 14.0876 18.5157C14.3101 19.0113 14.6849 19.434 15.1645 19.7303C15.6441 20.0265 16.2069 20.183 16.7816 20.1799C17.1624 20.1792 17.5394 20.108 17.8907 19.9706C18.242 19.8332 18.5609 19.6323 18.829 19.3793C19.097 19.1263 19.309 18.8262 19.4527 18.4964C19.5965 18.1666 19.6691 17.8134 19.6665 17.4573H19.6689Z" fill="white"/>
            <path d="M21.4892 20.887L22.7556 21.5219C21.0258 24.658 16.6003 25.6706 13.5 23.7093L14.2949 22.5873C15.5335 23.3063 16.861 23.5368 18.2737 23.2067C19.6863 22.8766 20.7483 22.0877 21.4892 20.887Z" fill="white"/>
          </svg>
        </div>
        <div class="tips-component-item-right">
          Оплачивайте безопасно онлайн после того
          как задание выполнено
        </div>
      </div>
      <div class="tips-component-item">
        <div class="tips-component-item-left tips-component-item-left-green">
          <div>
            <span class="tips-component-item-left-head">750</span> <br>
            czk
          </div>
        </div>
        <div class="tips-component-item-right">
          Cредняя стоимость заявки
          по данной категории
        </div>
      </div>
    </div>
    <div v-else class="tips-component-default">
      <div class="tips-component-item">
        <div class="tips-component-item-left tips-component-item-left-sand">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 11.4856C8.5 6.70709 12.3056 2.83334 17 2.83334C21.6944 2.83334 25.5 6.70709 25.5 11.4856C25.5 16.2267 22.7871 21.759 18.5544 23.7374C17.5676 24.1986 16.4324 24.1986 15.4456 23.7374C11.2129 21.759 8.5 16.2267 8.5 11.4856ZM17 14.1667C18.5648 14.1667 19.8333 12.8982 19.8333 11.3333C19.8333 9.76854 18.5648 8.50001 17 8.50001C15.4352 8.50001 14.1667 9.76854 14.1667 11.3333C14.1667 12.8982 15.4352 14.1667 17 14.1667Z" fill="#C5B595"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13935 20.5902C5.50376 21.0501 5.42633 21.7184 4.96639 22.0828C4.15371 22.7267 3.89648 23.2943 3.89648 23.7292C3.89648 24.0734 4.05272 24.4914 4.52073 24.9753C4.99353 25.4641 5.73223 25.9607 6.7377 26.4167C8.51454 27.2226 10.9706 27.8327 13.8132 28.0986V27.4479C13.8132 27.031 14.057 26.6527 14.4366 26.4804C14.8163 26.3081 15.2616 26.3738 15.5753 26.6483L17.7003 28.5077C17.9309 28.7094 18.0632 29.0009 18.0632 29.3073C18.0632 29.6137 17.9309 29.9052 17.7003 30.1069L15.5753 31.9663C15.2616 32.2408 14.8163 32.3065 14.4366 32.1342C14.057 31.962 13.8132 31.5836 13.8132 31.1667V30.2322C10.7179 29.9607 7.94711 29.2986 5.85997 28.3519C4.70701 27.829 3.7133 27.1971 2.99327 26.4526C2.26847 25.7032 1.77148 24.7832 1.77148 23.7292C1.77148 22.383 2.57426 21.267 3.64673 20.4172C4.10666 20.0528 4.77493 20.1302 5.13935 20.5902ZM28.862 20.5902C29.2264 20.1302 29.8946 20.0528 30.3546 20.4172C31.427 21.267 32.2298 22.383 32.2298 23.7292C32.2298 25.6815 30.5751 27.1442 28.6221 28.1228C26.5797 29.1462 23.7879 29.8706 20.6494 30.1887C20.0656 30.2478 19.5444 29.8225 19.4852 29.2387C19.4261 28.6549 19.8514 28.1336 20.4352 28.0745C23.4069 27.7733 25.9276 27.0961 27.6701 26.2229C29.5022 25.3049 30.1048 24.3761 30.1048 23.7292C30.1048 23.2943 29.8476 22.7267 29.0349 22.0828C28.575 21.7184 28.4975 21.0501 28.862 20.5902Z" fill="#C5B595"/>
          </svg>
        </div>
        <div class="tips-component-item-right">
          У нас есть функция <strong>"геопозиция"</strong> которая позволит вам найти исполнителей в заданном вами радиусе
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TipsComponent",
  props: {
    mapStep: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.tips-component{
  border: 1px solid rgba(30, 39, 47, 0.17);
  border-radius: $border-radius-medium;
  padding: 20px;
  margin-left: auto;
  &-default{
    margin-top: 15px;
  }
  &-logo{
    width: 41px;
    height: 34px;
  }
  &-item{
    display: flex;
    align-items: center;
    gap:13px;
    &-left{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      min-width: 70px;
      height: 70px;
      border-radius: 17px;
      text-align: center;
      &-logo{
        border: 1px solid #D9D9D9;
      }
      &-blue{
        color: #287EFF;
        background: rgba(40, 126, 255, 0.3);
        font-size: 12px;
      }
      &-green{
        color: #3CB055;
        font-size: 12px;
        background: rgba(60, 176, 85, 0.25);
      }
      &-head{
        font-size: 22px;
        font-weight: 500;
      }
      &-orange{
        background: rgba(255, 107, 23, 0.37);
      }
      &-sand{
        background: rgba(246, 219, 166, .5);
      }
    }
    &-right{
      font-weight: 300;
      &-logo{
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  &-item:not(:last-child){
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.32);
  }
  &-item:not(:first-child){
    padding-top: 15px;
  }
}
</style>
<script setup>
import MiniLogo from "@/components/icons/MiniLogo.vue";
</script>
