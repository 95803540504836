<template>
  <div class="create-task-step1">
    <div class="create-task-container-title">
      Укажите название задания
    </div>
    <UiFormInput placeholder="Например, уборка квартиры*..." @validate="setDanger(false)" ref="uiForm" :model-value="taskTitle" @update:modelValue="setTaskTitle" danger-text="Укажите название задания"/>
    <div class="create-task-categories">
      <DropdownComponent class="create-task-step1-categories" position="bottom" ref="dropdownCategories">
        <template #icon>
          <UiInput :multi-select="true" label="Категория" :placeholder="category.name ? category.name : 'Выберите категорию'" @update:modelValue="search" :model="searchInput"/>
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="dropicon">
            <path d="M4.62265 4.62341L-0.000789093 -2.7325e-05L9.24609 -2.81334e-05L4.62265 4.62341Z" fill="#1E272F"/>
          </svg>
        </template>
        <template #list>
          <div class="header-city-list-container">
            <div class="header-city-search">
              <div class="header-city-search-container">
                <input placeholder="Поиск по категориям"/>
                <div class="header-city-search-icon">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="header-city-list">
              <div
                :class="['header-city-list-item', {'header-city-list-item-active': category.id === cat.id}]"
                v-for="cat in categories"
                :key="cat.id"
                @click="setCategory(cat)"
              >
                {{ cat.name }}
              </div>
            </div>
          </div>
        </template>
      </DropdownComponent>
      <DropdownComponent class="create-task-step1-subcategories" position="bottom" ref="dropdownSubcategories" :disabled="category.id === 0">
        <template #icon>
          <UiInput :multi-select="true" label="Подкатегория" :placeholder="subCategory.name ? subCategory.name : 'Выберите подкатегорию'"/>
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="dropicon">
            <path d="M4.62265 4.62341L-0.000789093 -2.7325e-05L9.24609 -2.81334e-05L4.62265 4.62341Z" fill="#1E272F"/>
          </svg>
        </template>
        <template #list>
          <div class="header-city-list-container">
            <div class="header-city-search">
              <div class="header-city-search-container">
                <input placeholder="Поиск по подкатегориям"/>
                <div class="header-city-search-icon">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="header-city-list" v-if="category.id !== 0">
              <div
                :class="['header-city-list-item', {'header-city-list-item-active': subCategory.id === subcat.id}]"
                v-for="subcat in category.subcategories"
                :key="subcat.id"
                @click="setCategory(subcat, 'subcategories')"
              >
                {{ subcat.name }}
              </div>
            </div>
          </div>
        </template>
      </DropdownComponent>
    </div>
  </div>
</template>
<script>
import DropdownComponent from "@/components/Dropdown.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";

import Categories from '../Categories.json'
export default {
  name: 'CreateTaskStep1',
  components: {
    DropdownComponent,
    UiInput,
    UiFormInput
  },
  data() {
    return {
      category: {
        id: 0,
        name: ''
      },
      subCategory: {
        id: 0,
        name: ''
      },
      searchInput: '',
      categories: Categories,
      taskTitle: ''
    }
  },
  methods: {
    setDanger(value){
      this.$emit('validate', value)
    },
    setCategory(value, type = 'categories') {
      this.searchInput = ''
      if (type === 'subcategories'){
        this.subCategory = value
        this.$refs.dropdownSubcategories.closeDropdown()
        return
      }
      this.category = value
      this.subCategory = {
        id: 0,
        name: ""
      }
      this.$refs.dropdownCategories.closeDropdown()
    },
    nextStep(){
      if (this.taskTitle.trim().length === 0){
        this.$refs.uiForm.setDanger(true)
        return false
      }
      return true
    },
    setTaskTitle(value){
      this.taskTitle = value
    },
    search (value) {
      this.categories = categories.filter(el => {
        if(el['name'].indexOf(this.searchInput) !== -1){
          return el
        }
      })
    }
  }
}
</script>
<style lang="scss">
.create-task-step1{
  padding-bottom: 225px;
  &-categories{
    width: 360px;
    .ui-input{
      width: 360px;
      &-input-multiselect{
        max-width: 187px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  &-subcategories{
    width: 392px;
    .ui-input{
      width: 392px;
      &-input-multiselect{
        max-width: 187px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
