<template>
  <div class="create-task container-banners">
    <div class="container" >
      <div v-if="step === 0" class="create-task-header">
        <MiniLogo/>
        <div class="create-task-header-text">
          <div class="title">
            Быстро найдём подходящих исполнителей
          </div>
          <div class="create-task-header-text-desc">
            <div class="create-task-header-text-desc-text">
              Осталось только заполнить детали задания.
            </div>
            <div class="create-task-header-time">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.5C4.41 1.5 1.5 4.41 1.5 8C1.5 11.59 4.41 14.5 8 14.5C11.59 14.5 14.5 11.59 14.5 8C14.5 4.41 11.59 1.5 8 1.5ZM5.42719 5.07312L8.70844 7.29187C8.92503 7.44787 9.07151 7.68287 9.11618 7.94603C9.16086 8.20919 9.10013 8.47936 8.94715 8.69809C8.79417 8.91683 8.56122 9.06654 8.29871 9.11485C8.03619 9.16317 7.76521 9.10619 7.54437 8.95625C7.44736 8.88783 7.3628 8.80327 7.29437 8.70625L5.07562 5.425C5.04147 5.37607 5.02563 5.31669 5.03087 5.25725C5.03611 5.19781 5.06211 5.14212 5.1043 5.09993C5.14649 5.05773 5.20219 5.03174 5.26163 5.0265C5.32106 5.02125 5.38045 5.0371 5.42938 5.07125L5.42719 5.07312ZM8 13.5C4.96875 13.5 2.5 11.0328 2.5 8C2.49742 7.26028 2.64533 6.52776 2.93474 5.84699C3.22414 5.16622 3.64898 4.55143 4.18344 4.04C4.225 3.99856 4.2744 3.96582 4.32875 3.94367C4.3831 3.92152 4.44132 3.91041 4.5 3.91099C4.55869 3.91157 4.61668 3.92383 4.67058 3.94705C4.72448 3.97027 4.77322 4.00399 4.81396 4.04624C4.85469 4.08849 4.88661 4.13842 4.90785 4.19314C4.92909 4.24785 4.93922 4.30624 4.93766 4.36491C4.9361 4.42358 4.92288 4.48136 4.89876 4.53486C4.87464 4.58837 4.84011 4.63654 4.79719 4.67656C4.34871 5.10582 3.99219 5.6218 3.74932 6.19312C3.50644 6.76445 3.38228 7.3792 3.38438 8C3.38438 10.545 5.455 12.6156 8 12.6156C10.545 12.6156 12.6156 10.545 12.6156 8C12.6156 5.60406 10.7806 3.62875 8.44219 3.40625V5.125C8.44219 5.24228 8.3956 5.35475 8.31267 5.43767C8.22975 5.5206 8.11728 5.56719 8 5.56719C7.88272 5.56719 7.77025 5.5206 7.68733 5.43767C7.6044 5.35475 7.55781 5.24228 7.55781 5.125V2.94219C7.55781 2.82491 7.6044 2.71244 7.68733 2.62951C7.77025 2.54659 7.88272 2.5 8 2.5C11.0328 2.5 13.5 4.96875 13.5 8C13.5 11.0312 11.0328 13.5 8 13.5Z" fill="#1E272F"/>
              </svg>
              <span>
                Это займёт 3 минуты.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="create-task-header">
        <TaskSteps :step-count="stepComponents.length" :current-step="step + 1" :done="step + 1 === stepComponents.length"/>
      </div>
    </div>
    <div class="create-task-content">
      <div class="container create-task-container">
        <component :is="stepComponents[step]" ref="component" @validate="setValidate"/>
        <div class="create-task-actions">
          <div v-if="step > 0" class="btn btn-secondary" @click="backStep" >
            Назад
          </div>
          <div v-if="step + 1 !== stepComponents.length" :class="['btn-orange', {'btn-orange-disabled': invalid }]" @click="nextStep">
            Далее
          </div>
          <div v-else :class="['btn-orange', {'btn-orange-disabled': invalid, 'create-task-last-step': step + 1 === stepComponents.length}]" @click="lastStep">
            Разместить задание
          </div>
          <div v-if="step + 1 === stepComponents.length" class="create-task-actions-text">
            Нажимая «Разместить», вы принимаете правила сервиса
          </div>
          <div class="create-task-actions-executors" v-if="step === 0">
            <div class="create-task-actions-executors-avatars">
              <AvatarComponent/>
              <AvatarComponent/>
              <AvatarComponent/>
            </div>
            <div class="create-task-actions-executors-text">
              2310 исполнителей могут вам помочь
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CreateTaskStep1 from "@/components/Tasks/CreateTask/CreateTaskStep1.vue";
import CreateTaskStep2 from "@/components/Tasks/CreateTask/CreateTaskStep2.vue";
import CreateTaskStep3 from "@/components/Tasks/CreateTask/CreateTaskStep3.vue";
import CreateTaskStep4 from "@/components/Tasks/CreateTask/CreateTaskStep4.vue";
import CreateTaskStep5 from "@/components/Tasks/CreateTask/CreateTaskStep5.vue";
import AvatarComponent from "@/components/Avatar.vue";
import TaskSteps from "@/components/Tasks/CreateTask/TaskSteps.vue";
import MiniLogo from "@/components/icons/MiniLogo.vue";

export default {
  name: "CreateTask",
  components: {
    MiniLogo,
    TaskSteps, AvatarComponent, CreateTaskStep1, CreateTaskStep2, CreateTaskStep3, CreateTaskStep4, CreateTaskStep5},
  data () {
    return {
      step: 0,
      invalid: false,
      stepComponents: [
        'create-task-step1',
        'create-task-step2',
        'create-task-step3',
        'create-task-step4',
        'create-task-step5'
      ],
      finished: false
    }
  },
  methods: {
    nextStep () {
      if (this.invalid) {
        return
      }
      if (this.$refs.component.nextStep()) {
        this.step++
      } else {
        this.invalid = true
      }
    },
    backStep () {
      if (this.step > 0) {
        this.invalid = false
        this.step --
      }
    },
    setValidate(value) {
      this.invalid = value
    },
    lastStep(){
      this.$store.dispatch("setModal",{ modalName: 'PublishedTask'})
      setTimeout(() => {
        this.$store.dispatch('setIsOpen', false)
        this.finished = true
        this.$router.push('/task/1')
      }, 3000)
    }
  },
  beforeRouteLeave(to, from, next){
      if (this.step === 0 || this.finished){
        next()
        return
      }
      this.$store.dispatch(
        'setModal',
        {
          modalName: 'LeaveCreateTaskModal',
          callBack: () => {
            this.$store.dispatch('setIsOpen', false)
            next()
          }
        }
      )
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.create-task{
  padding-left: 0;
  padding-right: 0;
  &-header{
    display: flex;
    align-items: center;
    margin-top: 15px;
    &-img{
      width: 70px;
      height: 70px;
      border: 1px solid #D9D9D9;
      border-radius: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 41px;
        height: 34px;
      }
    }
    &-time{
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &-text{
      margin-left: 20px;
      &-desc{
        margin-top: 7px;
        display: flex;
        align-items: center;
        &-text{
          font-weight: 300;
        }
        span{
          font-weight: 500;
        }
      }
    }
  }
  &-content{
    border: 1px solid rgba(30, 39, 47, 0.17);
    border-bottom: none;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    margin-top: 51px;
    .ui-form-input{
      height: 60px;
      margin-top: 25px;
      width: 754px;
      font-size: 14px;
    }
  }
  &-container{
    padding: 34px 34px 34px 0;
    &-title{
      font-size: 24px;
      font-weight: 500;
    }
    &-description{
      font-size: 22px;
      font-weight: 300;
      margin-top: 19px;
    }
  }
  &-categories{
    display: flex;
    align-items: center;
    margin-top: 19px;
    gap: 8px;
    .dropicon{
      position: absolute;
      right: 20px;
      top:33px;
    }
    .header-city-search-container{
      width: 325px;
      input{
        width: 85%;
      }
    }
    .dropdown-list-bottom{
      left: 30%;
      width: 358px;
      .header-city-list{
        width: 94%;
      }
    }

  }
  &-actions{
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 12px;
    .btn-orange{
      width: 202px;
      height: 98px;
      font-size: 22px;
      &-disabled{
        cursor: not-allowed;
        background: #D9D9D9;
      }
    }
    .btn-secondary{
      width: 202px;
      font-size: 22px;
      height: 98px;
    }
    &-executors{
      margin-left: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      &-avatars{
        display: flex;
        align-items: center;
        .avatar-component-container:not(:first-child){
          margin-left: -15px;
        }
      }
      &-text{
        font-size: 22px;
        font-weight: 300;
      }
    }
    &-text{
      align-items: center;
      font-size: 14px;
      height: 14px;
      font-weight: 300;
    }
  }
  &-last-step{
    width: 278px!important;
  }
}
</style>
