<template>
  <div class="how-it-works-card">
    <div class="how-it-works-card-title">{{title}}</div>
    <div class="how-it-works-card-description">{{description}}</div>
    <div :class="['how-it-works-card-img', {'mt41': src === 'hiw1.png' || src === 'hiw5.png', 'hiw6': src === 'hiw6.png', 'hiw2' : src === 'hiw2.png'}]"><img :src="getSrc"/></div>
  </div>
</template>
<script>
export default {
  name: "HowItWorksCard",
  props: {
    src: {
      type: String,
      default: ()=>"hiw1.png"
    },
    title: {
      type: String,
      default: ()=>""
    },
    description: {
      type: String,
      default: ()=>""
    }
  },
  computed: {
    getSrc(){
      return require('@/assets/img/' + this.src);
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.mt41{
  margin-top: 41px;
}
.how-it-works-card{
  border: 1px solid #1E272F2B;
  border-radius: $border-radius-medium;
  width: 360px;
  padding: 41px 0 51px 0;
  height: 458px;
  &-title{
    font-size: 20px;
    font-weight: 500;
    width: 195px;
    margin-left: 45px;
  }
  &-description{
    font-weight: 300;
    margin-top: 14px;
    width: 280px;
    margin-left: 45px;
  }
  &-img{
    //width: 268px;
    height: 294px;
    width: 360px;
    img{
      margin: 0 auto;
      display: block;

    }
  }
  .hiw2{
    img{
      margin-left: 25px;
    }
  }
  .hiw6{
    img{
      width: 268px;
      height: 260px;
    }
  }
}
</style>
