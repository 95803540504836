<template>
  <div class="registration-step3">
    <div class="registration-step3-container">
      <UiSwitcherType/>
      <UiInput label="Имя*" placeholder="Введите имя"/>
      <UiInput label="Фамилия*" placeholder="Введите фамилию"/>
      <UiInput label="Email*" placeholder="Введите email"/>
      <DropdownComponent class="registration-step3-dropdown" position="bottom" ref="dropdownCategories">
        <template #icon>
          <UiInput label="Город*" multi-select :placeholder="activeCity.city ? activeCity.city : 'Выберите город'"/>
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="dropicon">
            <path d="M4.62265 4.62341L-0.000789093 -2.7325e-05L9.24609 -2.81334e-05L4.62265 4.62341Z" fill="#1E272F"/>
          </svg>
        </template>
        <template #list>
          <div class="header-city-list-container">
            <div class="header-city-search">
              <div class="header-city-search-container">
                <input placeholder="Поиск по категориям" @input="search" v-model="searchInput"/>
                <div class="header-city-search-icon">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="header-city-list">
              <div
                :class="['header-city-list-item', {'header-city-list-item-active': activeCity.city === city.city}]"
                v-for="city in cities"
                :key="city.city"
                @click="setCity(city)"
              >
                {{ city.city }}
              </div>
            </div>
          </div>
        </template>
      </DropdownComponent>
      <div class="btn-primary" @click="$emit('nextStep')">
        Далее
        <svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.5646 8.94928L34.5633 8.94787L26.8071 1.22912C26.2261 0.650882 25.2862 0.653034 24.7079 1.23417C24.1296 1.81523 24.1318 2.75506 24.7128 3.33337L29.9203 8.51562H-1.51562C-2.33545 8.51562 -3 9.18017 -3 10C-3 10.8198 -2.33545 11.4844 -1.51562 11.4844H29.9202L24.7129 16.6666C24.1319 17.2449 24.1296 18.1848 24.7079 18.7658C25.2863 19.347 26.2262 19.349 26.8072 18.7709L34.5634 11.0521L34.5647 11.0507C35.1461 10.4705 35.1442 9.52759 34.5646 8.94928Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import UiSwitcherType from "@/components/Ui/UiSwitcherType.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import DropdownComponent from "@/components/Dropdown.vue";
import czcities from "@/components/Header/cz.json";
export default {
  name:'RegistrationStep3',
  components: {DropdownComponent, UiInput, UiSwitcherType},
  data () {
    return {
      cities: czcities,
      activeCity: {},
      searchInput: ''
    }
  },
  methods: {
    setCity(value){
      this.activeCity = value
    },
    search(){
      this.cities = czcities.filter(el => {
        if(el['city'].toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1){
          return el
        }
      })
    },
  }
}
</script>
<style lang="scss">
.registration-step3{
  padding: 43px 75px 56px 92px;
  &-container{
    margin: 0 auto;
  }
  .ui-switcher-type{
    margin: 0 auto;
  }
  .ui-input{
    width: 415px;
    margin-top: 19px;
  }
  .dropicon{
    position: absolute;
    right: 25px;
    top: 52px;
  }
  &-dropdown{
    width: 425px;
  }
  .dropdown-list{
    left: 0;
  }
  .btn-primary{
    margin: 46px auto 0;
    width: 221px;
    height: 98px;
    display: flex;
    font-size: 22px;
    align-items: center;
    gap: 19px;
  }
}
</style>
