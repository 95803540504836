<template>
  <div class="default-layout">
    <HeaderComponent/>
    <div class="default-layout-content container-bordered-full">
      <div class="df container profile-container">
        <SidebarComponent/>
        <router-view class="profile-content"/>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import SidebarComponent from "@/components/Profile/Sidebar.vue";

export default {
  name: "DefaultLayout",
  components: {SidebarComponent, FooterComponent, HeaderComponent}
}
</script>
<style lang="scss" scoped>
.profile-content{
  width: 700px;
  padding-top: 50px;
  padding-left: 100px;
}
.profile-container{
  min-height: 800px;
}
</style>
