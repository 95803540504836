<template>
  <div class="profile-view-component container">
    <div class="profile-view-component-avatar">
      <div class="profile-view-component-avatar-img">
        <img src="@/assets/img/profileavatar.png" alt="">
      </div>
      <div class="profile-view-component-stars">
        <div class="profile-view-component-stars-text">
          Общая оценка
        </div>
        <div class="profile-view-component-stars-container">
          <StarsComponent/>
          <div class="profile-view-component-stars-value">4.8</div>
        </div>
      </div>
      <div class="profile-view-component-offer" v-if="isExecutor">
        <div class="btn-orange" @click="offerTask">
          Предложить задание
        </div>
      </div>
    </div>
    <div class="profile-view-component-info-block">
      <ProfileInfo :is-executor="isExecutor" :is-view="true"/>
      <ProfileReviews :is-executor="isExecutor"/>
      <div v-if="!isExecutor" class="profile-view-component-footer">
        <h2>
          Хотите начать зарабатывать?
        </h2>
        <div class="btn-orange" >
          Стать исполнителем
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarsComponent from "@/components/Profile/Stars.vue";
import ProfileInfo from "@/components/Profile/ProfileIndex/ProfileInfo.vue";
import ProfileReviews from "@/components/Profile/ProfileIndex/ProfileReviews/ProfileReviews.vue";

export default {
  name: "ProfileViewComponent",
  props: {
    isExecutor: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      onceOpened: false
    }
  },
  components: {
    StarsComponent,
    ProfileInfo,
    ProfileReviews
  },
  methods: {
    offerTask(){
      if (!this.onceOpened){
        this.$store.dispatch('setModal', {
          modalName: "ModalOnlyExecutors",
          customClose: true,
          props: {
            title: "У вас пока нет опубликованных заданий",
            description: "Создайте задание, после чего вы сможете предложить выполнить его исполнителям.",
            confirmBtn: "Создать задание"
          }
        })
        this.onceOpened = true
      } else {
        this.$store.dispatch('setModal', {
          modalName: "ModalOnlyExecutors",
          customClose: true,
          props: {
            title: "Предложить задание",
            description: "Выберите задание, которое хотите предложить исполнителю",
            confirmBtn: "Предложить",
            haveTasks: true
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileViewComponent.scss";
</style>
