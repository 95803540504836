<template>
  <div class="profile-security">
    <div class="profile-security-title">
      Придумайте безопасный пароль со следующими требованиями:
    </div>
    <div class="profile-security-desc">
      <div class="profile-security-desc-icon">
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="1.26929" width="31.0526" height="30.2307" rx="6.5" fill="white" stroke="#287EFF"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.64641 14.0698V12.1167C9.64641 8.57301 12.5027 5.70027 16.0261 5.70027C19.5495 5.70027 22.4058 8.57301 22.4058 12.1167V14.0698C23.4594 14.1489 24.1455 14.3487 24.647 14.8532C25.4775 15.6884 25.4775 17.0327 25.4775 19.7214C25.4775 22.4101 25.4775 23.7544 24.647 24.5897C23.8166 25.4249 22.4799 25.4249 19.8067 25.4249H12.2455C9.57229 25.4249 8.23566 25.4249 7.40518 24.5897C6.57471 23.7544 6.57471 22.4101 6.57471 19.7214C6.57471 17.0327 6.57471 15.6884 7.40518 14.8532C7.9067 14.3487 8.59281 14.1489 9.64641 14.0698ZM11.0641 12.1167C11.0641 9.36051 13.2857 7.12615 16.0261 7.12615C18.7665 7.12615 20.9881 9.36051 20.9881 12.1167V14.0213C20.6261 14.0179 20.2334 14.0179 19.8067 14.0179H12.2455C11.8188 14.0179 11.4261 14.0179 11.0641 14.0213V12.1167ZM12.2455 20.672C12.7675 20.672 13.1907 20.2464 13.1907 19.7214C13.1907 19.1964 12.7675 18.7708 12.2455 18.7708C11.7236 18.7708 11.3004 19.1964 11.3004 19.7214C11.3004 20.2464 11.7236 20.672 12.2455 20.672ZM16.0261 20.672C16.5481 20.672 16.9712 20.2464 16.9712 19.7214C16.9712 19.1964 16.5481 18.7708 16.0261 18.7708C15.5041 18.7708 15.081 19.1964 15.081 19.7214C15.081 20.2464 15.5041 20.672 16.0261 20.672ZM20.7518 19.7214C20.7518 20.2464 20.3287 20.672 19.8067 20.672C19.2847 20.672 18.8615 20.2464 18.8615 19.7214C18.8615 19.1964 19.2847 18.7708 19.8067 18.7708C20.3287 18.7708 20.7518 19.1964 20.7518 19.7214Z" fill="#287EFF"/>
        </svg>
      </div>
      <div class="profile-security-desc-text">
        Длина пароля должна сосставлять не менее 8 символов, включать в себя
        заглавные и строчные буквы, цифры или специальные символы, например %, #, $
      </div>
    </div>
    <div class="profile-security-container">
      <div class="profile-security-input">
        <div class="profile-security-input-text">
          <div v-if="!invalid">
            Пароль:
          </div>
          <div v-else class="profile-security-input-text-danger">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4.20833C0.5 2.60954 0.5 1.81014 0.688758 1.5412C0.877516 1.27227 1.62916 1.01498 3.13246 0.500396L3.41886 0.402358C4.20249 0.134119 4.59431 0 5 0C5.40569 0 5.79751 0.13412 6.58114 0.402359L6.86755 0.500396C8.37084 1.01498 9.12248 1.27227 9.31124 1.5412C9.5 1.81014 9.5 2.60954 9.5 4.20833V4.99568C9.5 7.81472 7.38052 9.18274 6.05072 9.76364C5.68999 9.92121 5.50963 10 5 10C4.49037 10 4.31001 9.92121 3.94928 9.76364C2.61948 9.18274 0.5 7.81472 0.5 4.99568V4.20833ZM5 2.625C5.20711 2.625 5.375 2.79289 5.375 3V5C5.375 5.20711 5.20711 5.375 5 5.375C4.79289 5.375 4.625 5.20711 4.625 5V3C4.625 2.79289 4.79289 2.625 5 2.625ZM5 7C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6C4.72386 6 4.5 6.22386 4.5 6.5C4.5 6.77614 4.72386 7 5 7Z" fill="#F44236"/>
            </svg>
            Ваши пароли не совпадают!
          </div>
        </div>
        <div class="profile-security-input-input">
          <input :type="type1 === false ? 'password' : 'text'" placeholder="*********"/>
          <div :class="['profile-security-input-icon', { 'profile-security-input-icon-active': type1 === true}]" @click="type1 = !type1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 2.75C15.9068 2.75 17.2615 2.75159 18.2892 2.88976C19.2952 3.02503 19.8749 3.27869 20.2981 3.7019C20.7852 4.18904 20.9973 4.56666 21.1147 5.23984C21.2471 5.9986 21.25 7.08092 21.25 9C21.25 9.41422 21.5858 9.75 22 9.75C22.4142 9.75 22.75 9.41422 22.75 9L22.75 8.90369C22.7501 7.1045 22.7501 5.88571 22.5924 4.98199C22.417 3.97665 22.0432 3.32568 21.3588 2.64124C20.6104 1.89288 19.6615 1.56076 18.489 1.40314C17.3498 1.24997 15.8942 1.24998 14.0564 1.25H14C13.5858 1.25 13.25 1.58579 13.25 2C13.25 2.41421 13.5858 2.75 14 2.75Z"/>
              <path d="M2.00001 14.25C2.41422 14.25 2.75001 14.5858 2.75001 15C2.75001 16.9191 2.75289 18.0014 2.88529 18.7602C3.00275 19.4333 3.21477 19.811 3.70191 20.2981C4.12512 20.7213 4.70476 20.975 5.71085 21.1102C6.73852 21.2484 8.09318 21.25 10 21.25C10.4142 21.25 10.75 21.5858 10.75 22C10.75 22.4142 10.4142 22.75 10 22.75H9.94359C8.10583 22.75 6.6502 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64125 21.3588C1.95681 20.6743 1.58304 20.0233 1.40762 19.018C1.24992 18.1143 1.24995 16.8955 1.25 15.0964L1.25001 15C1.25001 14.5858 1.58579 14.25 2.00001 14.25Z"/>
              <path d="M22 14.25C22.4142 14.25 22.75 14.5858 22.75 15L22.75 15.0963C22.7501 16.8955 22.7501 18.1143 22.5924 19.018C22.417 20.0233 22.0432 20.6743 21.3588 21.3588C20.6104 22.1071 19.6615 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0564 22.75H14C13.5858 22.75 13.25 22.4142 13.25 22C13.25 21.5858 13.5858 21.25 14 21.25C15.9068 21.25 17.2615 21.2484 18.2892 21.1102C19.2952 20.975 19.8749 20.7213 20.2981 20.2981C20.7852 19.811 20.9973 19.4333 21.1147 18.7602C21.2471 18.0014 21.25 16.9191 21.25 15C21.25 14.5858 21.5858 14.25 22 14.25Z"/>
              <path d="M9.94359 1.25H10C10.4142 1.25 10.75 1.58579 10.75 2C10.75 2.41421 10.4142 2.75 10 2.75C8.09319 2.75 6.73852 2.75159 5.71085 2.88976C4.70476 3.02503 4.12512 3.27869 3.70191 3.7019C3.21477 4.18904 3.00275 4.56666 2.88529 5.23984C2.75289 5.9986 2.75001 7.08092 2.75001 9C2.75001 9.41422 2.41422 9.75 2.00001 9.75C1.58579 9.75 1.25001 9.41422 1.25001 9L1.25 8.90369C1.24995 7.10453 1.24992 5.8857 1.40762 4.98199C1.58304 3.97665 1.95681 3.32568 2.64125 2.64124C3.38961 1.89288 4.33856 1.56076 5.51098 1.40314C6.65019 1.24997 8.10584 1.24998 9.94359 1.25Z"/>
              <path d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75Z"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89243 14.0598C5.29748 13.3697 5.00001 13.0246 5.00001 12C5.00001 10.9754 5.29748 10.6303 5.89243 9.94021C7.08038 8.56223 9.07269 7 12 7C14.9273 7 16.9196 8.56222 18.1076 9.94021C18.7025 10.6303 19 10.9754 19 12C19 13.0246 18.7025 13.3697 18.1076 14.0598C16.9196 15.4378 14.9273 17 12 17C9.07269 17 7.08038 15.4378 5.89243 14.0598ZM9.25001 12C9.25001 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25001 13.5188 9.25001 12Z" />
            </svg>
          </div>
        </div>
        <div v-if="invalid" class="profile-security-input-danger">
        </div>
      </div>
      <div class="profile-security-input">
        <div class="profile-security-input-text">
          <div v-if="!invalid">
            Повторите пароль:
          </div>
          <div v-else class="profile-security-input-text-danger">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4.20833C0.5 2.60954 0.5 1.81014 0.688758 1.5412C0.877516 1.27227 1.62916 1.01498 3.13246 0.500396L3.41886 0.402358C4.20249 0.134119 4.59431 0 5 0C5.40569 0 5.79751 0.13412 6.58114 0.402359L6.86755 0.500396C8.37084 1.01498 9.12248 1.27227 9.31124 1.5412C9.5 1.81014 9.5 2.60954 9.5 4.20833V4.99568C9.5 7.81472 7.38052 9.18274 6.05072 9.76364C5.68999 9.92121 5.50963 10 5 10C4.49037 10 4.31001 9.92121 3.94928 9.76364C2.61948 9.18274 0.5 7.81472 0.5 4.99568V4.20833ZM5 2.625C5.20711 2.625 5.375 2.79289 5.375 3V5C5.375 5.20711 5.20711 5.375 5 5.375C4.79289 5.375 4.625 5.20711 4.625 5V3C4.625 2.79289 4.79289 2.625 5 2.625ZM5 7C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6C4.72386 6 4.5 6.22386 4.5 6.5C4.5 6.77614 4.72386 7 5 7Z" fill="#F44236"/>
            </svg>
            Ваши пароли не совпадают!
          </div>
        </div>
        <div class="profile-security-input-input">
          <input :type="type2 === false ? 'password' : 'text'" placeholder="*********"/>
          <div :class="['profile-security-input-icon', { 'profile-security-input-icon-active': type2 === true}]" @click="type2 = !type2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 2.75C15.9068 2.75 17.2615 2.75159 18.2892 2.88976C19.2952 3.02503 19.8749 3.27869 20.2981 3.7019C20.7852 4.18904 20.9973 4.56666 21.1147 5.23984C21.2471 5.9986 21.25 7.08092 21.25 9C21.25 9.41422 21.5858 9.75 22 9.75C22.4142 9.75 22.75 9.41422 22.75 9L22.75 8.90369C22.7501 7.1045 22.7501 5.88571 22.5924 4.98199C22.417 3.97665 22.0432 3.32568 21.3588 2.64124C20.6104 1.89288 19.6615 1.56076 18.489 1.40314C17.3498 1.24997 15.8942 1.24998 14.0564 1.25H14C13.5858 1.25 13.25 1.58579 13.25 2C13.25 2.41421 13.5858 2.75 14 2.75Z"/>
              <path d="M2.00001 14.25C2.41422 14.25 2.75001 14.5858 2.75001 15C2.75001 16.9191 2.75289 18.0014 2.88529 18.7602C3.00275 19.4333 3.21477 19.811 3.70191 20.2981C4.12512 20.7213 4.70476 20.975 5.71085 21.1102C6.73852 21.2484 8.09318 21.25 10 21.25C10.4142 21.25 10.75 21.5858 10.75 22C10.75 22.4142 10.4142 22.75 10 22.75H9.94359C8.10583 22.75 6.6502 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64125 21.3588C1.95681 20.6743 1.58304 20.0233 1.40762 19.018C1.24992 18.1143 1.24995 16.8955 1.25 15.0964L1.25001 15C1.25001 14.5858 1.58579 14.25 2.00001 14.25Z"/>
              <path d="M22 14.25C22.4142 14.25 22.75 14.5858 22.75 15L22.75 15.0963C22.7501 16.8955 22.7501 18.1143 22.5924 19.018C22.417 20.0233 22.0432 20.6743 21.3588 21.3588C20.6104 22.1071 19.6615 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0564 22.75H14C13.5858 22.75 13.25 22.4142 13.25 22C13.25 21.5858 13.5858 21.25 14 21.25C15.9068 21.25 17.2615 21.2484 18.2892 21.1102C19.2952 20.975 19.8749 20.7213 20.2981 20.2981C20.7852 19.811 20.9973 19.4333 21.1147 18.7602C21.2471 18.0014 21.25 16.9191 21.25 15C21.25 14.5858 21.5858 14.25 22 14.25Z"/>
              <path d="M9.94359 1.25H10C10.4142 1.25 10.75 1.58579 10.75 2C10.75 2.41421 10.4142 2.75 10 2.75C8.09319 2.75 6.73852 2.75159 5.71085 2.88976C4.70476 3.02503 4.12512 3.27869 3.70191 3.7019C3.21477 4.18904 3.00275 4.56666 2.88529 5.23984C2.75289 5.9986 2.75001 7.08092 2.75001 9C2.75001 9.41422 2.41422 9.75 2.00001 9.75C1.58579 9.75 1.25001 9.41422 1.25001 9L1.25 8.90369C1.24995 7.10453 1.24992 5.8857 1.40762 4.98199C1.58304 3.97665 1.95681 3.32568 2.64125 2.64124C3.38961 1.89288 4.33856 1.56076 5.51098 1.40314C6.65019 1.24997 8.10584 1.24998 9.94359 1.25Z"/>
              <path d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75Z"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89243 14.0598C5.29748 13.3697 5.00001 13.0246 5.00001 12C5.00001 10.9754 5.29748 10.6303 5.89243 9.94021C7.08038 8.56223 9.07269 7 12 7C14.9273 7 16.9196 8.56222 18.1076 9.94021C18.7025 10.6303 19 10.9754 19 12C19 13.0246 18.7025 13.3697 18.1076 14.0598C16.9196 15.4378 14.9273 17 12 17C9.07269 17 7.08038 15.4378 5.89243 14.0598ZM9.25001 12C9.25001 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25001 13.5188 9.25001 12Z" />
            </svg>
          </div>
        </div>
        <div v-if="invalid" class="profile-security-input-danger">
        </div>
      </div>
    </div>
    <div class="profile-security-min-length profile-security-difficulty-red">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4.20833C0.5 2.60954 0.5 1.81014 0.688758 1.5412C0.877516 1.27227 1.62916 1.01498 3.13246 0.500396L3.41886 0.402358C4.20249 0.134119 4.59431 0 5 0C5.40569 0 5.79751 0.13412 6.58114 0.402359L6.86755 0.500396C8.37084 1.01498 9.12248 1.27227 9.31124 1.5412C9.5 1.81014 9.5 2.60954 9.5 4.20833V4.99568C9.5 7.81472 7.38052 9.18274 6.05072 9.76364C5.68999 9.92121 5.50963 10 5 10C4.49037 10 4.31001 9.92121 3.94928 9.76364C2.61948 9.18274 0.5 7.81472 0.5 4.99568V4.20833ZM5 2.625C5.20711 2.625 5.375 2.79289 5.375 3V5C5.375 5.20711 5.20711 5.375 5 5.375C4.79289 5.375 4.625 5.20711 4.625 5V3C4.625 2.79289 4.79289 2.625 5 2.625ZM5 7C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6C4.72386 6 4.5 6.22386 4.5 6.5C4.5 6.77614 4.72386 7 5 7Z" />
      </svg>
      Ваш пароль должен быть 8 символов
    </div>
    <div class="profile-security-difficulty">
      <div class="profile-security-difficulty-text">
        Сложность вашего пароля:
      </div>
      <div :class="`profile-security-difficulty-${diff[curDiff].color}`">
        <div class="profile-security-difficulty-icon">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4.20833C0.5 2.60954 0.5 1.81014 0.688758 1.5412C0.877516 1.27227 1.62916 1.01498 3.13246 0.500396L3.41886 0.402358C4.20249 0.134119 4.59431 0 5 0C5.40569 0 5.79751 0.13412 6.58114 0.402359L6.86755 0.500396C8.37084 1.01498 9.12248 1.27227 9.31124 1.5412C9.5 1.81014 9.5 2.60954 9.5 4.20833V4.99568C9.5 7.81472 7.38052 9.18274 6.05072 9.76364C5.68999 9.92121 5.50963 10 5 10C4.49037 10 4.31001 9.92121 3.94928 9.76364C2.61948 9.18274 0.5 7.81472 0.5 4.99568V4.20833ZM5 2.625C5.20711 2.625 5.375 2.79289 5.375 3V5C5.375 5.20711 5.20711 5.375 5 5.375C4.79289 5.375 4.625 5.20711 4.625 5V3C4.625 2.79289 4.79289 2.625 5 2.625ZM5 7C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6C4.72386 6 4.5 6.22386 4.5 6.5C4.5 6.77614 4.72386 7 5 7Z" />
          </svg>
          <div>
            {{ diff[curDiff].text }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-orange">
      Сохранить пароль
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileSecurity",
  data(){
    return{
      type1: false,
      type2: false,
      curDiff: 2,
      diff: [
        {
          text: 'Низкая',
          color: 'red',
        },
        {
          text: 'Средняя',
          color: 'orange'
        },
        {
          text: 'Высокая',
          color: 'green'
        }
      ],
      invalid: true
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileSecurity.scss";
</style>
