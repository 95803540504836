<template>
  <div class="chat-sidebar">
    <div class="chat-sidebar-search">
      <input type="text" placeholder="Поиск по имени..."/>
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 18.0063L13.6927 12.6989C14.968 11.1678 15.604 9.20393 15.4683 7.21584C15.3326 5.22774 14.4357 3.3685 12.9641 2.02488C11.4924 0.681269 9.55947 -0.0432665 7.56726 0.00200021C5.57505 0.047267 3.67699 0.858851 2.26792 2.26792C0.858851 3.67699 0.047267 5.57505 0.00200021 7.56726C-0.0432665 9.55947 0.681269 11.4924 2.02488 12.9641C3.3685 14.4357 5.22774 15.3326 7.21584 15.4683C9.20393 15.604 11.1678 14.968 12.6989 13.6927L18.0063 19L19 18.0063ZM1.43068 7.75563C1.43068 6.50467 1.80163 5.28181 2.49662 4.24168C3.19162 3.20154 4.17944 2.39086 5.33518 1.91214C6.49091 1.43341 7.76265 1.30816 8.98957 1.55221C10.2165 1.79626 11.3435 2.39865 12.2281 3.28321C13.1126 4.16778 13.715 5.29477 13.9591 6.5217C14.2031 7.74862 14.0779 9.02035 13.5991 10.1761C13.1204 11.3318 12.3097 12.3196 11.2696 13.0146C10.2295 13.7096 9.00659 14.0806 7.75563 14.0806C6.07872 14.0787 4.47102 13.4118 3.28527 12.226C2.09951 11.0402 1.43254 9.43254 1.43068 7.75563Z" fill="#1E272F" fill-opacity="0.21"/>
      </svg>
    </div>
    <div class="chat-sidebar-items">
      <ChatItem v-for="i in 10" :key="i" :system="i % 2 === 0"/>
    </div>
  </div>
</template>
<script>
import ChatItem from "@/components/Chat/ChatItem.vue";

export default {
  name: 'ChatSidebar',
  components: {ChatItem}
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.chat-sidebar{
  width: 648px;
  position: absolute;
  &-search{
    display: flex;
    align-items: center;
    width: 313px;
    height: 53px;
    padding: 20px;
    border: 1px solid #D9D9D9;
    border-radius: $border-radius;
    input{
      height: 19px;
      border: none;
      outline: none;
      font-weight: 300;
      width: 100%;
    }
  }
  &-items{
    margin-top: 22px;
    overflow-y: auto;
    overflow-x: visible;
    height: 575px;
    width: 648px;
    direction:rtl;
    .chat-item{
      margin-right: 326px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      //width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #F0F0F0;
      border-radius: $border-radius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1E272F;
      border-radius: $border-radius;
    }
  }
}
</style>
