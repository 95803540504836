<template>
  <div class="create-task-step3">
    <div class="create-task-step3-container">
      <div class="create-task-container-title">
        По какому адресу?
      </div>
      <div class="create-task-container-description">
        Укажите, по какому адресу необходима будет помощь
      </div>
      <UiFormInput placeholder="Например, Pod sídlištěm 9, Praha 8 Метро “Kobylisy“*" :model-value="input" @validate="setDanger(false)" @update:modelValue="setInput" danger-text="Укажите адрес" ref="uiForm"/>
      <MapComponent :no-radius="true" :input="input"/>
    </div>
    <TipsComponent :map-step="true"/>
  </div>
</template>
<script>
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";
import MapComponent from "@/components/Map/Map.vue";
import TipsComponent from "@/components/Tasks/TipsComponent.vue";

export default {
  name: "CreateTaskStep3",
  components: {
    UiFormInput,
    MapComponent,
    TipsComponent
  },
  data () {
    return {
      input: ''
    }
  },
  methods: {
    nextStep(){
      if (this.input.trim().length === 0){
        this.$refs.uiForm.setDanger(true)
        return false
      }
      return true
    },
    setInput(value) {
      this.input = value
    },
    setDanger(value){
      this.$emit('validate', value)
    },
  }
}
</script>
<style lang="scss" scoped>
.create-task-step3{
  padding-bottom: 24px;
  display: flex;
  gap: 43px;
  .tips-component{
    min-width: 360px;
    width: 360px;
    height: 260px;
  }
}
.map-component{
  width: 757px;
  height: 435px;
  margin-top: 19px;
}
</style>
