<template>
  <div class="ui-form-upload" v-bind="getRootProps()">
    <label for="upload-photo" class="ui-form-upload-label">
      <span class="ui-form-upload-svg">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6776 0H3.32236C1.49038 0 0 1.49043 0 3.32241V22.6776C0 24.5096 1.49038 26 3.32236 26H22.6776C24.5096 26 26 24.5096 26 22.6776V3.32241C26 1.49043 24.5096 0 22.6776 0ZM24.4685 22.6776C24.4685 23.6652 23.6651 24.4685 22.6776 24.4685H3.32236C2.33482 24.4685 1.53141 23.6651 1.53141 22.6776V19.8843L6.5717 15.5958C6.6605 15.52 6.77323 15.4781 6.88996 15.4774C7.0067 15.4767 7.11992 15.5173 7.20962 15.592L10.3669 18.2137C10.6713 18.4664 11.1182 18.4457 11.398 18.1657L18.8998 10.6524C19.0354 10.5165 19.1935 10.5033 19.276 10.5076C19.3583 10.5118 19.5144 10.5411 19.6354 10.6902L24.4686 16.6412L24.4685 22.6776ZM24.4685 14.2119L20.8242 9.72456C20.645 9.50427 20.4216 9.324 20.1685 9.19541C19.9153 9.06682 19.638 8.99277 19.3545 8.97802C18.7823 8.94908 18.2212 9.16449 17.8161 9.57023L10.8079 16.5893L8.18807 14.4139C7.82125 14.1083 7.35821 13.9423 6.88082 13.9452C6.40343 13.948 5.94242 14.1196 5.57929 14.4295L1.53141 17.8736V3.32241C1.53141 2.33487 2.33482 1.53146 3.32236 1.53146H22.6776C23.6652 1.53146 24.4685 2.33487 24.4685 3.32241V14.2119Z" fill="#999DA1"/>
          <path d="M8.18269 3.19922C6.14662 3.19922 4.49023 4.8557 4.49023 6.89168C4.49023 8.9277 6.14667 10.5841 8.18269 10.5841C10.2187 10.5841 11.8751 8.9277 11.8751 6.89168C11.8751 4.85565 10.2188 3.19922 8.18269 3.19922ZM8.18269 9.05272C6.99106 9.05272 6.02164 8.08326 6.02164 6.89168C6.02164 5.70004 6.99106 4.73063 8.18269 4.73063C9.37432 4.73063 10.3437 5.70009 10.3437 6.89168C10.3437 8.08326 9.37432 9.05272 8.18269 9.05272Z" fill="#999DA1"/>
        </svg>
      </span>
      <span class="ui-form-upload-text">
        Перетащите ваши фото суда или нажмите, чтобы выбрать и загрузить файлы обычным способом
      </span>
    </label>
    <input id="upload-photo" v-bind="getInputProps()"/>
  </div>
</template>
<script>
import { useDropzone } from "vue3-dropzone";

export default {
  setup() {
    function onDrop(acceptFiles, rejectReasons) {
      console.log(acceptFiles);
      console.log(rejectReasons);
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-form-upload{
  border: 2px dashed rgba(#1E272F, .17);
  padding: 36px 32px;
  border-radius: 32px;
  color: rgba(#1E272F, .51);
  &-label{
    display: flex;
    align-items: center;
    gap: 9px;
  }
  &-svg,&-text{
    display: flex;
  }
  #upload-photo{
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}
</style>
