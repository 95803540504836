<template>
  <div class="container-banners">
    <div class="container safe-banner-container">
      <div class="safe-banner-col">
        <div class="banners-title">
<!--          <div class="safe-banner-title banners-title">-->
          Безопасная сделка
        </div>
        <div class="safe-banner-sticker">
          <img src="@/assets/img/stripe.png"/>
        </div>
        <div class="safe-banner-row">
          <div class="btn-orange">
            Воспользоваться
          </div>
          <div class="safe-banner-description">
            Все сделки защищены проверенным
            сервисом безопасных платежей stripe.
          </div>
        </div>
      </div>
      <div class="safe-banner-col">
        <div class="safe-banner-row safe-banner-row-cards">
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              1
            </div>
            <div class="safe-banner-card-text">
              Создайте задание
            </div>
          </div>
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              2
            </div>
            <div class="safe-banner-card-text">
              Выберите исполнителя
            </div>
          </div>
        </div>
        <div class="safe-banner-row safe-banner-row-cards safe-banner-row-cards-middle">
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              3
            </div>
            <div class="safe-banner-card-text">
              Согласуйте цену
            </div>
          </div>
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              4
            </div>
            <div class="safe-banner-card-text">
              Пополните кошелек
              сервиса getjob
            </div>
          </div>
        </div>
        <div class="safe-banner-row safe-banner-row-cards">
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              5
            </div>
            <div class="safe-banner-card-text">
              Удостоверьтесь
              что задача выполнена
            </div>
          </div>
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.21 15.54L0 9.33L2.83 6.5L6.21 9.89L16.09 0L18.92 2.83L6.21 15.54Z" fill="#FF6B17"/>
              </svg>
            </div>
            <div class="safe-banner-card-text">
              Подтвердите оплату
              на счет исполнителя
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SafeBanner"
}
</script>
<style lang="scss">
@import "./Safe.scss";
</style>
