<template>
  <div class="accepted-icon">
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2563 5.11513C15.6723 2.92743 18.8732 2.92743 20.2891 5.11513C21.0996 6.36735 22.5994 6.98861 24.058 6.67624C26.6061 6.13051 28.8695 8.39392 28.3238 10.9421C28.0114 12.4006 28.6327 13.9005 29.8849 14.7109C32.0726 16.1268 32.0726 19.3278 29.8849 20.7437C28.6327 21.5542 28.0114 23.054 28.3238 24.5126C28.8695 27.0607 26.6061 29.3241 24.058 28.7784C22.5994 28.466 21.0996 29.0873 20.2891 30.3395C18.8732 32.5272 15.6723 32.5272 14.2563 30.3395C13.4459 29.0873 11.946 28.466 10.4875 28.7784C7.93933 29.3241 5.67592 27.0607 6.22165 24.5126C6.53402 23.054 5.91276 21.5542 4.66054 20.7437C2.47284 19.3278 2.47284 16.1268 4.66054 14.7109C5.91276 13.9005 6.53402 12.4006 6.22165 10.9421C5.67592 8.39392 7.93933 6.13051 10.4875 6.67624C11.946 6.98861 13.4459 6.36735 14.2563 5.11513Z" fill="#3CB055"/>
      <g clip-path="url(#clip0_479_2869)">
        <path d="M17.2707 10.9418L11.7188 13.4093V17.1106C11.7188 20.5343 14.0876 23.7359 17.2707 24.5132C20.4538 23.7359 22.8226 20.5343 22.8226 17.1106V13.4093L17.2707 10.9418Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_479_2869">
          <rect width="14.8052" height="14.8052" fill="white" transform="translate(9.87109 10.3248)"/>
        </clipPath>
      </defs>
    </svg>
    <div class="accepted-icon-info">
      <div class="accepted-icon-info-container">
        <div class="accepted-icon-info-text">
          Этот исполнитель подтвердил свои email и номер телефона
        </div>
        <div class="accepted-icon-info-triangle">
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.accepted-icon{
  cursor: pointer;
  position: relative;
  &:hover{
    .accepted-icon-info{
      display: block;
    }
  }
  &-info{
    display: none;
    position: absolute;
    width: 323px;
    height: 96px;
    left: -370%;
    background: #fff;
    z-index: 1;
    top: 130%;
    &-container{
      border-radius: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.25);
      position: relative;
    }
    &-triangle{
      width: 34px;
      height: 34px;
      background: #fff;
      border-radius: 4px;
      position: absolute;
      top: -8px;
      left: 40%;
      transform: rotate(45deg);
    }
    &-text{
      font-size: 14px;
      width: 262px;
    }
  }
}
</style>
