<template>
  <div class="executors-view">
    <div class="container-banners">
      <div class="find-task-header container">
        <BannerInput btn-text="Найти" placeholder="Поиск по ключевым словам" filter/>
        <div class="find-task-header-description">
          Найдено 2 937 заданий
        </div>
      </div>
      <div class="container-banners">
        <div class="find-task-items container">
          <div class="find-task-items-left">
            <FindTaskItem
              v-for="i in 10"
              :key="i"
              is-executors
              :have-tasks="i % 2 === 0"
            />
            <PaginationComponent />
          </div>
          <div class="find-task-items-right">
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Все категории"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Курьерские услуги" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Ремонт и строительство" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Грузоперевозки" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Уборка и помощь по хозяйству" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Виртуальный помощник" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Компьютерная помощь" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Грузоперевозки" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Уборка и помощь по хозяйству" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Виртуальный помощник" is-dropdown :items="items"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationComponent from "@/components/PaginationComponent.vue";
import BannerInput from "@/components/Home/Banners/BannerInput.vue";
import FindTaskItem from "@/components/Tasks/FindTaskItem.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";
export default {
  components: {UiCheckbox, FindTaskItem, BannerInput, PaginationComponent},
  data () {
    return {
      status: 2,
      isExecutor: false,
      isOwner: false,
      items: [
        {
          id: 1,
          text: 'Ремонт компьютеров и ноутбуков'
        },
        {
          id: 2,
          text: 'Установка и настройка операц. систем, программ'
        },
        {
          id: 3,
          text: 'Удаление вирусов'
        },
        {
          id: 4,
          text: 'Настройка интернета и Wi-Fi'
        },
        {
          id: 5,
          text: 'Ремонт и замена комплектующих'
        }
      ]
    }
  },
}
</script>
