<template>
  <div class="modal-task-cancel">
     <div class="modal-task-cancel-container">
       <div class="btn btn-secondary" @click="confirmDelete">
         Да, отменить
       </div>
       <div class="btn-primary" @click="closeModal">
         Назад
       </div>
     </div>
  </div>
</template>
<script>
export default {
  name: "ModalTaskCancel",
  computed: {
    modal(){
      return this.$store.getters.getModal
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('setIsOpen', false)
    },
    confirmDelete() {
      this.modal.callBack()
    }
  }
}
</script>
<style lang="scss">
.modal-header{
  width: 592px;
}
.modal-task-cancel{
  border-top: 1px solid #D9D9D9;
  border-radius: 21px;
  margin-top: -14px;
  background: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 33px 0;
  .btn-secondary{
    width: 337px;
    height: 98px;
    font-size: 22px;
    color: #1e272f;
    &:hover{
      color: #fff;
    }
  }
  .btn-primary{
    width: 337px;
    height: 98px;
    margin-top: 14px;
    font-size: 22px;
  }
}
</style>
