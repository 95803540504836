<template>
  <div class="profile-referral">
    <div class="profile-referral-container">
      <div class="profile-referral-referrals" v-if="referrals">
        <div class="profile-referral-referrals-header">
          <div class="title">
            Ваши рефералы
          </div>
          <div class="profile-referral-referrals-header-link">
           <router-link to="to">
             Вывод баллов себе на счет
             <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M1 9L9 1M9 1V8.68M9 1H1.32" stroke="#FF6B17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>
           </router-link>
          </div>
        </div>
        <div class="profile-referral-referrals-container" >
          <ReferralCard name="Emilia Jones" :lvl="1" :tasks="4" :points="2424"/>
          <ReferralCard name="Emilia Jones" :lvl="2" :tasks="534" :points="2424"/>
          <ReferralCard name="Emilia Jones" :lvl="1" :tasks="4" :points="2424"/>
          <ReferralCard name="Emilia Jones" :lvl="2" :tasks="534" :points="2424"/>
        </div>
      </div>
      <div :class="['title', {'profile-referral-title': referrals}]">Реферальная программа</div>
      <div class="profile-referral-desc">
        Вы приглашаете новых исполнителей присоединиться к GetJob и зарабатываете комиссии с каждого выполненного ими задания и их рефералов.
      </div>
      <div class="profile-referral-content">
        <div class="title">
          Как работает программа?
        </div>
        <div class="profile-referral-info">
          <ReferralDesc title="Зарегистрируйтесь как пользователь или исполнитель на платформе GetJob." desc="Ваша реферальная ссылка появится в личном кабинете." :number="1"/>
          <ReferralDesc title="Приглашайте новых исполнителей на платформу GetJob" desc="Отправляйте Вашу реферальную ссылку, по которой они могут зарегистрироваться." :number="2"/>
          <ReferralDesc title="Получайте бонусные комиссии за каждое задание" desc="Которые совершают исполнители в Вашей 2-уровневой сети." :is-icon="true"/>
        </div>
        <div class="profile-referral-lvl-container">
          <div class="profile-referral-lvl profile-referral-lvl-first">
            <div class="title profile-referral-lvl-title">
              Лично приглашенные исполнители
            </div>
            <div class="profile-referral-lvl-info">
              <div class="profile-referral-lvl-info-avatars">
                <AvatarComponent :is-star="true" size="medium"/>
                <AvatarComponent :is-star="true" size="medium"/>
                <AvatarComponent :is-star="true" size="medium"/>
              </div>
              <div class="profile-referral-lvl-info-text">
                С каждого задания ваших лично приглашенных исполнителей <span>вы зарабатываете 2.5%</span>
              </div>
            </div>
            <div class="profile-referral-lvl-bubble profile-referral-lvl-bubble-orange"></div>
          </div>
          <div class="profile-referral-lvl profile-referral-lvl-second">
            <div class="title profile-referral-lvl-title">
              2-й уровень исполнителей
            </div>
            <div class="profile-referral-lvl-info">
              <div class="profile-referral-lvl-info-avatars">
                <AvatarComponent :is-star="true" star-color="blue" size="medium"/>
                <AvatarComponent :is-star="true" star-color="blue" size="medium"/>
                <AvatarComponent :is-star="true" star-color="blue" size="medium"/>
              </div>
              <div class="profile-referral-lvl-info-text">
                С каждого задания исполнителей на 2-м уровне <span>вы зарабатываете 1.5%.</span>
              </div>
              <div class="profile-referral-lvl-bubble profile-referral-lvl-bubble-blue"></div>
            </div>
          </div>
        </div>
        <div class="profile-referral-text">
          <div class="title">
            Почему стоит пользоваться этой реферальной программой?
          </div>
          <div class="profile-referral-text-desc">
            <p>1. Вы получайте постоянный доход, даже если вы сами
              не выполняете задания.</p>
            <p>2. Участие полностью бесплатное. Просто приглашайте исполнителей
              и получайте комиссии с каждой их сделки.</p>
            <p>3. Быстрый заработок – вам начисляется комиссия сразу
            же после выполнения задания исполнителем.</p>
            <p>4. Быстрый доступ к средствам – весь доход автоматически зачисляется на ваш баланс и доступен в личном кабинете.</p>
            <p class="strong">
              Вы можете использовать его на портале GetJob или вывести
              на выбранный вами банковский счет.
            </p>
          </div>
        </div>
        <div class="profile-referral-actions">
          <div :class="['profile-referral-actions-btn', {'btn-primary': !referrals, 'btn-orange': referrals}]" @click="referrals = !referrals">
            Стать партнёром
          </div>
          <div class="profile-referral-actions-link" v-if="referrals">
            <div class="profile-referral-actions-link-a">
              GetJob/referral/yNiKNcKw
            </div>
            <div class="profile-referral-actions-link-btn btn btn-dark">
              Скопировать
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReferralDesc from "@/components/Profile/ProfileReferral/ReferralDesc.vue";
import AvatarComponent from "@/components/Avatar.vue";
import ReferralCard from "@/components/Profile/ProfileReferral/ReferralCard.vue";

export default {
  name: "ProfileReferral",
  components: {ReferralCard, AvatarComponent, ReferralDesc},
  data () {
    return {
      referrals: false
    }
  }
}
</script>
<style lang="scss">
@import './ProfileReferral.scss';
</style>
