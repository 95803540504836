<template>
  <div class="profile-about">
    <h2>
      О себе
    </h2>
    <div v-if="!isEditing" class="profile-about-area">
      {{ textArea }}
    </div>
    <textarea v-else class="profile-about-area-active" v-model="textArea">
    </textarea>
    <div v-if="!isEditing" class="profile-about-edit btn btn-dark" @click="isEditing = true">
       Редактировать
    </div>
    <div v-else class="btn-primary" @click="isEditing = false">
      Сохранить
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileAbout",
  data () {
    return {
      isEditing: false,
      textArea: "Учитывая ключевые сценарии поведения, внедрение современных методик требует определения и уточнения анализа существующих паттернов поведения." +
        "Повседневная практика показывает, что разбавленное изрядной долей эмпатии, рационаращены в посмешище, хотя само их существование приносит несомненную пользу"
    }
  }
}
</script>
<style lang="scss">
@import "./ProfileAbout.scss";
</style>
