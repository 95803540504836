<template>
  <div class="app-card google-play">
    <div class="app-card-icon apple-store-icon">
      <img src="@/assets/img/appstore.png"/>
    </div>
    <div class="app-card-text">
      Apple Store
    </div>
  </div>
</template>
<script>
export default {
  name: "AppStoreCard"
}
</script>