<template>
  <div class="modal-map">
    <div class="modal-map-title">
      <div class="modal-map-title-text">
        Адрес:
      </div>
      <div class="modal-map-title-value">
        Ул. Город. Дом. Дробь/2
      </div>
    </div>
    <MapComponent :no-radius="true" :no-tips="true" />
  </div>
</template>
<script>
import MapComponent from "@/components/Map/Map.vue";
export default {
  name: 'ModalMap',
  components: {
    MapComponent
  }
}
</script>
<style lang="scss" scoped>
.modal-map{
  padding: 20px;
  &-title{
    margin-top: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-text{
      color: #999DA1;
    }
    &-value{
      font-weight: 500;
    }
  }
  .map-component{
    margin-top: 27px;
    width: 662px;
    height: 435px;
    border-radius: 34px;
  }
}
</style>
