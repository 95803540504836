<template>
  <div class="social-login">
    <div>
      Войти через соц-сети:
    </div>
    <div class="social-login-item social-login-facebook">
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.70466 11.2398C3.6293 11.2398 2.04842 11.2403 1.3193 11.2396C0.94394 11.2393 0.81002 11.1045 0.81002 10.7267C0.80942 9.75639 0.80942 8.78607 0.81002 7.81575C0.81026 7.44279 0.95186 7.30047 1.32242 7.30023C2.05154 7.29975 3.62378 7.29999 3.70466 7.29999V5.18751C3.70514 4.23375 3.8753 3.32079 4.35986 2.48487C4.85594 1.62927 5.57786 1.04319 6.50306 0.704309C7.09562 0.487109 7.71074 0.400469 8.33954 0.399989C9.12626 0.399509 9.91298 0.400229 10.6999 0.401669C11.0381 0.402149 11.1881 0.551669 11.1888 0.892229C11.1903 1.80495 11.1903 2.71767 11.1888 3.63015C11.1883 3.97431 11.0446 4.11255 10.6987 4.11639C10.0539 4.12335 9.4085 4.11903 8.76434 4.14495C8.1137 4.14495 7.77146 4.46271 7.77146 5.13615C7.75586 5.84847 7.76498 6.56151 7.76498 7.29975C7.82594 7.29975 9.67754 7.29951 10.543 7.29975C10.9361 7.29975 11.0705 7.43487 11.0705 7.83015C11.0705 8.79519 11.0703 9.76047 11.0695 10.7255C11.0693 11.115 10.9431 11.2393 10.5475 11.2396C9.6821 11.2401 7.8377 11.2398 7.75706 11.2398V19.0506C7.75706 19.467 7.62602 19.5997 7.21514 19.5997H4.20962C3.8465 19.5997 3.7049 19.4586 3.7049 19.0955L3.70466 11.2398Z" fill="#3D6AD6"/>
      </svg>
    </div>
    <div class="social-login-item social-login-gmail">
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.22727 13.7517H4.09092V6.79712L2.21576 3.52274L0 3.72893V12.5244C0 13.2024 0.549211 13.7517 1.22727 13.7517Z" fill="#0085F7"/>
        <path d="M13.9092 13.7517H16.7728C17.4509 13.7517 18.0001 13.2024 18.0001 12.5244V3.72893L15.7875 3.52274L13.9092 6.79712V13.7517H13.9092Z" fill="#00A94B"/>
        <path d="M13.9087 1.47892L12.2266 4.68879L13.9087 6.79711L17.9996 3.72892V2.09257C17.9996 0.575862 16.2681 -0.290388 15.0541 0.619843L13.9087 1.47892Z" fill="#FFBC00"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09116 6.7971L2.48828 3.41121L4.09116 1.47891L9.00024 5.16072L13.9093 1.47891V6.7971L9.00024 10.4789L4.09116 6.7971Z" fill="#FF4131"/>
        <path d="M0 2.09257V3.72892L4.09092 6.79711V1.47892L2.94546 0.619843C1.73148 -0.290388 0 0.575862 0 2.09257Z" fill="#E51C19"/>
      </svg>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.social-login{
  display: flex;
  align-items: center;
  color: rgba(30, 39, 47, 0.51);
  font-size: 14px;
  padding-bottom: 23px;
  border-bottom: 1px solid rgba(0,0,0, .06);
  &-item{
    width: 34px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    border-radius: 5px;
    margin-left: 7px;
    cursor: pointer;
  }
  &-facebook{
    margin-left: 6px;
  }
}
</style>
