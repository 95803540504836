<template>
  <div class="profile-view container-banners">
    <ProfileViewComponent :is-executor="id === 1"/>
  </div>
</template>
<script>
import ProfileViewComponent from "@/components/Profile/View/ProfileViewComponent.vue";
export default {
  name: "ProfileView",
  components: {
    ProfileViewComponent
  },
  data () {
    return {
      id: 1
    }
  },
  created() {
    if (this.$route.params.id != 1) {
      this.id = 2
    }
  }
}
</script>
