<template>
  <div class="modal-registration-step1 modal-registration-container">
    <div class="modal-registration-step1-container">
      <UiFormInput placeholder="Ваш номер телефона*:" :grey="true"/>
      <div class="btn-primary" @click="$emit('nextStep')">
        Далее
      </div>
      <SocialLogin/>
      <div class="modal-registration-step1-agreements">
        Нажимая кнопку “зарегистрироваться” вы соглашаетесь с <router-link to="/">правилами пользования</router-link> нашего сайта
      </div>
    </div>
  </div>
</template>
<script>
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";
import SocialLogin from "@/components/SocialLogin.vue";

export default {
  name: "RegistrationStep1",
  components: {SocialLogin, UiFormInput}
}
</script>
<style lang="scss">
.modal-registration-step1{
  &-container{
    width: 312px;
    margin: -14px auto 0;
    padding-top: 40px;
  }
  .ui-form-input{
    width: 308px;
    padding: 26px 30px;
    margin:0 auto;
    input {
      text-align: center;
    }
  }
  .btn-primary{
    width: 312px;
    height: 98px;
    margin-top: 19px;
    font-size: 22px;
  }
  .social-login{
    width: 226px;
    margin: 0 auto;
    margin-top: 19px;
  }
  &-agreements{
    margin-top: 14px;
    padding-bottom: 23px;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    color: rgba(30, 39, 47, 0.51);
    a{
      color: #287EFF;
    }

  }
}
</style>
