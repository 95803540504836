<template>
  <div class="ui-turn-switcher">
    <div :class="['ui-turn-switcher-btn', {'ui-turn-switcher-btn-active': turned}]" @click="setSwitcher(true)">
      ON
    </div>
    <div :class="['ui-turn-switcher-btn', {'ui-turn-switcher-btn-active': !turned}]" @click="setSwitcher(false)">
      OFF
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiTurnSwitcher',
  data () {
    return {
      turned: false
    }
  },
  methods: {
    setSwitcher(value){
      this.turned = value
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.ui-turn-switcher{
  width: 146px;
  height: 49px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.34);
  &-btn{
    color: #1e272f;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 77px;
    &-active{
      border-radius: $border-radius;
      background: #FF6B17;
      color: #fff;
    }
  }
}
</style>
