<template>
  <div class="notification cookies-notification">
    <div class="cookies-notification-background">
    </div>
    <div class="cookies-notification-content">
      <div class="cookies-notification-img">
        <img src="@/assets/img/cookiesimg.svg"/>
      </div>
      <div class="cookies-notification-container">
        <div class="cookies-notification-text">
          Наш сайт использует файлы cookie, чтобы улучшить работу сайта, повысить его эффективность и удобство.
        </div>
        <div class="cookies-notification-actions">
          <div>
            Это безопасно, проверьте нашу <router-link to="/">политику конфидициальности</router-link>
          </div>
          <div class="btn-orange" @click="closeNotification">
            allow all cookies
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CookiesNotification',
  methods: {
    closeNotification(){
      this.$store.dispatch('setNotificationIsOpen', false)
    }
  }
}
</script>
<style lang="scss">
.cookies-notification{
  top: calc(100vh - 130px)!important;
  &-background{
    height: 119px;
    width: 855px;
    background: rgba(30, 39, 47, 0.74);
    border-radius: 21px;
    box-shadow: 0px 38px 243px rgba(30, 39, 47, 0.25);
    backdrop-filter: blur(10px);
  }
  &-img{
    width: 82px;
    height: 68px;
    margin-left: 24px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  &-container{
    color: #fff;
    font-size: 14px;
    margin-left: 22px;
    a{
      color: #287EFF;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  &-actions{
    display: flex;
    align-items: center;
    margin-top: 7px;
    .btn-orange{
      margin-left: 100px;
      width: 200px;
      height: 39px;
    }
  }
  &-content{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  &-text{
    width: 442px;
  }
}
</style>
