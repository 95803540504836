<template>
  <div>
    <div class="blog-header">
      <h1>Популярные посты из блога</h1>
      <div class="blog-header-link">
<!--        <router-link to="to">-->
<!--          Хотите стать автором в блоге?-->
<!--          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M1 9L9 1M9 1V8.68M9 1H1.32" stroke="#FF6B17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          </svg>-->
<!--        </router-link>-->
      </div>
    </div>

    <BlogContainer/>

    <div class="blog-footer">
      <div class="blog-footer-link">
        <router-link to="to">
           Перейти в блог
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L9 1M9 1V8.68M9 1H1.32" stroke="#FF6B17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import BlogContainer from "@/components/Blog/BlogContainer.vue";

export default {
  name: "BlogComponent",
  components: {BlogContainer}
}
</script>
<style lang="scss">
@import "./Blog.scss";
</style>
