<template>
  <div class="ui-gallery">
    <div
      class="ui-gallery-img"
      v-for="(item, index) in items"
      :key="index"
      @click="openPicture(index)"
    >
      <img :src="require(`@/assets/img/${item}`)" alt="img"/>
      <div class="ui-gallery-img-background">
        <div class="ui-gallery-img-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7883 19.7883C20.0706 19.506 20.0706 19.0483 19.7883 18.7659L16.1224 15.1001C17.4885 13.5006 18.3133 11.4249 18.3133 9.15663C18.3133 4.09956 14.2137 0 9.15663 0C4.09956 0 0 4.09956 0 9.15663C0 14.2137 4.09956 18.3133 9.15663 18.3133C11.4249 18.3133 13.5006 17.4885 15.1001 16.1224L18.7659 19.7883C19.0483 20.0706 19.506 20.0706 19.7883 19.7883ZM9.15663 6.0241C9.55587 6.0241 9.87952 6.34775 9.87952 6.74699V8.43373H11.5663C11.9655 8.43373 12.2892 8.75739 12.2892 9.15663C12.2892 9.55587 11.9655 9.87952 11.5663 9.87952H9.87952V11.5663C9.87952 11.9655 9.55587 12.2892 9.15663 12.2892C8.75739 12.2892 8.43373 11.9655 8.43373 11.5663V9.87952H6.74699C6.34775 9.87952 6.0241 9.55587 6.0241 9.15663C6.0241 8.75739 6.34775 8.43373 6.74699 8.43373H8.43373V6.74699C8.43373 6.34775 8.75739 6.0241 9.15663 6.0241Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PhotoSwipe from 'photoswipe/';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import {computed, ref} from "vue";
export default {
  name: 'UiGallery',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props){
    const items = computed(() => {
      return props.items.map(el => {
        return {
          src: require(`@/assets/img/${el}`),
          w: 800,
          h: 600
        }
      })
    })

    const options = ref({
      dataSource: items,
      showHideAnimationType: 'none'
    })

    return {
      options
    }
  },
  methods: {
    openPicture(index){
      this.options.index = index; // defines start slide index
      const pswp = new PhotoSwipe(this.options);
      pswp.init();
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables";
.ui-gallery{
  display: flex;
  align-items: center;
  gap: 12px;
  &-img{
    width: 117px;
    height: 117px;
    border-radius: $border-radius-medium;
    overflow: hidden;
    position: relative;
    img{
      height: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
    }
    &:hover{
      .ui-gallery-img-background{
        opacity: 1;
      }
    }
    &-background{
      position: absolute;
      width: 117px;
      height: 117px;
      z-index: 1;
      background: rgba(40, 126, 255, 0.48);
      border: 1px solid #287EFF;
      border-radius: $border-radius-medium;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
    }
  }
}
</style>
