import store from "@/store/index";

export default{
  state: {
    isOpen: false,
    modal: {
      title: "",
      modalName: "",
      greyHeader: false,
      customClose: false,
      props: {},
      callBack: () => {}
    }
  },
  getters: {
    getModalState: state => state.isOpen,
    getModal: state => state.modal
  },
  mutations: {
    SET_IS_OPEN(state, value){
      state.isOpen = value;
    },
    SET_MODAL(state, value) {
      state.modal = value
    }
  },
  actions: {
    setIsOpen: ({ commit }, value) => {
      commit("SET_IS_OPEN", value);
    },
    setModal({commit, dispatch}, value){
      commit("SET_MODAL", value)
      dispatch("setIsOpen", true)
    }
  }
}
