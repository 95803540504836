import {createStore} from "vuex";
import auth from "@/store/auth";
import modal from "@/store/modal";

export default {
  state: {
    examples: []
  },
  getters: {
    getExamples: state => state.examples
  },
  mutations: {
    SET_EXAMPLES (state, data) {
      state.examples = data
    }
  },
  actions: {
    fetchExamples({commit}){
      const examples = localStorage.getItem('examples') ? JSON.parse(localStorage.getItem('examples')) : []
      commit('SET_EXAMPLES', examples)
    },
    setExamples({commit}, data) {
      commit('SET_EXAMPLES', data)
    },
  },
  modules: {
  }
}
