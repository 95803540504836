<template>
  <div class="banner container-banners">
    <div class="banner-container container">
      <div class="banner-col">
        <div class="banner-double-text">
          <div class="banner-title">
            Мы сделаем всё за вас
          </div>
          <div class="banner-description">
            Найти профессионала для любой задачи
          </div>
        </div>
        <BannerInput btn-text="Создать задание" placeholder="Какая услуга вам нужна?"/>
        <div class="tag tag-warning">
          Станьте исполнителем и начните зарабатывать
        </div>
      </div>
      <div class="banner-col">
        <div class="banner-col-bg">
          <svg width="131" height="325" viewBox="0 0 131 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M130.124 59L131 223.646C131 279.669 84.5585 325 27.1639 325H0V285.228H27.1639C61.7759 285.228 90.2542 257.431 90.2542 223.646L89.3779 59H130.124Z" fill="#1E272F"/>
            <circle cx="109" cy="22" r="22" fill="#FF6B17"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerInput from "@/components/Home/Banners/BannerInput.vue";

export default {
  name: "BannerComponent",
  components: {BannerInput}
}
</script>
<style lang="scss">
@import "Banner";
</style>
