<template>
  <div class="find-task">
    <div class="container-banners">
      <div class="find-task-header container">
        <BannerInput btn-text="Найти" placeholder="Поиск по ключевым словам" filter/>
        <div class="find-task-header-description">
          Найдено 2 937 заданий
        </div>
      </div>
      <div class="container-banners">
        <div class="find-task-items container">
          <div class="find-task-items-left">
            <FindTaskItem
              v-for="i in 10"
              :key="i"
            />
            <PaginationComponent />
          </div>
          <div class="find-task-items-right">
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Все категории"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Курьерские услуги" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Ремонт и строительство" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Грузоперевозки" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Уборка и помощь по хозяйству" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Виртуальный помощник" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Компьютерная помощь" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Грузоперевозки" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Уборка и помощь по хозяйству" is-dropdown :items="items"/>
            </div>
            <div class="find-task-items-right-category">
              <UiCheckbox size="medium" color="orange" title="Виртуальный помощник" is-dropdown :items="items"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerInput from "@/components/Home/Banners/BannerInput.vue";
import FindTaskItem from "@/components/Tasks/FindTaskItem.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import UiCheckbox from "@/components/Ui/Form/UiCheckbox.vue";

export default {
  name: "FindTask",
  components: {UiCheckbox, PaginationComponent, FindTaskItem, BannerInput},
  data () {
    return {
      items: [
        {
          id: 1,
          text: 'Ремонт компьютеров и ноутбуков'
        },
        {
          id: 2,
          text: 'Установка и настройка операц. систем, программ'
        },
        {
          id: 3,
          text: 'Удаление вирусов'
        },
        {
          id: 4,
          text: 'Настройка интернета и Wi-Fi'
        },
        {
          id: 5,
          text: 'Ремонт и замена комплектующих'
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.find-task{
  .container-banners{
    padding: 0;
    .container-banners{
      margin: 45px 0 0;
      //border-left: none;
      //border-right: none;
      border-bottom: none;
    }
  }
  &-header{
    display: flex;
    align-items: center;
    gap: 38px;
    margin-top: 32px;
    &-description{
      color: #999DA1;
    }
  }
  &-items{
    margin-top: 61px;
    padding-bottom: 253px;
    display: flex;
    &-right{
      margin-left: 83px;
      label{
        font-size: 16px!important;
      }
      &-category:not(:first-child){
        margin-top: 21px;
      }
    }
  }
  &-item{
    width: 663px;
    &:not(:first-child){
      margin-top: 23px;
    }
  }
}
</style>
