<template>
  <div class="category-block">
    <div class="category-block-icon">
      <component :is="iconName"/>
    </div>
    <router-link to="/" class="category-block-title">
      {{ title }}
    </router-link>
    <div class="category-block-subcategories">
      <router-link
        v-for="item in subcategories"
        :key="item.id"
        :to="item.link"
        class="category-block-subcategories-item">
        {{ item.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
import iconCleaning from "@/components/Categories/icons/iconCleaning.vue";
import iconBabySitter from "@/components/Categories/icons/iconBabySitter.vue";
import iconAnimals from "@/components/Categories/icons/iconAnimals.vue";
import iconRepairs from "@/components/Categories/icons/iconRepairs.vue";
import iconDelivery from "@/components/Categories/icons/iconDelivery.vue";
import iconCargo from "@/components/Categories/icons/iconCargo.vue";
import iconPassenger from "@/components/Categories/icons/iconPassenger.vue";
import iconTeachers from "@/components/Categories/icons/iconTeachers.vue";
import iconDocsHelpers from "@/components/Categories/icons/iconDocsHelpers.vue";
import iconRealService from "@/components/Categories/icons/iconRealService.vue";
import iconOther from "@/components/Categories/icons/iconOther.vue";
import iconNotes from "@/components/Categories/icons/iconNotes.vue";
import iconRotatedNotes from "@/components/Categories/icons/iconRotatedNotes.vue";
import iconRotatedCards from "@/components/Categories/icons/iconRotatedCards.vue";

export default {
  name: "CategoryBlock",
  props: {
    icon: {
      type: String,
      default: () => 'Cleaning'
    },
    title: {
      type: String,
      default: () => ''
    },
    subcategories: {
      type: Array,
      default: () => []
    }
  },
  components: {
    iconCleaning,
    iconBabySitter,
    iconAnimals,
    iconRepairs,
    iconDelivery,
    iconCargo,
    iconPassenger,
    iconTeachers,
    iconDocsHelpers,
    iconRealService,
    iconOther,
    iconNotes,
    iconRotatedNotes,
    iconRotatedCards
  },
  computed: {
    iconName() {
      return `icon${this.icon}`
    }
  }
}
</script>
<style lang="scss">
.category-block{
  &-icon{
    width: 44px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #287EFF;
  }
  &-title{
    font-size: 26px;
    margin-top: 22px;
    display: block;
  }
  &-subcategories{
    margin-top: 25px;
    font-weight: 300;
    &-item{
      display: block;
    }
    &-item:not(:first-child){
      margin-top: 20px;
    }
  }
}
</style>
