<template>
  <div class="profile-examples">
    <div class="title">
      Примеры работ
    </div>
    <div class="profile-examples-container" v-if="examples.length !== 0">
      <ExampleCard v-for="card in examples" :key="card.id" :card="card"/>
      <PaginationComponent/>
    </div>
    <div class="profile-examples-desc" v-else>
      Если у вас есть примеры выполненной вами работы, обязательно прикрепите их,
      это покажет вас в лучшем свете в глазах автора задания. Также вы будете вызывать больше доверия как исполнитель.
    </div>
    <div class="profile-examples-btn btn-orange" @click="openModal">
      Добавить
    </div>
  </div>
</template>
<script>
import ExampleCard from "@/components/Profile/ProfileExamples/ExampleCard.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
export default {
  name: 'ProfileExamples',
  components: {
    PaginationComponent,
    ExampleCard
  },
  data () {
    return {
      counter: 0
    }
  },
  computed: {
    examples () {
      return this.$store.getters.getExamples;
    }
  },
  beforeMount() {
    this.$store.dispatch('fetchExamples')
  },
  methods: {
    openModal () {
      this.$store.dispatch("setModal",{title: 'Добавить пример работы', modalName: 'AddExample', greyHeader: true})
    }
  }
}
</script>

<style lang="scss">
.profile-examples{
  &-desc{
    margin-top: 11px;
  }
  &-btn{
    margin-top: 18px;
    width: 100px;
    height: 41px;
  }
  &-container{
    margin-top: 20px;
  }
  .example-card:not(:first-child){
    margin-top: 18px;
  }
}
</style>
