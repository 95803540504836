import { createStore } from 'vuex'
import auth from './auth';
import modal from './modal';
import examples from "./examples";
import notification from "./notification";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    modal,
    examples,
    notification
  }
})
