<template>
  <div class="task-header">
    <div class="task-header-user">
      <AvatarComponent/>
      <div class="task-header-user-info">
        <div v-if="!isExecutor" class="task-header-user-name">
          Маркус Максимов
        </div>
        <router-link v-else to="/profile" class="task-header-user-name">
          Маркус Максимов
        </router-link>
        <div class="task-header-user-task-status">
          <div class="task-header-user-task-status-text">Статус задачи: </div>
          <OrderStatus :order-status="status"/>
        </div>
      </div>
    </div>
    <div class="task-header-date">
      <div class="task-header-date-container">
        <span>
          Создано:
        </span>
          16.10.2023 в 13:38
      </div>
    </div>
  </div>
</template>
<script>
import AvatarComponent from "@/components/Avatar.vue";
import OrderStatus from "@/components/OrderStatus.vue";

export default {
  name: 'TaskHeader',
  props: {
    status: {
      type: Number,
      default: () => 2
    },
    isExecutor: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    AvatarComponent,
    OrderStatus
  }
}
</script>
<style lang="scss" scoped>
.task-header{
  display: flex;
  &-user{
    display: flex;
    align-items: center;
    &-info{
      margin-left: 14px;
    }
    &-task-status{
      display: flex;
      align-items: center;
      gap:7px;
      margin-top: 6px;
      &-text{
        color: #999DA1;
        font-weight: 300;
      }
    }
  }
  &-date{
    margin-left: 229px;
    position: relative;
    width: 212px;
    &-container{
      position: absolute;
      bottom: 10px;
      span{
        color: #999DA1;
        font-weight: 300;
      }
    }
  }
}
</style>
