<template>
  <div class="registration-step4">
    <div class="registration-step4-img">
      <img src="@/assets/img/executorbanner.png"/>
    </div>
    <div class="registration-step4-title">
      Спасибо, что присоединились!
    </div>
    <div class="btn-primary" @click="toCreateTask">
      Создать задание
    </div>
    <router-link class="registration-step4-link" to="/">
      Подробнее
    </router-link>
    <div class="registration-step4-footer">
      <div class="btn-orange">
        Станьте исполнителем
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegistrationStep4",
  methods: {
    toCreateTask(){
      this.$store.dispatch('setIsOpen', false)
      this.$router.push('/create-task')
    }
  }
}
</script>
<style lang="scss">
.registration-step4{
  &-img{
    width: 90px;
    height: 75px;
    margin:64px auto 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
  &-title{
    margin-top: 25px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
  }
  .btn-primary{
    margin: 35px auto 0;
    width: 337px;
    height: 98px;
  }
  &-link{
    display: block;
    width: 115px;
    color: #287EFF;
    margin: 34px auto 0;
    text-decoration: underline;
    font-size: 22px;
  }
  &-footer{
    border-top: 1px solid rgba(0,0,0, .12);
    border-radius: 21px;
    margin-top: 51px;
    padding: 33px 119px 49px 136px;
    .btn-orange{
      width: 337px;
      height: 98px;
      font-size: 22px;
    }
  }
}
</style>
