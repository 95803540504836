<template>
  <div class="ui-switcher-type">
    <div
      :class="['ui-switcher-type-btn',{'btn-orange': switcher === 0}]"
      @click="setModel(0)"
    >
      Я заказчик
    </div>
    <div
      :class="['ui-switcher-type-btn',{'btn-orange': switcher === 1 }]"
      @click="setModel(1)"
    >
      Я исполнитель
    </div>
  </div>
</template>
<script>
export default {
  name: "UiSwitcherType",
  data(){
    return{
      switcher: 0
    }
  },
  methods: {
    setModel(value){
      this.switcher = value;
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.ui-switcher-type{
  display: flex;
  align-items: center;
  height: 49px;
  width: 279px;
  box-shadow: 0px 38px 202px 0px #1E272F57;
  border-radius: $border-radius-medium;
  .btn-orange{
    padding: 0 21px;
    color: #fff;
    height: 100%;
    &:hover{
      opacity: 1;
    }
  }
  &-btn{
    padding: 0 21px;
    color: #1E272F82;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
