<template>
  <div class="blog-card">
    <div class="blog-card-img">
      <img :src="blogImg"/>
      <div class="blog-card-img-tag">
        <div class="blog-card-img-tag-text">
          БЛОГ
        </div>
      </div>
    </div>
    <div class="blog-card-title">
<!--      <router-link :to="`/blog/${id}`">{{title}}</router-link>-->
      {{title}}
    </div>
    <router-link  class="blog-card-btn btn-orange-outline" :to="`/blog/${id}`">
      Прочитать
    </router-link>
  </div>
</template>
<script>
export default {
  name: "BlogCard",
  props: {
    id: {
      type: Number,
      default: () => 1
    },
    title: {
      type: String,
      default: ()=>"Title",
    },
    src: {
      type: String,
      default: () => "blog1.png",
    }
  },
  computed: {
    blogImg(){
      return require("@/assets/img/" + this.src)
    }
  }
}
</script>
<style lang="scss">
@import "./BlogCard.scss";
</style>
