<template>
  <div class="example-card">
    <div class="example-card-imgs">
      <div class="example-card-imgs-img">
        <img src="@/assets/img/1big.png"/>
      </div>
      <div class="example-card-imgs-container">
        <div class="example-card-no-img" v-for="i in 3" :key="i">
          <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10552 0H0.894482C0.401256 0 0 0.40127 0 0.894496V6.10552C0 6.59873 0.401256 7 0.894482 7H6.10552C6.59874 7 7 6.59873 7 6.1055V0.894496C7 0.40127 6.59874 0 6.10552 0ZM6.58768 6.10552C6.58768 6.37139 6.37138 6.58768 6.10552 6.58768H0.894482C0.628605 6.58768 0.412303 6.37138 0.412303 6.10552V5.35347L1.7693 4.19887C1.79321 4.17846 1.82356 4.16717 1.85499 4.16699C1.88642 4.1668 1.9169 4.17773 1.94105 4.19784L2.79109 4.90369C2.87305 4.97174 2.99336 4.96616 3.06868 4.89076L5.08841 2.86795C5.12492 2.83138 5.16748 2.82782 5.18969 2.82896C5.21184 2.83009 5.25388 2.83799 5.28646 2.87812L6.5877 4.48033L6.58768 6.10552ZM6.58768 3.82628L5.60651 2.61815C5.55827 2.55884 5.49814 2.51031 5.42998 2.47569C5.36182 2.44107 5.28716 2.42113 5.21082 2.41716C5.05676 2.40937 4.9057 2.46736 4.79664 2.5766L2.90981 4.46634L2.20448 3.88065C2.10572 3.79839 1.98106 3.7537 1.85253 3.75447C1.724 3.75524 1.59988 3.80143 1.50212 3.88486L0.412303 4.81212V0.894496C0.412303 0.628619 0.628605 0.412316 0.894482 0.412316H6.10552C6.37139 0.412316 6.58768 0.628619 6.58768 0.894496V3.82628Z" fill="#999DA1"/>
            <path d="M2.20311 0.861328C1.65493 0.861328 1.20898 1.3073 1.20898 1.85545C1.20898 2.40361 1.65495 2.84957 2.20311 2.84957C2.75127 2.84957 3.19723 2.40361 3.19723 1.85545C3.19723 1.30729 2.75128 0.861328 2.20311 0.861328ZM2.20311 2.43727C1.88228 2.43727 1.62129 2.17626 1.62129 1.85545C1.62129 1.53463 1.88228 1.27363 2.20311 1.27363C2.52393 1.27363 2.78493 1.53464 2.78493 1.85545C2.78493 2.17626 2.52393 2.43727 2.20311 2.43727Z" fill="#999DA1"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="example-card-info">
      <div class="example-card-info-title">
        {{ card.title }}
      </div>
      <div class="example-card-info-desc">
        {{ card.desc }}
      </div>
      <div class="example-card-delete" @click="deleteExample">
         Удалить
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExampleCard',
  props: {
    card: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    deleteExample () {
      let examples = JSON.parse(localStorage.getItem('examples'))
      examples = examples.filter( el => el.id !== this.card.id)
      localStorage.setItem('examples', JSON.stringify(examples))
      this.$store.dispatch('setExamples', examples)
    }
  }
}
</script>
<style lang="scss" scoped>
.example-card{
  display: flex;
  &-no-img{
    background: rgba(#DADBDD, .38);
    border-radius: 3px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:first-child) {
      margin-top: 4px;
    }
  }
  &-imgs{
    display: flex;
    &-container{
      margin-left: 3px;
    }
  }
  &-info{
    margin-left: 14px;
    &-title{
      font-size: 14px;
    }
    &-desc{
      font-size: 14px;
      font-weight: 300;
      margin-top: 12px;
    }
  }
  &-delete{
    display: none;
    color: #F44236;
    cursor: pointer;
    font-size: 12px;
    &:hover{
      opacity: .75;
    }
  }
  &:hover{
    .example-card-delete{
      display: block;
    }
  }
}
</style>
