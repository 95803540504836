<template>
  <div class="modal-add-example">
    <UiFormInput
      title="Название работы"
      @update:modelValue="setTitle"
      :black-title="true"
      placeholder="Например, Фотосессия гендер пати... "
    />
    <UiFormTextArea
      title="Описание работы:"
      :black-title="true"
      @update:modelValue="setDesc"
      placeholder="Опишите детали работы, когда она была выполнена,
в чём её особенность..."
    />
    <UiFormUpload/>
    <div class="btn-primary" @click="saveExample">
      Сохранить
    </div>
  </div>
</template>
<script>
import UiFormInput from "@/components/Ui/Form/UiFormInput.vue";
import UiFormTextArea from "@/components/Ui/Form/UiFormTextArea.vue";
import UiFormUpload from "@/components/Ui/Form/UiFormUpload.vue";

export default {
  name: 'AddExample',
  components: {UiFormUpload, UiFormTextArea, UiFormInput},
  data () {
    return {
      title: '',
      desc: ''
    }
  },
  methods: {
    setTitle (value) {
      this.title = value
    },
    setDesc (value) {
      this.desc = value
    },
    saveExample () {
      let examples = localStorage.getItem('examples') ? JSON.parse(localStorage.getItem('examples')) : []
      examples.push({'id': examples.length === 0 ? 1 : examples.length, 'title': this.title, 'desc': this.desc})
      localStorage.setItem('examples', JSON.stringify(examples))
      this.$store.dispatch('setExamples', examples)
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-add-example{
  width: 595px;
  padding: 41px 50px 35px;
  border-radius: 21px;
  border-top: 1px solid rgba(0,0,0, .12);
  margin-top: -10px;
  background: #fff;
  .ui-form-text-area{
    margin-top: 12px;
  }
  .ui-form-upload{
    margin-top: 9px;
  }
  .btn-primary{
    width: 337px;
    margin: 0 auto;
    margin-top: 24px;
    height: 98px;
  }
}

</style>
