<template>
  <div class="category-card category-all-btn" @click="toAllCategories">
    <div class="category-all-btn-text">
      Показать все услуги
    </div>
    <div class="category-all-btn-circle">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 17.25L17.25 1M17.25 1V16.6M17.25 1H1.65" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "CategoryAllBtn",
  methods: {
    toAllCategories () {
      this.$router.push('/categories')
    }
  }
}
</script>
<style lang="scss" scoped>
.category-all-btn{
  &-text{
    font-size: 32px;
    font-weight: 500;
    margin: 1.5rem 0 1.5rem 2rem;
    width: 160px;
  }
  &-circle{
    width: 63px;
    height: 63px;
    background: #FF6B17;
    border-radius: 50%;
    position: relative;
    margin-top: 30px;
    margin-left: 21px;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.category-card{
  background: #fff;
  border-color: #fff;
  box-shadow: 0px 38px 202px 0px #1E272F1C;
  &:hover{
    cursor: pointer;
    opacity: .75;
    border-color: #FF6B17;
    .category-all-btn-text{
      color: #FF6B17;
    }
  }
}
</style>
