<template>
  <div :class="['achievement-classic', `achievement-classic-${achievementClass[count]}`]">
    {{ count }}
  </div>
</template>
<script>
export default {
  name: 'AchievementClassic',
  props: {
    count: {
      type: Number,
      default: () => 50
    }
  },
  data () {
    return {
      achievementClass: {
        50: 'grey',
        100: 'blue',
        300: 'dark-blue',
        400: 'yellow',
        500: 'green',
        'top50': 'top'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.achievement-classic{
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  &-grey{
    background: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px -5px 13px rgba(30, 39, 47, 0.18);
    color: rgba(#1E272F, .69);
  }
  &-blue{
    color: #287EFF;
    background: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px -5px 13px rgba(40, 126, 255, 0.34);
  }
  &-dark-blue{
    background: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px -5px 13px rgba(20, 52, 203, 0.37);
    color: #3D6AD6;
  }
  &-yellow{
    background: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px -5px 13px rgba(251, 188, 4, 0.42);
    color: #FBBC04;
  }
  &-green{
    color: #3CB055;
    background: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px -5px 13px rgba(60, 176, 85, 0.6);
  }
}
</style>
