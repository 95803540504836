<template>
  <component :is="notification.name"/>
</template>
<script>
import CookiesNotification from "@/components/Notifications/CookiesNotification.vue";
import SaveNotification from "@/components/Notifications/SaveNotification.vue";
export default {
  name: "NotificationComponent",
  components: {
    CookiesNotification,
    SaveNotification
  },
  computed: {
    notification(){
      return this.$store.getters.getNotification
    }
  }
}
</script>
<style lang="scss">
.notification{
  position: fixed;
  z-index: 3;
  top: calc(100vh - 50px);
  left: 50%;
  transform: translateX(-50%);
}
</style>
