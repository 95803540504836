<template>
  <div class="payment-policy-item container-banners">
    <div class="container">
      <div class="payment-policy-item-icon">
        <IconBillCheck/>
      </div>
      <div class="payment-policy-item-title">
        {{ title }}
      </div>
      <div class="payment-policy-item-container">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
import IconBillCheck from "@/components/Categories/icons/iconBillCheck.vue";
export default {
  props: {
    title: {
      type: String,
      default: () => ""
    }
  },
  components: {IconBillCheck}
}
</script>
<style lang="scss">
.payment-policy-item{
  &-icon{
    width: 44px;
    height: 44px;
    background: #287EFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-title{
    margin-top: 22px;
    font-size: 26px;
  }
  &-container{
    margin-top: 41px;
  }
}
</style>
