<template>
  <div>
    <BannerComponent/>
    <div class="container">
      <CategoriesComponent/>
      <div>
      <HowItWorksComponent/>
      </div>
    </div>
    <ReviewsComponent/>
    <SafeBanner/>
    <ApplicationsBanner/>
    <div class="container">
      <BlogComponent/>
    </div>
  </div>
</template>
<script>
import BannerComponent from "@/components/Home/Banners/Banner.vue";
import CategoriesComponent from "@/components/Home/Categories/Categories.vue";
import SafeBanner from "@/components/Home/Banners/Safe.vue";
import ApplicationsBanner from "@/components/Home/Banners/Applications.vue";
import BlogComponent from "@/components/Blog/Blog.vue";
import ReviewsComponent from "@/components/Home/Reviews/Reviews.vue";
import HowItWorksComponent from "@/components/Home/HowItWorks/HowItWorks.vue";

export default {
  name: "HomeView",
  components: {
    HowItWorksComponent,
    ReviewsComponent, BlogComponent, ApplicationsBanner, SafeBanner, CategoriesComponent, BannerComponent}
}
</script>
