<template>
  <div class="modal-published-task">
    <div class="modal-published-task-container">
      <div class="modal-published-task-header">
        <div class="modal-published-task-header-img">
          <img src="@/assets/img/executorbanner.png"/>
        </div>
        <div class="modal-published-task-header-text">
          <div class="modal-published-task-header-text-title">
            Ваше Задание опубликовано!
          </div>
          <div class="modal-published-task-header-task-text-desc">
            Осталось дождаться откликов от мастеров и выбрать исполнителя.
          </div>
        </div>
      </div>
      <div class="modal-published-task-content">
        <div class="modal-published-task-content-item">
          Пожалуйста, для ознакомления с работой нашего сайта перейдите по ссылке: <a href="https://getjob.cz/faq">https://getjob.cz/faq</a>
        </div>
        <div class="modal-published-task-content-item">
          Если у вас остались вопросы, воспользуйтесь формой обратной связи: <a href="https://getjob.cz/contact">https://getjob.cz/contact</a>
        </div>
        <div class="modal-published-task-content-item">
          По техническим или другим вопросам свяжитесь с нашей поддержкой, по электронной почте: <a href="mailto:info@getjob.cz">info@getjob.cz</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PublishedTask',
  data () {
    return {
      title: '',
      desc: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-published-task{
  &-container{
    width: 853px;
    height: 499px;
  }
  &-header{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 67px 60px 65px;
    &-img{
      border-radius: 17px;
      border: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      img{
        width: 41px;
        height: 34px;
      }
    }
    &-text{
      &-title{
        font-size: 26px;
        font-weight: 500;
      }
      &-desc{
        margin-top: 6px;
        font-weight: 300;
      }
    }
  }
  &-content{
    border-top: 1px solid #D9D9D9;
    border-radius: 21px;
    padding-left: 63px;
    &-item{
      width: 583px;
      margin-top: 35px;
      a{
        color: #287EFF;
      }
    }
  }
}
</style>
