<template>
  <div class="profile-reviews">
    <h2>Отзывы от {{ isExecutor ? 'заказчиков' : 'исполнителей'}}</h2>
    <div class="profile-reviews-container">
      <ProfileReviewCard
        class="profile-reviews-card"
        v-for="i in 3"
        :key="i"
        name="Martin Dvořák"
        text="Текст отзыва об исполнителях тут я просто напишу слова для примерного
понимания длины отзыва и максимальном кол-ве символов под
отзывом я думаю 3 строчки вполне достаточно"
      />
      <div class="profile-reviews-pagination">
        <router-link to="to" class="profile-reviews-pagination-text active">
          Показать еще отзывы
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.94977 0.999938L3.94977 6.94977M3.94977 6.94977L1.09385 4.09385M3.94977 6.94977L6.80569 4.09385" stroke="#FF6B17" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileReviewCard from '@/components/Profile/ProfileIndex/ProfileReviews/ProfileReviewCard.vue';

export default {
  name: 'ProfileReviews',
  props: {
    isExecutor: {
      type: Boolean,
      default: () => false
    }
  },
  components: { ProfileReviewCard}
}
</script>
<style lang="scss">
.profile-reviews{
  &-container{
    margin-top: 30px;
    .profile-review-card{
      width: 700px;
    }
  }
  &-pagination{
    margin-top: 20px;
    padding-bottom: 24px;
    border-bottom: 1px solid #ECEDED;
    &-text{
      margin-left: 82px;
      font-size: 14px;
      font-weight: 300;
    }
  }
  &-card{
    &:not(:first-child){
      margin-top: 23px;
    }
  }
}
</style>
