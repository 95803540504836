<link rel="stylesheet" href="ChatComponent.scss">
<template>
  <div class="chat-component">
    <div class="chat-component-container" v-click-outside="closeChat">
      <ChatSidebar/>
      <ChatContent/>
      <div class="chat-component-close" @click="closeChat">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.51672 15.8998C1.12447 15.9226 0.738678 15.7922 0.440711 15.536C-0.146904 14.9449 -0.146904 13.9902 0.440711 13.3991L13.3075 0.532244C13.9187 -0.0396496 14.8777 -0.00785834 15.4496 0.603313C15.9668 1.15599 15.9969 2.00547 15.5202 2.59339L2.57756 15.536C2.28347 15.7885 1.90384 15.9186 1.51672 15.8998Z" fill="#1E272F"/>
          <path d="M14.3684 15.8998C13.9708 15.8981 13.5898 15.7403 13.3075 15.4603L0.440589 2.59337C-0.103806 1.95765 -0.0297925 1.00091 0.605933 0.456468C1.17334 -0.0294346 2.01014 -0.0294346 2.57749 0.456468L15.5201 13.3234C16.1312 13.8954 16.1628 14.8545 15.5907 15.4655C15.568 15.4898 15.5444 15.5133 15.5201 15.5361C15.3633 15.6724 15.1802 15.7751 14.9821 15.8376C14.7839 15.9002 14.575 15.9214 14.3684 15.8998Z" fill="#1E272F"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import ChatSidebar from "@/components/Chat/ChatSidebar.vue";
import ChatContent from "@/components/Chat/ChatContent.vue";

export default {
  name: 'ChatComponent',
  components: {ChatContent, ChatSidebar},
  methods: {
    closeChat(){
      this.$store.dispatch('setIsOpen', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './ChatComponent.scss';
</style>
