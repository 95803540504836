<template>
  <div class="modal-login">
    <div v-if="steps[step].title" class="modal-header">
        {{ steps[step].title }}
    </div>
    <component :is="steps[step].name" @nextStep="step++" @backStep="step--"/>
    <div v-if="step === 0" class="modal-registration-footer">
      <div class="modal-registration-footer-link" @click="step = 2">
        Войти по логину и паролю
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationStep1 from "@/components/Modals/ModalAuth/RegistrationSteps/RegistrationStep1.vue";
import RegistrationStep2 from "@/components/Modals/ModalAuth/RegistrationSteps/RegistrationStep2.vue";
import LoginStep1 from "@/components/Modals/ModalAuth/LoginSteps/LoginStep1.vue";
export default {
  name: "ModalLogin",
  components: {
    RegistrationStep1,
    RegistrationStep2,
    LoginStep1
  },
  data () {
    return {
      steps: [
        {
          name: 'registration-step1',
          title: 'Вход'
        },
        {
          name: 'registration-step2'
        }
        ,
        {
          name: 'login-step1',
          title: 'Вход'
        }
      ],
      step: 0
    }
  }
}
</script>
<style lang="scss">
.modal-login{
  width: 592px;
  .modal-registration-container{
    margin-top: -14px;
  }
}
</style>
