<template>
  <div class="header" ref="header">
    <div :class="['header-container', 'container', {'header-container-logged': isAuth }]">
      <LogoIcon/>
      <div class="header-city">
        <DropdownComponent position="bottom" ref="dropdown" v-click-outside="clear">
          <template #icon>
            <div class="header-city-container">
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.375 0.875C1.95973 0.875 0 2.63895 0 4.8125C0 8.3125 4.375 13.125 4.375 13.125C4.375 13.125 8.75 8.3125 8.75 4.8125C8.75 2.63895 6.79027 0.875 4.375 0.875ZM4.375 7C4.02888 7 3.69054 6.89736 3.40275 6.70507C3.11497 6.51278 2.89066 6.23947 2.75821 5.9197C2.62576 5.59993 2.5911 5.24806 2.65863 4.90859C2.72615 4.56913 2.89282 4.2573 3.13756 4.01256C3.3823 3.76782 3.69413 3.60115 4.03359 3.53363C4.37306 3.4661 4.72493 3.50076 5.0447 3.63321C5.36447 3.76566 5.63778 3.98997 5.83007 4.27775C6.02236 4.56554 6.125 4.90388 6.125 5.25C6.12449 5.71397 5.93996 6.1588 5.61188 6.48688C5.2838 6.81496 4.83897 6.99949 4.375 7Z" fill="#999DA1"/>
              </svg>
              <div class="header-city-container-text">
                {{cities['active']}}
              </div>
              <svg class="dropdown-svg dropdown-svg-grey" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L-5.24537e-07 1.04907e-06L12 0L6 6Z"/>
              </svg>
            </div>
          </template>
          <template #list>
            <div class="header-city-list-container">
              <div class="header-city-search">
                <div class="header-city-search-container">
                  <input placeholder="Поиск по городу" @input="search" v-model="searchInput"/>
                  <div class="header-city-search-icon">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="header-city-list">
                <div :class="['header-city-list-item', {'header-city-list-item-active': cities['active'] === city['city']}]" v-for="(city) in cities['data']" :key="city['city']" @click="setCity(city['city'])">
                  {{city['city']}}
                </div>
              </div>
            </div>
          </template>
        </DropdownComponent>
      </div>
      <nav :class="['header-nav', {'header-nav-logged': isAuth}]">
        <ul>
          <li><router-link :class="[{'header-nav-active': $route.name === 'CreateTask'}]" to="/create-task">Создать задание</router-link></li>
          <li><router-link :class="[{'header-nav-active': $route.name === 'FindTask'}]" to="/tasks">Найти задание</router-link></li>
          <li><router-link to="to">Для исполнителей</router-link></li>
          <li v-if="isAuth"><router-link to="to">Мои заказы</router-link></li>
        </ul>
      </nav>
      <div v-if="!isAuth" class="header-actions">
        <div class="btn btn-secondary" @click="registration">Регистрация</div>
        <div class="btn btn-primary-outline" @click="authorize">Войти</div>
      </div>
      <div v-else class="header-actions header-actions-logged">
        <div class="header-chat" @click="openChat">
          <div class="header-chat-icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.99992 12.8333C10.2216 12.8333 12.8333 10.2217 12.8333 7.00001C12.8333 3.77834 10.2216 1.16667 6.99992 1.16667C3.77826 1.16667 1.16658 3.77834 1.16658 7.00001C1.16658 7.93315 1.38569 8.81513 1.77527 9.59728C1.8788 9.80513 1.91326 10.0427 1.85324 10.267L1.5058 11.5656C1.35497 12.1292 1.87068 12.6449 2.43437 12.4941L3.73289 12.1467C3.95721 12.0867 4.19479 12.1211 4.40264 12.2247C5.1848 12.6142 6.06677 12.8333 6.99992 12.8333Z" fill="#287EFF"/>
              <path d="M8.74992 7.00001C8.74992 7.32217 9.01109 7.58334 9.33325 7.58334C9.65542 7.58334 9.91658 7.32217 9.91658 7.00001C9.91658 6.67784 9.65542 6.41667 9.33325 6.41667C9.01109 6.41667 8.74992 6.67784 8.74992 7.00001Z" fill="white"/>
              <path d="M6.41658 7.00001C6.41658 7.32217 6.67775 7.58334 6.99992 7.58334C7.32208 7.58334 7.58325 7.32217 7.58325 7.00001C7.58325 6.67784 7.32208 6.41667 6.99992 6.41667C6.67775 6.41667 6.41658 6.67784 6.41658 7.00001Z" fill="white"/>
              <path d="M4.08325 7.00001C4.08325 7.32217 4.34442 7.58334 4.66658 7.58334C4.98875 7.58334 5.24992 7.32217 5.24992 7.00001C5.24992 6.67784 4.98875 6.41667 4.66658 6.41667C4.34442 6.41667 4.08325 6.67784 4.08325 7.00001Z" fill="white"/>
            </svg>
          </div>
          <div class="header-chat-text">
            Чат
          </div>
          <div class="header-chat-count">22</div>
        </div>
        <div class="header-profile">
           <div class="header-profile-balance">
             <BalanceComponent/>
           </div>
          <div class="header-profile-dropdown">
            <DropdownComponent>
              <template #icon>
                <AvatarComponent class="header-profile-avatar" :new-message="newMessage"/>
                <svg class="dropdown-svg" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 6L-5.24537e-07 1.04907e-06L12 0L6 6Z"/>
                </svg>
              </template>
              <template #list>
                <div class="header-profile-dropdown-list">
                  <div class="header-profile-mini-avatar header-profile-dropdown-list-item">
                    <AvatarComponent size="mini" />
                    <div class="header-profile-mini-avatar-name">
                      Emilia Jones
                    </div>
                  </div>
                  <div v-if="newMessage" class="header-profile-messages header-profile-dropdown-list-item">
                    <div class="header-profile-messages-text">
                      У вас есть непрочитанные уведомления
                    </div>
                    <div class="header-profile-messages-btn">
                      <div class="header-profile-messages-count">
                        2
                      </div>
                      <div>
                        Открыть
                      </div>
                    </div>
                  </div>
                  <router-link to="/profile">
                    <div class="header-profile-dropdown-list-item">
                      Профиль
                    </div>
                  </router-link>
                  <router-link to="/profile/settings">
                    <div class="header-profile-dropdown-list-item">
                      Настройки
                    </div>
                  </router-link>
                  <router-link to="/logout">
                    <div class="header-profile-dropdown-logout header-profile-dropdown-list-item">
                      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3136 6.65687H0.999884M0.999884 6.65687L6.43046 1.22629M0.999884 6.65687L6.43046 12.0874" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <div>
                        Выйти
                      </div>
                    </div>
                  </router-link>
                </div>
              </template>
            </DropdownComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogoIcon from "@/components/icons/LogoIcon.vue";
import { ref, watch, watchEffect } from "vue";
import AvatarComponent from "@/components/Avatar.vue";
import BalanceComponent from "@/components/Balance.vue";
import DropdownComponent from "@/components/Dropdown.vue";
import czcities from "./cz.json";
export default {
  name: "HeaderComponent",
  components: {DropdownComponent, BalanceComponent, AvatarComponent, LogoIcon},
  data(){
    return{
      newMessage: true,
      searchInput: "",
      cities: {
        'active': 'Olomouc',
        'data': czcities
      }
    }
  },
  computed: {
    isAuth(){
      return this.$store.getters.getAuth;
    }
  },
  mounted() {
    this.vueOnScroll();
  },
  methods: {
    authorize(){
      this.$store.dispatch('setAuth', true)
      // this.$store.dispatch('setModal', { modalName: 'ModalLogin'})
    },
    registration() {
      this.$store.dispatch('setModal', { modalName: 'ModalRegistration'})
    },
    search(){
      this.cities['data'] = czcities.filter(el => {
        if(el['city'].toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1){
          return el
        }
      })
    },
    vueOnScroll() {
      let prev = window.scrollY;
      const refs = this.$refs.header; // assign the reference in variable
      window.addEventListener("scroll", () => {
        let curr = window.scrollY;
        if (prev < curr) {
          refs.classList.add("scrollDown");
        }
        if (curr === 0) {
          refs.classList.remove("scrollDown");
          refs.classList.remove("scrolled");
        }
        prev = curr;
      });
    },
    setCity(value){
      this.cities['active'] = value;
      this.$refs.dropdown.closeDropdown();
      this.clear();
    },
    clear(){
      this.cities['data'] = czcities;
      this.searchInput = "";
    },
    openChat(){
      this.$store.dispatch('setModal', { modalName: 'Chat'})
    }
  },
}
</script>

<style lang="scss">
@import "./HeaderComponent.scss";
</style>
