<template>
  <div class="faq-view container-banners">
    <div class="container title">
      Ответы на ваши вопросы
    </div>
    <div class="faq-view-container container-banners">
      <FaqContainer/>
    </div>
  </div>
</template>
<script>
import FaqContainer from "@/components/Faq/FaqContainer.vue";

export default {
  name: 'FaqView',
  components: {FaqContainer}
}
</script>
<style lang="scss">
.faq-view{
  &.container-banners{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .container-banners{
    margin: 0;
    margin-top: 58px;
  }
  .title{
    font-size: 42px;
  }

}
</style>
