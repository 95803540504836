<template>
  <div class="executor-banner">
    <div class="executor-banner-col">
      <div class="executor-banner-title">
        Начните зарабатывать c GetJob
      </div>
      <div class="executor-banner-desc">Выполнять задания на GetJob
        могут только исполнители</div>
      <div class="executor-banner-btn btn-primary">
        Хочу стать исполнителем
      </div>
    </div>
    <img src="@/assets/img/executorbanner.png"/>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.executor-banner{
  padding: 40px 33px;
  border: 1px solid rgba(#1e272f, 0.17);
  border-radius: $border-radius-medium;
  width: 659px;
  display: flex;
  align-items: center;
  &-col{
    width: 364px;
  }
  .btn-primary{
    padding: 25px 35px;
    margin-top: 30px;
    width: 263px;
  }
  &-title{
    font-size: 24px;
    font-weight: 500;
  }
  &-desc{
    font-size: 20px;
    font-weight: 300;
    margin-top: 15px;
    width: 281px;
  }
}
</style>
