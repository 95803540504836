<template>
  <div :class="['chat-item', { 'chat-item-system': system}]">
    <div :class="['chat-item-img', {'chat-item-img-system': system}]">
      <img v-if="system" src="@/assets/img/executorbanner.png"/>
      <img v-else src="@/assets/img/chatimg.png"/>
    </div>
    <div class="chat-item-container">
      <div class="chat-item-row">
        <div class="chat-item-row-left">
          {{ system ? 'GetJob' : 'Emilia Jones' }}
        </div>
        <div class="chat-item-row-right">
          <div class="chat-item-new-message"></div>
        </div>
      </div>
      <div class="chat-item-row chat-item-row-bottom">
        <div class="chat-item-row-left">
          <div v-if="!system" class="chat-item-online">
          </div>
          <div v-if="!system" class="chat-item-online-text">
            Online
          </div>
          <div v-if="system" class="chat-item-last-online">
            <div>
              Был в сети:
            </div>
            <div class="chat-item-last-online-date">
              11.10.23
            </div>
          </div>
        </div>
        <div class="chat-item-row-right chat-item-delete">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_296_1047)">
              <path d="M15 3.75C15.1989 3.75 15.3897 3.82902 15.5303 3.96967C15.671 4.11032 15.75 4.30109 15.75 4.5C15.75 4.69891 15.671 4.88968 15.5303 5.03033C15.3897 5.17098 15.1989 5.25 15 5.25H14.25L14.2477 5.30325L13.548 15.1065C13.5211 15.4849 13.3517 15.8391 13.0741 16.0977C12.7965 16.3563 12.4311 16.5 12.0518 16.5H5.9475C5.5681 16.5 5.2028 16.3563 4.92516 16.0977C4.64753 15.8391 4.47819 15.4849 4.45125 15.1065L3.7515 5.304C3.75036 5.28602 3.74986 5.26801 3.75 5.25H3C2.80109 5.25 2.61032 5.17098 2.46967 5.03033C2.32902 4.88968 2.25 4.69891 2.25 4.5C2.25 4.30109 2.32902 4.11032 2.46967 3.96967C2.61032 3.82902 2.80109 3.75 3 3.75H15ZM10.5 1.5C10.6989 1.5 10.8897 1.57902 11.0303 1.71967C11.171 1.86032 11.25 2.05109 11.25 2.25C11.25 2.44891 11.171 2.63968 11.0303 2.78033C10.8897 2.92098 10.6989 3 10.5 3H7.5C7.30109 3 7.11032 2.92098 6.96967 2.78033C6.82902 2.63968 6.75 2.44891 6.75 2.25C6.75 2.05109 6.82902 1.86032 6.96967 1.71967C7.11032 1.57902 7.30109 1.5 7.5 1.5H10.5Z"/>
            </g>
          </svg>
          <div class="chat-item-delete-container">
            <div>Удалить диалог ?</div>
            <div class="btn btn-secondary">
              Удалить
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChatItem',
  props: {
    system: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
<style lang="scss">
.chat-item{
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 21px;
  border: 1px solid rgba(0,0,0,.12);
  width: 313px;
  direction:ltr;
  padding: 7px 12px 7px 7px;
  &-system{
    background: linear-gradient(270.09deg, rgba(255, 107, 23, 0.15) 0.09%, rgba(255, 255, 255, 0) 35.59%);
  }
  &-img{
    width: 70px;
    height: 70px;
    border-radius: 17px;
    border: 1px solid #D9D9D9;
    img{
      width: 70px;
      height: 70px;
    }
    &-system{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;
      img{
        width: 41px;
        height: 34px;
      }
    }

  }
  &-container{
    margin-left: 9px;
    width: 100%;
  }
  &-row{
    display: flex;
    align-items: center;
    margin-top: 11px;
    &-bottom{
      margin-top: 16px;
    }
    &-left{
      display: flex;
      font-size: 14px;
      align-items: center;
    }
    &-right{
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
  &-new-message{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #F44236;
    margin-right: 7px;
  }
  &-online{
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #3CB055;
    &-text{
      color: #3CB055;
      margin-left: 4px;
    }
  }
  &-delete{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    width: 26px;
    height: 26px;
    cursor: pointer;
    svg{
      fill: #1E272F;
    }
    &:hover{
      svg{
        fill: #FF6B17;
      }
      .chat-item-delete-container{
        display: block;
      }
    }
    &-container{
      display: none;
      position: absolute;
      width: 174px;
      height: 125px;
      padding-left: 27px;
      padding-top: 24px;
      background: #fff;
      border-radius: 21px;
      bottom: -120px;
      left: 0;
      z-index: 1;
      box-shadow: 0px 38px 202px rgba(30, 39, 47, 0.25);
      .btn-secondary{
        width: 82px;
        height: 41px;
        font-size: 12px;
        margin-top: 18px;
      }
    }
  }
  &-last-online{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 300;
  }
  &:not(:first-child){
    margin-top: 8px;
  }

}
</style>
