<template>
  <div>
    <div @click="setCheckbox" class="ui-checkbox">
      <div :class="['ui-checkbox-checkbox', {'ui-checkbox-checkbox-medium' : size === 'medium'}]">
        <div :class="['ui-checkbox-checkbox-inner', `ui-checkbox-checkbox-inner-${color}`, {'ui-checkbox-checkbox-inner-hidden': !vModel}]">
        </div>
      </div>
      <input type="checkbox" class="ui-checkbox-input" id="checkbox" name="checkbox" v-model="vModel" :value="modelValue">
      <label :class="[{'ui-checkbox-grey': greyUnactive && this.vModel !== true}]">{{ title }}</label>
      <div v-if="isDropdown" :class="['ui-checkbox-arrow', { 'ui-checkbox-arrow-active': isOpen}]">
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 5.8196L-5.24537e-07 3.87855e-06L12 2.86102e-06L6 5.8196Z"/>
        </svg>
      </div>
    </div>
    <div v-if="isOpen" class="ui-checkbox-footer">
      <UiCheckbox
        v-for="item in items"
        :key="item.id"
        :title="item.text"
        color="orange"
        size="medium"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "UiCheckbox",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    color: {
      type: String,
      default: () => 'blue'
    },
    greyUnactive: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => 'default'
    },
    isDropdown: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: () => 1
    }
  },
  data(){
    return{
      checked: false,
      vModel: false,
      isOpen: false,
      isActive: false
    }
  },
  methods: {
    setCheckbox(){
      console.log('setCheckbox')
      if(this.isDropdown){
        this.isOpen = !this.isOpen
        this.vModel = !this.vModel
      } else {
        this.vModel = !this.vModel
        this.$emit('update:modelValue', { active: this.vModel, value: this.modelValue})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-checkbox{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 12px;
  &-input{
    position: absolute;
    z-index: -1;
    opacity: 0;
    &+label{
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      user-select: none;
      font-size: 12px;
    }
  }
  &-checkbox{
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 29px;
    border: 4px solid #f2f2f2;
    border-radius: 50px;
    margin-right: 9px;
    &-medium{
      width: 19px;
      height: 19px;
    }
    &-inner{
      width: 11px;
      height: 11px;
      border: 4px solid;
      &-orange{
        border-color: #FF6B17;
      }
      &-blue{
        border-color: #287EFF;
      }
      border-radius: 50px;
      &-hidden{
        visibility: hidden;
      }
    }
  }
  &-grey{
    color: #999DA1;
  }
  &-arrow{
    margin-left: 10px;
    svg{
      fill: #1e272f;
    }
    &-active{
      transform: rotate(180deg);
      svg{
        fill: #FF6B17;
      }
    }
  }
  &-footer{
    padding-left: 24px;
    .ui-checkbox{
      margin-top: 11px;
      label{
        font-size: 14px!important;

      }
    }
  }
}
</style>
