<template>
  <div class="order-card">
    <div class="order-card-header">
      <AvatarComponent size="mini"/>
      <div class="order-card-name">
        {{ customer }}
      </div>
      <BalanceComponent class="order-card-balance" :with-out-icon="true"/>
    </div>
    <div class="order-card-category">
      {{ category }}
    </div>
    <div class="order-card-footer">
      <div class="btn btn-dark">Детали</div>
      <OrderStatus class="order-card-status" :order-status="orderStatus"/>
    </div>
  </div>
</template>
<script>
import AvatarComponent from "@/components/Avatar.vue";
import BalanceComponent from "@/components/Balance.vue";
import OrderStatus from "@/components/OrderStatus.vue";

export default {
  name: "OrderCard",
  components: {OrderStatus, BalanceComponent, AvatarComponent},
  props: {
    customer: {
      type: String,
      default: () => ""
    },
    category: {
      type: String,
      default: () => ""
    },
    orderStatus: {
      type: Number,
      default: () => 0
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.order-card{
  padding: 18px 20px;
  border: 1px solid rgba(#1E272F, .17);
  border-radius: $border-radius-medium;
  &-header{
    display: flex;
    align-items: center;
    gap: 9px;
  }
  &-name{
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
  &-balance{
    margin-left: 8px;
  }
  &-category{
    font-size: 20px;
    font-weight: 500;
    margin-top: 9px;
  }
  &-footer{
    display: flex;
    align-items: center;
    margin-top: 15px;
    .btn-dark{
      padding: 10px 0;
      width: 79px;
      font-size: 12px;
    }
  }
  &-status{
    margin-left: 18px;
  }
}
</style>
